


















































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { showSnackbar } from "@/UIHelper";
@Component
export default class Benutzer extends Vue {
  private zeitFormat: boolean = false;
  private showLoading: boolean = false;
  private spezialUser: boolean = false;
  private adminUser: boolean = false;
  private infoData: any;
  private data: any;
  private stammsatz: Stammsatz[] = [];
  private sprache: Sprache[] = [];
  private user: User[] = [];
  private userGroup: UserGroup[] = [];
  private land: Land[] = [];
  private bezeichnung: string = "";
  private kennung: string = "";
  private tel: string = "";
  private eMail: string = "";
  private showDelete: boolean = false;

  private selectedSprache: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private selectedLand: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private selectedUser: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private selectedUserGroup: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };
  private selectedStammsatz: any = {
    aic: null,
    bezeichnung: this.$globalsBezeichnung(
      this.Globals.Begriff,
      "Web_Bitte_waehlen",
      "Bitte wählen"
    )
  };

  public async mounted() {
    const me = this;
    me.showLoading = true;
    // me.$api.getBenutzergruppen();
    try {
      const userData = await me.$api.getUser();
      if (userData) {
        this.user = userData.data.data;
      }
      const userGroupData = await me.$api.getUserGroup();
      if (userGroupData) {
        this.userGroup = userGroupData.data.data;
      }
      const land = await me.$api.getLaender();
      if (land) {
        this.land = land.data;
      }
      const stamm = await me.$api.getUserStammsatz();
      if (stamm) {
        this.stammsatz = stamm.data.data;
      }
      me.$api.getSprachen().then((x: any) => {
        me.sprache = x.data;
      });
      this.showLoading = false;
    } catch (ex) {
      this.showLoading = false;
      // error ausgeben...
    }
  }
  private async deleteUser() {
    try {
      this.$api.deleteUser(this.selectedUser.aic).then((res: any) => {
        const meldung = res.data.info;
        if (meldung) {
          showSnackbar({
            text: meldung,
            color: "info"
          });
        }
      });
      const userData = await this.$api.getUser();
      if (userData) {
        this.user = userData.data.data;
        this.$forceUpdate();
      }

      this.initUser(false);
    } catch {
      // Meldung
    }
  }
  private async neuAnlageUser(kopie: boolean) {
    let aic = 0;
    let copyAic = 0;
    if (kopie) {
      copyAic = this.selectedUser.aic;
    } else {
      aic = this.selectedUser.aic;
    }
    this.$api
      .newUser(
        aic,
        this.kennung,
        this.bezeichnung,
        2,
        this.tel,
        this.eMail,
        this.selectedSprache.aic,
        this.selectedLand.aic,
        this.selectedStammsatz.aic,
        this.selectedUserGroup.aic,
        copyAic
      )
      .then((res: any) => {
        const changed = res.data.aic;
        const fehler = res.data.info;
        if (fehler) {
          showSnackbar({
            text: fehler,
            color: "error"
          });
        } else if (changed) {
          showSnackbar({
            text: "Benutzer wurde erfolgreich angelegt",
            color: "info"
          });
        }
      });
    if (this.selectedUser) {
      const userData = await this.$api.getUser();
      if (userData) {
        this.user = userData.data.data;
        this.$forceUpdate();
      }
    }
  }
  protected selectedLandChanged(args: any) {
    const land: any = this.land.find((m: any) => m.bezeichnung === args);
    this.selectedLand = land;
  }
  protected selectedSpracheChanged(args: Sprache) {
    const sprache: any = this.sprache.find((m: any) => m.bezeichnung === args);
    this.selectedSprache = sprache;
  }
  protected selectedStammsatzChanged(args: Stammsatz) {
    const stammsatz: any = this.stammsatz.find(
      (m: any) => m.bezeichnung === args
    );
    this.selectedStammsatz = stammsatz;
  }
  protected selectedUserChanged(args: User) {
    const user: any = this.user.find((m: any) => m.bezeichnung === args);
    this.selectedUser = user;
    this.bezeichnung = user.bezeichnung;
    this.kennung = user.kennung;
    this.tel = user.tel;
    this.eMail = user.eMail;
    this.adminUser = user.admin;
    this.spezialUser = user.spezial;
    this.showDelete = true;
    if (user.sprache && this.sprache && this.sprache.length > 0) {
      for (let i = 0; i < this.sprache.length; i++) {
        const meineSprache = user.sprache;
        const sprache: any = this.sprache.find(
          (m: any) => m.aic === meineSprache
        );
        if (sprache) {
          this.selectedSprache = sprache;
        }
      }
    }
    if (user.land && this.land && this.land.length > 0) {
      for (let i = 0; i < this.land.length; i++) {
        const meinLand = user.land;
        const land: any = this.land.find((m: any) => m.aic === meinLand);
        if (land) {
          this.selectedLand = land;
        }
      }
    }
    if (
      user.hauptBenutzerGruppe &&
      this.userGroup &&
      this.userGroup.length > 0
    ) {
      for (let i = 0; i < this.userGroup.length; i++) {
        const meinUserGroup = user.hauptBenutzerGruppe;
        const userGroup: any = this.userGroup.find(
          (m: any) => m.aic === meinUserGroup
        );
        if (userGroup) {
          this.selectedUserGroup = userGroup;
        }
      }
    }
  }
  protected selectedUserGroupChanged(args: UserGroup) {
    const userGroup: any = this.userGroup.find(
      (m: any) => m.bezeichnung === args
    );
    this.selectedUserGroup = userGroup;
  }
  private initUser(kopie: boolean) {
    if (!kopie) {
      this.selectedUser = "";
    }
    this.bezeichnung = "";
    this.kennung = "";
    this.tel = "";
    this.eMail = "";
  }
  private filterFunction(
    item: any,
    queryText: string,
    itemText: string
  ): boolean {
    queryText = queryText.toLowerCase();
    if (item.headerHtml) {
      return true;
    }
    if (
      (
        itemText &&
        itemText.toLowerCase &&
        itemText.toLowerCase() + ""
      ).startsWith(queryText)
    ) {
      return true;
    }
    if (item.rowData) {
      const x = item.rowData.find(
        (t: string) =>
          t && t.toLowerCase && (t.toLowerCase() + "").startsWith(queryText)
      );
      if (x) {
        return true;
      }
    }
    return false;
  }
}
declare interface Land {
  aic: string;
  bezeichnung: string;
  iso3166: string;
  kennung: string;
  standard: boolean;
}
declare interface Sprache {
  aic: string;
  anzahl: string;
  bezeichnung: string;
  iso639: string;
  kennung: string;
}
declare interface Mandant {
  data: string;
  text: string;
}
declare interface User {
  aic: number;
  kennung: string;
  bezeichnung: string;
  admin: boolean;
  art: number;
  datum: string;
  ebene: string;
  tel: string;
  ohne: boolean;
  pwMG: number;
  pwML: number;
  pwMM: number;
  pwMS: number;
  pwMZ: number;
}
declare interface UserGroup {
  bezeichnung: string;
  aic: number;
  kennung: string;
}
declare interface Stammsatz {
  bezeichnung: string;
  aic: number;
  eintritt: string;
  austritt: string;
  mandant: string;
  aicMandant: number;
}
