var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"password",attrs:{"cols":"12","sm":"12","lg":"6","md":"9"}},[_c('v-card-title',{staticClass:"dvhBackground"},[_c('div',{staticClass:"headline font-weight-light white--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('v-form',[_c('v-text-field',{staticClass:"input-group--focused",attrs:{"required":"","append-icon":_vm.show ? 'visibility' : 'visibility_off',"type":_vm.show ? 'text' : 'password',"value":"","clearable":"","label":_vm.$globalsBezeichnung(
          _vm.Globals.Begriff,
          'Altes_Passwort:',
          'altes Passwort'
        ),"counter":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.show1 ? 'visibility' : 'visibility_off',"type":_vm.show1 ? 'text' : 'password',"required":"","value":"","clearable":"","label":_vm.$globalsBezeichnung(
          _vm.Globals.Begriff,
          'Neues_Passwort:',
          'neues Passwort'
        ),"hint":_vm.hintText,"persistent-hint":"","counter":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.show2 ? 'visibility' : 'visibility_off',"type":_vm.show2 ? 'text' : 'password',"required":"","value":"","clearable":"","error":"","label":_vm.$globalsBezeichnung(
          _vm.Globals.Begriff,
          'Wiederholung_PW',
          'Wiederholung'
        ),"hint":_vm.hintText,"persistent-hint":"","counter":""},on:{"click:append":function($event){_vm.show2 = !_vm.show2}},model:{value:(_vm.rePassword),callback:function ($$v) {_vm.rePassword=$$v},expression:"rePassword"}}),_c('v-card-actions',[_c('v-spacer'),_c('CommonBaseButtons',{attrs:{"deleteVisible":false,"createVisible":false,"backVisible":false,"refreshVisible":false}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }