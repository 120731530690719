































import CreateList from "../CreateList";
import "chartist/dist/chartist.min.css";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import Chartist from "vue-chartist";
import Constants from "@/Constants";
import { Globals } from "@/Globals";
import {
  getKennungTitle,
  getKennungValueDisplay,
  getKZTitle,
  getKZValue,
  getKZValueDisplay
} from "@/DataHelper";

Vue.use(Chartist);
@Component
export default class RelativeDestChartist extends Vue {
  @Prop({ default: false }) protected showTitle!: boolean;
  @Prop({ default: false }) protected small!: boolean;
  @Prop({ default: 400 }) protected fixHeight!: number;
  @Prop({ default: "white" }) public color!: string;
  private myHeight: number = 400;
  private bar: boolean = true;
  private pie: boolean = false;
  private title: string = "";
  private initalized: boolean = false;
  private showLoading: boolean = false;
  @Prop({ required: true }) public chartDataMounted: any = {};
  public chartData: any = {};
  @Prop({ required: false }) public chartColumnsMounted: any = {};
  private chartColumns: any = {};
  private oldchartData: any = {};
  private eventHandlers: any[] = [];

  private text: string = this.$globalsBezeichnung(
    Globals.Begriff,
    "Web_verbucht",
    "verbucht"
  );
  private text1: string = this.$globalsBezeichnung(
    Globals.Begriff,
    "Web_offen",
    "offen"
  );
  private isMobile: boolean = false;
  private options: any = {
    axisX: {
      showGrid: true
    },
    seriesBarDistance: 10,
    reverseData: true,
    height: window.innerHeight,
    stretch: true,
    horizontalBars: true,
    stackBars: true,
    chartPadding: {
      top: 10,
      right: 15,
      bottom: 0,
      left: 80
    }
  };
  private optionsSmall: any = {
    axisX: {
      showGrid: false,
      showLabel: false,
      offset: 0
    },
    axisY: {
      showGrid: false
    },
    low: 0,
    high: 8,
    seriesBarDistance: 10,
    height: "5em",
    horizontalBars: true,
    stackBars: false,
    showPoint: false,
    showLine: false,
    showArea: true,
    fullWidth: true,
    showLabel: false,
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  };
  private ratio: string = "";
  private responsiveOptions: any[] = [
    [
      "screen and (min-width: 641px) and (max-width: 1024px)",
      {
        seriesBarDistance: 5,
        axisX: {
          offset: 80,
          labelInterpolationFnc(value: any) {
            return value;
          }
        },
        axisY: {
          offset: 70
        }
      }
    ],
    [
      "screen and (max-width: 640px)",
      {
        seriesBarDistance: 5,
        axisX: {
          offset: 80,
          labelInterpolationFnc(value: any) {
            return value[0];
          }
        },
        axisY: {
          offset: 70
        }
      }
    ]
  ];
  private optionsPie = {
    height: "5em",
    showLabel: true
  };
  private responsiveOptionsPie: any[] = [
    [
      "screen and (min-width: 640px)",
      {
        chartPadding: 30,
        labelOffset: 100,
        labelDirection: "explode",
        labelInterpolationFnc(value: number) {
          return value;
        }
      }
    ],
    [
      "screen and (min-width: 1024px)",
      {
        labelOffset: 80,
        chartPadding: 20,
        labelInterpolationFnc(value: number) {
          return value;
        }
      }
    ]
  ];
  @Prop({ default: "Bar" }) protected type!: string;
  @Watch("chartDataMounted")
  @Watch("type")
  private created() {
    if (this.type === "Pie") {
      this.pie = true;
      this.bar = false;
    }
    if (this.fixHeight) {
      this.myHeight = this.fixHeight;
    } else {
      this.myHeight = window.innerHeight - 200;
    }
  }
  public mounted() {
    try {
      const me = this;
      this.chartColumns = this.chartColumnsMounted;
      this.chartData = this.chartDataMounted;
      if (Constants.isMobile) {
        this.isMobile = true;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
    this.refreshValues();
  }
  // private labelInterpolationFnc(value: any) {
  //   return value;
  // }
  protected refreshValues() {
    if (this.chartData === this.oldchartData) {
      return;
    }
    this.oldchartData = this.chartData;
    if (!this.oldchartData) {
      const text = this.$begriffBezeichnung(
        "Web_keineDaten_gefunden",
        "keine Daten gefunden"
      );
    }
    try {
      const labels = [];
      const series: any = [];
      const seriesPerc: any = [];

      if (this.chartData?.length > 0) {
        this.text = getKennungTitle("verbucht", this.chartColumns);
        this.text1 = getKennungTitle("Differenz", this.chartColumns);
        for (let i = 0; i < this.chartData.length; i++) {
          const row = this.chartData[i];
          const label = getKZTitle(row, "label", this.chartColumns);
          const labelBez = getKennungValueDisplay(
            row,
            "labelBez",
            this.chartColumns
          );
          const image = getKZValueDisplay(row, "image", this.chartColumns);
          const serie = getKZValue(row, "series", this.chartColumns);

          if (image) {
            labels.push({ label: labelBez, image });
          } else if (labelBez && !this.pie) {
            labels.push(labelBez);
          } else if (label) {
            labels.push(label);
          }
          if (serie) {
            series.push(serie);
          }
        }
        if (this.pie) {
          const lodash = require("lodash");
          let sumVal = lodash.sum(series);
          sumVal = Math.round(sumVal);
          if (sumVal > 0) {
            for (let j = 0; j < series; j++) {
              let val = series[j];
              val = Math.round(val);
              let perc = (val / sumVal) * 100;
              perc = Math.round(perc);
              seriesPerc.push(perc);
            }
            this.chartData = {
              labels,
              series: [seriesPerc]
            };
          } else {
            this.chartData = {
              labels,
              series: [series]
            };
          }
        } else {
          this.chartData = {
            labels,
            series: [series]
          };
        }
        this.initalized = true;
        this.showLoading = false;
      } else {
        this.showLoading = false;
      }
    } finally {
      this.$emit("dataloaded");
      // if (this.pie) {
      //   new Chartist.Pie(
      //     ".ct-chart",
      //     this.chartData,
      //     this.optionsPie,
      //     this.responsiveOptionsPie
      //   );
      // }
    }
  }
}
