





















































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import CreateList from "@/components/CreateList";
import { Globals } from "@/Globals";
// import BaseList from "../baseList.vue";
import { getKZValueDisplay, getKZValue } from "@/DataHelper";
import { showDialog } from "@/UIHelper";

@Component
export default class DialogReisen extends CreateList {
  constructor() {
    super();
    this.queryMutable = "Web_RK_Reisezeiten";
    this.showLoading = true;
  }
  private timeLine: boolean = false;
  private myHeight: number = 600;
  private bezeichnung: string = "";
  private memo: string = "";
  private showPrint: boolean = false;
  private printItems!: PrintItems[];
  public mounted() {
    if (this.siteData.aic) {
      this.queryAIC = this.siteData.aic;
    }
    this.myHeight = window.innerHeight;
    this.mountedBase();
    this.addDruck();
  }
  private addDruck() {
    const stammTyp = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
    if (stammTyp) {
      this.printItems = this.$globalsDruck("Begriff", false, stammTyp);
    }
  }

  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
  }

  protected refreshItems() {
    this.items = [];
    if (this.data.length > 0) {
      this.timeLine = true;
    }
    for (let i = 0; i < this.data.length; i++) {
      const row = this.data[i];
      this.bezeichnung = getKZValueDisplay(row, "subject", this.columns);
      this.showCalc = getKZValue(row, "showCalc", this.columns);
      const title = getKZValueDisplay(row, "abfahrt", this.columns);
      const title2 = getKZValueDisplay(row, "ankunft", this.columns);
      const subtitel = getKZValueDisplay(row, "zeit", this.columns);
      const subtitel1 = getKZValueDisplay(row, "ereignis", this.columns);
      const text = getKZValueDisplay(row, "region", this.columns);
      const differenz = getKZValueDisplay(row, "km", this.columns);
      const Abflatlong = getKZValueDisplay(row, "Abf_latlong", this.columns);
      const Anklatlong = getKZValueDisplay(row, "Ziel_latlong", this.columns);
      const color = getKZValueDisplay(row, "color", this.columns);
      const icon = getKZValueDisplay(row, "icon", this.columns);
      this.items.push({
        title,
        title2,
        subtitel,
        subtitel1,
        text,
        differenz,
        divider: true,
        inset: true,
        aic: row.aic_Bew_pool,
        menu: false,
        Abflatlong,
        Anklatlong,
        icon,
        color
      });
    }
  }
  private onCalculated(args: any) {
    this.$emit("back");
    // const x = this.$refs.Reisedaten as any;
    // this.$nextTick(() => (this.$refs.reisedaten as any).onRefresh());

    // x?.reloadData();
    // this.reloadData();
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let aic = this.queryAIC;
    if (this.aic) {
      aic = this.aic;
    }
    const noSelect = true;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      data: {
        zeitbereich: this.siteData.zeitbereich,
        printItem,
        aic,
        pers: false,
        noSelect
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        this.showPrint = false;
        return true;
      }
    });
  }
}
