






































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "@/Constants";
import VTimeLineCarouselBase from "@/components/common/VTimeLineCarouselBase";
import CreateList from "../CreateList";

@Component
export default class VTimeLineCorouselMonth extends VTimeLineCarouselBase {
  // @Prop() public aic: any;
  @Prop({ default: 3 }) public monthBack!: number;
  @Prop({ default: 0 }) public monthForward!: number;
  @Prop() protected expandPanel!: boolean;

  @Prop() private textKennung!: string;
  private textValue: string = "";
  protected static scrollToMonth: Date | undefined;
  private maxWidth: any = "";
  private minWidth: any = "";

  public mounted() {
    if (!VTimeLineCorouselMonth.scrollToMonth) {
      VTimeLineCorouselMonth.scrollToMonth = new Date().firstOfMonth();
    }
    VTimeLineCarouselBase.scrollToDate = VTimeLineCorouselMonth.scrollToMonth;
    this.mountedBase();
  }
  public created() {
    this.setSizes();
    this.createdBase();
  }
  public setSizes() {
    if (Constants.isMobile && this.$isPhone()) {
      this.maxWidth = window.innerWidth;
      this.minWidth = window.innerWidth;
    } else {
      this.maxWidth = window.innerWidth - 20;
      this.minWidth = window.innerWidth;
    }
    this.$nextTick(() => this.onRefresh());
  }
  public onResize(event: any) {
    // console.log("VTimelineCarousel.OnResize");
    super.onResize(event);
    this.setSizes();
  }
  protected calcDates() {
    const validMonthsBack = this.calcMonthsBack();
    const newDate = new Date();
    const myDate = newDate.firstOfMonth();
    for (let i = validMonthsBack * -1; i <= this.monthForward; i++) {
      this.dates.push(myDate.addMonths(i, false));
    }
  }

  private calcMonthsBack() {
    // periodensperre checken
    const sperrDate = this.getSperrDate();
    if (sperrDate) {
      let diff = Math.floor(Math.abs(sperrDate.diffInMonths(this.today)));
      if (diff === 0) {
        diff = 1;
      }
      return Math.min(diff, this.monthBack);
    }
    return this.monthBack;
  }

  public onRefresh() {
    const timelines = this.$refs.timeline as CreateList[];
    if (timelines.length && timelines.length > 0) {
      for (let i = 0; i < timelines.length; i++) {
        const timeline = timelines[i];
        timeline.reloadData();
      }
    } else {
      const timeline = this.$refs.timeline as CreateList;
      if (timeline && timeline.reloadData) {
        timeline.reloadData();
      } else {
        // console.log("no Month - timeline no cry...");
      }
    }
  }
  protected onTabChange(tab: number) {
    if (!tab) {
      const newDate = new Date();
      const myDate = newDate.firstOfMonth();
      VTimeLineCorouselMonth.scrollToMonth = myDate;
      VTimeLineCarouselBase.scrollToDate = VTimeLineCorouselMonth.scrollToMonth;
      return;
    }
    VTimeLineCorouselMonth.scrollToMonth = VTimeLineCarouselBase.scrollToDate = this.dates[
      tab * this.timelinesInPage + 1
    ];
  }
}
