

























































import { goBack, refreshFullPageContent } from "@/NavigationHelper";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component
export default class BaseButtons extends Vue {
  @Prop({ default: true }) public backVisible!: boolean;
  @Prop({ default: true }) public saveVisible!: boolean;
  @Prop({ default: true }) public deleteVisible!: boolean;
  @Prop({ default: true }) public refreshVisible!: boolean;
  @Prop({ default: true }) public createVisible!: boolean;

  @Prop({ default: true }) public triggerNavigateBack!: boolean;

  @Prop({ default: "blue darken-1" }) public backColor!: string;
  @Prop({ default: "green" }) public saveColor!: string;
  @Prop({ default: "red" }) public deleteColor!: string;
  @Prop({ default: "blue" }) public refreshColor!: string;
  @Prop({ default: "orange" }) public createColor!: string;

  @Prop({ default: "cancel" }) public backIcon!: string;
  @Prop({ default: "save" }) public saveIcon!: string;
  @Prop({ default: "delete" }) public deleteIcon!: string;
  @Prop({ default: "refresh" }) public refreshIcon!: string;
  @Prop({ default: "add_circle" }) public createIcon!: string;

  @Prop({ default: "" }) public backText!: string;
  @Prop({ default: "" }) public saveText!: string;
  @Prop({ default: "" }) public deleteText!: string;
  @Prop({ default: "" }) public refreshText!: string;
  @Prop({ default: "" }) public createText!: string;
  @Prop({ default: false }) public ignoreFunctions!: boolean;

  @Prop({ default: true }) public showBackText!: boolean;
  @Prop({ default: false }) public fabBtn!: boolean;
  @Prop({ default: false }) public darkBtn!: boolean;
  @Prop({ default: true }) public textBtn!: boolean;
  @Prop({ default: false }) public smallBtn!: boolean;

  private btnBackText: string = "";
  private btnSaveText: string = "";
  private btnDeleteText: string = "";
  private btnRefreshText: string = "";
  private btnCreateText: string = "";

  private backFunction: (() => void) | null = null;
  private saveFunction: (() => void) | null = null;
  private deleteFunction: (() => void) | null = null;
  private refreshFunction: (() => void) | null = null;
  private createFunction: (() => void) | null = null;



  private isApiBusy: boolean = false;
  public created() {
    this.$root.$on("isApiBusy", (x: boolean) => {
      this.isApiBusy = x;
      this.$forceUpdate();
    });
  }

  public mounted() {
    if (this.backText) {
      this.btnBackText = this.backText;
    }
    if (this.saveText) {
      this.btnSaveText = this.saveText;
    }
    if (this.deleteText) {
      this.btnDeleteText = this.deleteText;
    }
    if (this.refreshText) {
      this.btnRefreshText = this.refreshText;
    }
    if (this.createText) {
      this.btnCreateText = this.createText;
    }
    if (!this.btnBackText && this.showBackText) {
      this.btnBackText = this.$begriffBezeichnung("Close");
    }

    if (!this.btnSaveText) {
      this.btnSaveText = this.$begriffBezeichnung("Speichern");
    }
    if (!this.btnDeleteText) {
      this.btnDeleteText = this.$begriffBezeichnung("Loeschen");
    }
    if (!this.btnRefreshText) {
      this.btnRefreshText = this.$begriffBezeichnung("Refresh");
    }
    if (!this.btnCreateText) {
      this.btnCreateText = this.$begriffBezeichnung("neu");
    }
    this.checkFunctionDelegates();
  }

  public checkFunctionDelegates() {
    if (this.ignoreFunctions) {
      return;
    }
    let parentComponent: any = this.$parent as any;
    let i = 10; // maximal 10 Ebenen
    do {
      if (!parentComponent || i <= 0) {
        break;
      }
      i--;

      if (parentComponent.back && typeof parentComponent.back === "function") {
        this.backFunction = parentComponent.back;
      }
      if (
        parentComponent.onSave &&
        typeof parentComponent.onSave === "function"
      ) {
        // juhu save gefunden!
        this.saveFunction = parentComponent.onSave;
      }
      if (
        parentComponent.onCancel &&
        typeof parentComponent.onCancel === "function"
      ) {
        this.backFunction = parentComponent.onCancel;
      }
      if (parentComponent.save && typeof parentComponent.save === "function") {
        // juhu save gefunden!
        this.saveFunction = parentComponent.save;
      }
      if (
        parentComponent.delete &&
        typeof parentComponent.delete === "function"
      ) {
        this.deleteFunction = parentComponent.delete;
      }
      if (
        parentComponent.refresh &&
        typeof parentComponent.refresh === "function"
      ) {
        this.refreshFunction = parentComponent.refresh;

        if (!this.refreshFunction) {
          this.refreshFunction = refreshFullPageContent;
        }
      }
      if (
        parentComponent.reloadData &&
        typeof parentComponent.reloadData === "function"
      ) {
        //  this.refreshFunction = this.refreshFullPageContent();
        this.refreshFunction = parentComponent.reloadData;
      }
      if (
        parentComponent.onNew &&
        typeof parentComponent.onNew === "function"
      ) {
        this.createFunction = parentComponent.onNew;
      }
      if (this.deleteFunction || this.backFunction || this.saveFunction || this.createFunction) {
        break;
      }

      parentComponent = parentComponent.$parent as any;
    } while (true);
  }

  public onBack() {
    this.$emit("back");
    if (this.backFunction) {
      this.backFunction();
    } else {
      // nicht 2x.
      if (this.triggerNavigateBack) {
        goBack();
      }
    }
  }

  public onSave() {
    this.$emit("save");
    if (this.saveFunction) {
      this.saveFunction();
    }
    this.$emit("afterSave");
  }

  public onDelete() {
    this.$emit("delete");
    if (this.deleteFunction) {
      this.deleteFunction();
    }
  }
  public onRefresh() {
    this.$emit("refresh");
    if (this.refreshFunction) {
      this.refreshFunction();
    }
  }
  public onNew() {
    this.$emit("onNew");
    if (this.createFunction) {
      this.createFunction();
    }
  }
}
