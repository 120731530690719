var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showMutable)?_c('v-row',{ref:"container",staticClass:"wrap"},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"pa-0",staticStyle:{"max-height":"30px"},attrs:{"align":"start"}},[_c('v-col',{staticClass:"fullcol",attrs:{"cols":"5"}},[(_vm.showFields)?_c('MaskTextField',{ref:"von",attrs:{"disabled":!_vm.enabled,"label":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'von', 'von'),"errorText":_vm.vonError,"mask":_vm.mask},on:{"input":_vm.vonChanged},model:{value:(_vm.vonStr),callback:function ($$v) {_vm.vonStr=$$v},expression:"vonStr"}}):_vm._e()],1),_c('v-col',{staticClass:"fullcol",attrs:{"cols":"5"}},[(_vm.showFields)?_c('MaskTextField',{ref:"bis",attrs:{"disabled":!_vm.enabled,"label":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'bis', 'bis'),"errorText":_vm.bisError,"mask":_vm.mask},on:{"input":_vm.bisChanged},model:{value:(_vm.bisStr),callback:function ($$v) {_vm.bisStr=$$v},expression:"bisStr"}}):_vm._e()],1),(_vm.allDayVisible)?_c('v-col',{staticClass:"fullcol",attrs:{"cols":"2","align-self":"center"}},[_c('v-menu',{attrs:{"close-on-click":true,"close-on-content-click":false,"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.disabled)?_c('v-btn',_vm._g({attrs:{"text":"","icon":"","align-self":"center"}},on),[_c('v-icon',{class:_vm.iconCss},[_vm._v("insert_invitation")])],1):_vm._e()]}}],null,false,3980457473),model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('div',{staticStyle:{"background":"white"}},[_c('v-tooltip',{attrs:{"top":"","nudge-top":"-55"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-date-picker',_vm._g({attrs:{"range":"","locale":"de-de","min":_vm.minDate,"first-day-of-week":"1","show-week":true,"events":_vm.getHolidayColorVuetify,"selected-items-text":_vm.selectedDatesText},on:{"change":_vm.pickerPicked,"mouseover:date":_vm.onDayHover,"mouseleave:date":_vm.onDayLeave},model:{value:(_vm.pickerDates),callback:function ($$v) {_vm.pickerDates=$$v},expression:"pickerDates"}},on))]}}],null,false,293574250),model:{value:(_vm.showTooltip),callback:function ($$v) {_vm.showTooltip=$$v},expression:"showTooltip"}},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])]),_c('v-spacer'),_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closePicker(false)}}},[_vm._v(" "+_vm._s(_vm.$begriffBezeichnung("Close")))]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closePicker(true)}}},[_vm._v(" Ok ")])],1)],1)])],1):_vm._e()],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"fullcol",attrs:{"cols":"3","align-self":"center"}},[_c('v-checkbox',{attrs:{"disabled":!_vm.enabled,"label":_vm.$globalsBezeichnung(
              _vm.Globals.Begriff,
              'Web_ganztaegig',
              'ganztägig'
            )},on:{"change":_vm.allDayChanged},model:{value:(_vm.IsAllDay),callback:function ($$v) {_vm.IsAllDay=$$v},expression:"IsAllDay"}})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }