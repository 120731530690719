var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pr-5",attrs:{"cols":"12","sm":"12","lg":"6","md":"9"}},[(!_vm.persDruck && !_vm.noSelect)?_c('v-card-title',{staticClass:"pa-0"},[_c('v-spacer'),_c('v-switch',{staticClass:"pa-0",on:{"change":_vm.setMultiselect},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Multiselect ")]},proxy:true}],null,false,3873437658)})],1):_vm._e(),_c('v-form',[(_vm.multipleSelect && !_vm.noSelect)?_c('v-date-picker',{attrs:{"dark":"","landscape":_vm.landscape,"type":_vm.type,"startDate":_vm.startVal,"endDate":_vm.endVal,"locale":_vm.$api.user.locale,"max":_vm.maxDate,"show-week":true,"multiple":_vm.multipleSelect},on:{"change":_vm.valueChanged},model:{value:(_vm.pickerMulti),callback:function ($$v) {_vm.pickerMulti=$$v},expression:"pickerMulti"}}):_vm._e(),(!_vm.multipleSelect && !_vm.noSelect)?_c('v-date-picker',{attrs:{"landscape":_vm.landscape,"type":_vm.type,"startDate":_vm.startVal,"endDate":_vm.endVal,"locale":_vm.$api.user.locale,"max":_vm.maxDate,"show-week":true,"multiple":_vm.multiple},on:{"change":_vm.valueChanged},model:{value:(_vm.picker),callback:function ($$v) {_vm.picker=$$v},expression:"picker"}}):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Web_Farbe', 'Farbe')},model:{value:(_vm.farbe),callback:function ($$v) {_vm.farbe=$$v},expression:"farbe"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(
                _vm.Globals.Begriff,
                'Web_Seitenumbruch',
                'Seitenumbruch'
              )},model:{value:(_vm.umbruch),callback:function ($$v) {_vm.umbruch=$$v},expression:"umbruch"}})],1)],1),_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(
                _vm.Globals.Begriff,
                'DruckFormat',
                'Format in hh:mm'
              )},model:{value:(_vm.zeitFormat),callback:function ($$v) {_vm.zeitFormat=$$v},expression:"zeitFormat"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[(!_vm.persDruck && !_vm.noSelect)?_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(
                _vm.Globals.Begriff,
                'Periode',
                'Abschnitt/Periode'
              )},model:{value:(_vm.periodisch),callback:function ($$v) {_vm.periodisch=$$v},expression:"periodisch"}}):_vm._e()],1)],1),(!_vm.persDruck)?_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"4"}},[(_vm.showNurSumme)?_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'NurSumme', 'nur Summe')},model:{value:(_vm.nurSumme),callback:function ($$v) {_vm.nurSumme=$$v},expression:"nurSumme"}}):_vm._e()],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4"}},[(_vm.showkeineSumme)?_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(
                _vm.Globals.Begriff,
                'keine_Summe',
                'keine Summe'
              )},model:{value:(_vm.keineSumme),callback:function ($$v) {_vm.keineSumme=$$v},expression:"keineSumme"}}):_vm._e()],1)],1):_vm._e(),(!_vm.persDruck)?_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[(_vm.eMail)?_c('v-checkbox',{attrs:{"text":"","color":"black","label":_vm.$globalsBezeichnung(
                _vm.Globals.Begriff,
                'E-Mail2',
                'e-Mail erstellen'
              )},model:{value:(_vm.eMail),callback:function ($$v) {_vm.eMail=$$v},expression:"eMail"}}):_vm._e()],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),(_vm.printLayout.length > 0)?_c('v-select',{attrs:{"solo":"","items":_vm.printLayout,"label":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Seitenlayout', 'Seitenlayout'),"placeholder":_vm.$globalsBezeichnung(_vm.Globals.Begriff, 'Seitenlayout', 'Seitenlayout'),"item-text":"bezeichnung","item-value":"aicLayout"},on:{"change":_vm.selectedValueChanged}}):_vm._e(),_c('v-btn',{attrs:{"text":"","loading":_vm.loading,"color":"green"},on:{"click":function($event){return _vm.onPrintItemClick()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{staticClass:"custom-loader"},[_c('v-icon',{attrs:{"color":"red","light":""}},[_vm._v("mdi-cached")])],1)]},proxy:true}])},[_c('v-icon',[_vm._v("print")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }