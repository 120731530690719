













































import { Vue, Component, Prop } from "vue-property-decorator";
import { showDialog } from "@/UIHelper";
// import BaseEditor from "./BaseEditor.vue";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import Constants from "@/Constants";

@Component
export default class SubMasken extends Vue {
  protected editZeitbereich: APIZeitbereich | null = null;
  @Prop() protected buttonItems!: any[];
  @Prop() public aic!: string;
  @Prop() public name!: string;
  @Prop({ default: false }) private pers!: boolean;
  constructor() {
    super();
  }
  private showDialog: boolean = false;

  private openSite(item: any) {
    const me = this;
    const aic = this.aic;
    onShowSite({
      title: item.compTitle,
      site: item.compName,
      data: {
        zeitbereich: this.$api.zeitbereich,
        item,
        aic,
        toggleUebergabe: item.toggle,
        showBack: true,
        name: me.name,
        titel: me.name,
        gotoToday: false
      },
      // titleColor: "dvhBackground",
      // titleClass: "white--text",
      callOnLeave: () => {
        setTimeout(() => refreshFullPageContent());
        // return true;
      }
    });
  }
  private openDialog(item: any) {
    const aic = this.aic;
    showDialog({
      title: item.compTitle,
      width: 500,
      site: "BaseDialog",
      fullscreen: item.fullscreen,
      data: { zeitbereich: this.$api.zeitbereich, query: item.query, aic, pers: this.pers },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      }
    });
  }
}
