













































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { showDialog, showSnackbar } from "@/UIHelper";
import { Globals } from "@/Globals";
@Component
export default class DialogReisen extends EditPage {
  @Prop({ default: null }) public druckItems!: any;
  constructor() {
    super();
    this.siteData.bewegungsdaten = false;
    this.query = "WebReisePlanung_Pers";
    this.showLoading = true;
  }
  public mounted() {
    this.bewegungsdaten = false;
    this.mountedBase();
    if (!this.druckItems) {
      const stammTyp = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
      this.druckItems = this.$globalsDruck("Begriff", true, stammTyp);
    }
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let aic = this.queryAIC;
    if (this.aic) {
      aic = this.aic;
    }
    const noSelect = true;
    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      width: 500,
      data: {
        zeitbereich: this.zeitbereich,
        printItem,
        aic,
        pers: true,
        noSelect
      },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
      }
    });
  }
  private openBuchungen() {
    const me = this;
    const von = me.zeitbereich?.von || new Date();
    showDialog({
      title: von.toLocaleDateString(),
      showTitle: false,
      site: "PzePersoenlichPlanungenTemplatesStempelungen",
      width: 400,
      data: { zeitbereich: this.zeitbereich },
      titleColor: "white",
      onClose: () => {
        return true;
      }
    });
  }
}
