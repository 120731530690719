
































































































import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import baseTreeView from "@/editor/EditComponent";
import { getDisplay } from "@/DataHelper";
import EditPage from "@/components/EditPage";
import { Globals } from "../Globals";
import IQueryDataProvider from "@/editor/IQueryDataProvider";
import CreateList from "./CreateList";
import { Dictionary } from "lodash";
import AUApi from "@/api";

export declare interface TreeItem {
  bezeichnung: string;
  aic: string;
  stt: string;
  header: string;
  title: string;
  subtitel: string;
  row: string;
  sttBez: string;
  icon: string;
  color: string;
}
declare interface HsAbfragen {
  aicAbf: number;
  aicEig: number;
  kennung: string;
  aicStt: number;
  aicSttVon: number;
  art: string;
}
@Component
export default class BaseTreeView extends Vue {
  @Prop() protected print!: boolean;
  @Prop({ default: true }) protected showHeader!: boolean;
  @Prop({ default: false }) protected showClose!: boolean;
  @Prop({ default: false }) protected showSubGrid!: boolean;
  @Prop() protected edit!: boolean;
  @Prop() protected value!: boolean;
  @Prop() protected selectable!: boolean;
  @Prop() protected hsAbfragen: HsAbfragen[] = [];
  private hsSubAbfragen: HsAbfragen[] = [];
  @Prop() protected hsAic!: number;
  private gridColumns: DataColumn[] = [];

  protected filteredItems: TreeItem[] = [];
  protected pagedItems: TreeItem[] = [];
  private menu: boolean = false;
  protected items: TreeItem[] = [];
  private filterColumns: DataColumn[] | null = null;

  private pageSize = 10;
  private pages = 1;
  private currentPage = 1;

  public search: string = "";
  private caseSensitive: boolean = false;

  protected header: string = "";
  @Prop() public query!: string;
  @Prop() public editZeitbereich!: APIZeitbereich;
  protected showLoading: boolean = false;
  protected initialized: boolean = false;

  public mounted() {
    this.header = this.$globalsBezeichnung(
      Globals.Begriff,
      "Web_DatenLaden",
      "lade..."
    );
    console.log("BaseTreeView start...");

    this.loadDataBase();
  }
  private selected(x: any) {
    const subAbfrage = this.hsSubAbfragen.find(abf => abf.aicStt === x[0].stt);
    this.$emit("input", x, subAbfrage);
    // if (!this.active.length) return undefined
    // const id = this.active[0]
    // return this.items.find(user => this.items.id === id)
  }
  // protected refreshItems(data: any) {
  //   try {
  //     this.items = data as TreeItem[];
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     this.$emit("dataloaded", { items: this.items });
  //     this.filteredItems = this.items;
  //     // man muss aufrunden!
  //     this.pages = Math.max(
  //       1,
  //       Math.ceil(this.filteredItems.length / this.pageSize)
  //     );
  //     if (BaseTreeView.lastQuery === this.query) {
  //       if (this.pages > BaseTreeView.lastPage) {
  //         this.currentPage = BaseTreeView.lastPage;
  //       } else {
  //         this.currentPage = BaseTreeView.lastPage = 1;
  //         BaseTreeView.lastQuery = this.query;
  //       }
  //     } else {
  //       this.currentPage = BaseTreeView.lastPage = 1;
  //       BaseTreeView.lastQuery = this.query;
  //     }
  //     this.updatePagedItems();
  //   }
  // }
  public loadDataBase() {
    try {
      this.showLoading = true;
      const me = this;
      this.$api.getHSStruktur(
        (data: any) => me.dataLoaded(data),
        this.hsAic,
        this.editZeitbereich,
        false
      );
    } catch (e) {
      console.log(e);
    }
  }
  protected async dataLoaded(data: any) {
    console.log("BaseTreeView data loaded");
    // Fehlerbehandlung
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }
    try {
      this.items = data.data as TreeItem[];
      for (let i = 0; i < this.items.length; i++) {
        const row = this.items[i];
        const stt = row.stt;
        const stamm = this.$globalsStt(Globals.Stt, stt);
        this.items[i].icon = stamm.icon;
        this.items[i].color = stamm.farbe;
      }
    } catch (e) {
      console.log(e);
    }
    if (this.hsAbfragen) {
      this.hsSubAbfragen = this.hsAbfragen;
      await this.loadSubQuerys();
      this.initialized = true;
    } else {
      this.initialized = true;
    }
    this.showLoading = false;
  }

  private async HsSubAbfragen(data: any) {
    // im data stecken die AICs der Abfragen die pro Ebene geholt werden sollen
    // es stecken auch Parameter ob Ebene offen etc drinnen -daher reine Abfragen rausfiltern!
    const allSubData = data.data;
    const subAbfragen = allSubData.filter((c: any) => c.aicAbf !== undefined);
    this.hsSubAbfragen = subAbfragen;
    if (this.hsSubAbfragen) {
      await this.loadSubQuerys();
      this.initialized = true;
    } else {
      this.initialized = true;
    }
  }

  private hsSubData: Dictionary<any> = {};
  @Watch("queryData")
  private async loadSubQuerys() {
    try {
      if (this.hsSubAbfragen.length > 0) {
        const me = this;
        const vecStamm: any[] = [];
        for (let i = 0; i < this.hsSubAbfragen.length; i++) {
          const row = this.hsSubAbfragen[i];
          if (row && row.kennung) {
            const query = row.kennung;
            const response = await AUApi.Current.getQuery(query, undefined);
            this.dataLoadedSubAbf(response, row.aicStt);
          }
        }
      }
    } finally {
      // alles geladen?
    }
  }
  private async dataLoadedSubAbf(data: any, stt: any) {
    try {
      console.log("SubAbfragen loaded");
      // Fehlerbehandlung
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        console.log(data.data.error);
        alert("ACHTUNG: " + data.data.error);
        this.$emit("back");
        return;
      }
    } catch (e) {
      console.error(e);
      return;
    }
    const dataSub = data.data.data;
    if (dataSub.length > 0) {
      // Abfragen zusammensetzern - HS der im Base Tree View definiert ist - und diese Subabfragen....???
      this.hsSubData[stt] = data.data;
    }
  }
  private getGridData(stt: string, aicStamm: string) {
    // die geladene Abrage - die Spalten mit dem identen AIC aus dem this.item zusammensetzen??
    const gridData = this.hsSubData[stt];

    if (!gridData) {
      return;
    }
    if (aicStamm) {
      const filteredData = {
        header: gridData.header,
        data: gridData.data.filter(
          (item: { aic_Stamm: string }) => item.aic_Stamm === aicStamm
        ),
        columns: gridData.columns
      };
      // mit this.items zusammensetzen - das ist die HIERARCHIE

      return { data: filteredData };
    }
  }
  protected static lastPage: number = 1;
  protected static lastQuery: string | undefined;

  public updatePagedItems() {
    BaseTreeView.lastPage = this.currentPage;
    BaseTreeView.lastQuery = this.query;
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.pagedItems = this.filteredItems.slice(
      startIndex,
      Math.min(this.filteredItems.length, startIndex + this.pageSize)
    );
  }

  protected updateFilteredItems() {
    try {
      if (!this.filterColumns && !this.search) {
        this.filteredItems = this.items;
        return;
      }
      const me = this;
      const oldPagePos = this.currentPage / Math.max(1, this.pages);
      this.filteredItems = this.items.filter(item => {
        let match = true;

        if (me.search) {
          const search = me.search.toLowerCase();
          const inHeader =
            item.header && item.header.toLowerCase().indexOf(search) >= 0;
          const inTitle =
            item.title && item.title.toLowerCase().indexOf(search) >= 0;
          const inSub =
            item.subtitel != null &&
            item.subtitel.toLowerCase().indexOf(search) >= 0;
          if (!inHeader && !inTitle && !inSub) {
            return false;
          }
        }

        if (!me.filterColumns) {
          return null;
        }
        for (let i = 0; i < me.filterColumns.length; i++) {
          const fc = me.filterColumns[i];
          const fv = getDisplay(item.row, fc);
          match =
            match &&
            fc.filterValues.find(
              (ffv: any) => ffv.value === fv && ffv.checked
            ) != null;
        }
        return match;
      });
      this.pages = Math.max(
        1,
        Math.ceil(this.filteredItems.length / this.pageSize)
      );
      this.currentPage = Math.max(Math.floor(this.pages * oldPagePos), 1);
      this.updatePagedItems();
    } catch (e) {
      console.error(e);
    }
  }
}
