import { render, staticRenderFns } from "./relativeDestChart.vue?vue&type=template&id=407ed501&scoped=true&"
import script from "./relativeDestChart.vue?vue&type=script&lang=ts&"
export * from "./relativeDestChart.vue?vue&type=script&lang=ts&"
import style0 from "./relativeDestChart.vue?vue&type=style&index=0&id=407ed501&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407ed501",
  null
  
)

export default component.exports