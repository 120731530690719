import { getKZValue } from "@/DataHelper";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AufgabeChartParams, getAlternateColor, getAufgabeDefRow, openZusatzInfoDialog } from "../ChartTypesAndFunctions";
import { onShowSite } from "@/NavigationHelper";
import { Globals } from "@/Globals";

export default abstract class ChartBase extends Vue {
    @Prop() public params!: AufgabeChartParams;
    @Prop({ default: [] }) public QvAICs!: number[];
    @Prop({ default: false }) public QuickViewMode!: boolean;

    protected vector: number[] = [];

    protected vectorForZuordnungen() { return this.vector; }

    protected lastQueryResponse?: QueryResponse;

    public get zeitbereich() {
        return this.params.zeitbereich;
    }
    public get query() {
        return this.params.query;
    }
    public get aic() {
        return this.params.aic;
    }
    public get size() {
        return this.params.size;
    }
    public get title() {
        return this.params.title;
    }
    public get tooltips() {
        return this.params.tooltips ?? true;
    }
    public get icon() {
        return this.params.icon ?? "poll";
    }
    public get bewegungsdaten() {
        return this.params.bewegungsdaten;
    }
    public get orientation() { return this.params.orientation ?? "horizontal"; }
    public get zuordnungen() { return this.params.zuordnungen ?? []; }
    public get aufgabenDefinition() { return this.params.aufgabenDefinition; }

    protected get zusatzInfo() { return this.params.zusatzInfo; }
    protected get zusatzFrame() { return this.params.zusatzFrame; }
    protected get queryData() { return this.lastQueryResponse ?? this.params.queryData; }

    private aufgabenDefRow?: any;

    protected getKZAufgabeDefiniton<T>(kz: string, defaultVal?: T) {
        if (!this.params?.aufgabenDefinition) { return defaultVal; }
        if (!this.aufgabenDefRow && this.params.aicStamm) {
            this.aufgabenDefRow = getAufgabeDefRow(this.params.aicStamm, this.params?.aufgabenDefinition);
        }
        if (!this.aufgabenDefRow) { return defaultVal; }

        return getKZValue(this.aufgabenDefRow, kz, this.params.aufgabenDefinition?.columns) ?? defaultVal;
    }
    private getAlternateColor(ix: number) {
        return getAlternateColor(ix);
    }

    private setzteVector(vec: number[]) {
        this.vector = vec;
        this.$emit("vectorChanged", this.vector);
    }

    public showDialog(aic: any) {
        if (this.zusatzInfo && this.zusatzInfo.gruppe === "Frame") {
            const item = this.zusatzInfo;
            const site = item.alias ?? item.kennung;
            let sysAbfrage = "";
            if (item.kennung === "EditStamm") {
                const stt = aic.aicStamm;
                const noFilter = true;
                const begr = this.$globalsStt(Globals.Begriff, stt, "", noFilter); // holt system Abfrage
                if (begr) {
                    sysAbfrage = begr.kennung;
                }
            }
            onShowSite({
                title: item.name ?? item.bezeichnung,
                site,
                data: {
                    zeitbereich: this.$api.zeitbereich,
                    item,
                    aic,
                    showBack: true,
                    toggleUebergabe: item.toggleSight,
                    name: item.name ?? item.bezeichnung,
                    titel: item.name ?? item.bezeichnung,
                    gotoToday: false,
                    query: sysAbfrage
                },
            });
        } else if (this.zusatzInfo && this.zusatzInfo.gruppe === "Hauptschirm") {
            const item = this.zusatzInfo;
            const site = "DashboardChartsHauptschirm";
            const hsAicSelected = item.aicHS;
            onShowSite({
                title: item.name ?? item.bezeichnung,
                site,
                data: {
                    zeitbereich: this.$api.zeitbereich,
                    item,
                    aic,
                    showBack: true,
                    toggleUebergabe: item.toggleSight,
                    name: item.name ?? item.bezeichnung,
                    titel: item.name ?? item.bezeichnung,
                    gotoToday: false,
                    hsAicSelected
                },
            });
        } else if (this.zusatzInfo && this.zusatzInfo.gruppe === "Aufgabe") {
            openZusatzInfoDialog(
                this.zusatzInfo,
                undefined,
                aic,
                this.aufgabenDefinition,
                this.zeitbereich,
                this.params.aicStamm
            ).catch(e => console.error(e));
        } else {
            console.log("no zusatzinfo - no dialog..");
        }
    }

    @Watch("QvAICs")
    public refreshOnQV() {
        this.QvAICsChanged();
    }

    protected abstract QvAICsChanged(): void;
}
