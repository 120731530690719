



































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
Vue.use(TextBoxPlugin);
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditTextArea extends EditComponent {
  constructor() {
    super();
  }

  private text: string = "";
  private label: string = "";
  @Prop({ default: true }) public clearable!: boolean;
  @Prop({ default: 1 }) public rows!: number;
  @Prop() public disabledMounted!: boolean;
  public mounted() {
    this.text = this.getInitValue();
    if (this.disabledMounted !== undefined) {
      this.enabled = !this.disabledMounted;
    }
  }

  public textChanged() {
    this.valueChanged(this.text);
  }

  public validate() {
    if (this.disabled) {
      this.errorText = "";
      return true;
    } // disabled Controls sind IMMER valide ;)
    if (this.required) {
      if (this.text) {
        this.errorText = "";
        return true;
      }
      this.errorText = this.requiredMessage;
      return false;
    }
    this.errorText = "";
    return true;
  }
}
