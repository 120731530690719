var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-col',{key:'c' + index,attrs:{"cols":item.flex}},[_c('v-card',{class:_vm.reiseClass,attrs:{"loading":_vm.showLoadingReise}},[_c('v-list-item',{staticClass:"pa-0"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-subtitle',{class:item.class},[_vm._v(_vm._s(item.title))]),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Beginn", "Beginn" ))+": "+_vm._s(item.title2))])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Web_Ende", "Ende"))+": "+_vm._s(item.subtitel))])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();(item.expand = !item.expand) && (item.select = false)}}},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Details", "Details"))+" "),_c('v-icon',[_vm._v("expand_more")])],1),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();(item.select = !item.select) && (item.expand = false)}}},[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Status", "Status"))+" "),_c('v-icon',[_vm._v("expand_more")])],1),_c('v-btn',{attrs:{"right":"","text":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openKostenersatz(
                item.title,
                item.aic,
                item.sperre,
                item.number,
                item.date
              )}}},[_vm._v(_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_Button_Beleg", "Belege" ))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""}},[_vm._v(_vm._s(item.subtitel1)+"€")])],1),_c('v-expand-transition',[(item.expand)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal pa-0",staticStyle:{"height":"100%","width":"100% overflow-y: scroll"}},[_c('BaseEditor',{staticClass:"pl-5",staticStyle:{"z-index":"0"},attrs:{"fieldCssClass":"xs12 sm12 md12","aic":item.aic,"zeitbereich":_vm.editZeitbereich,"showTitle":false,"query":"Web_ReiseDatenAnpassen","backOnSave":true,"showDeleteButton":false,"readonly":item.sperre},on:{"back":function($event){return _vm.onBack(item)},"save":function($event){return _vm.onDialogClose(item)}}}),_c('v-card-actions',[_c('CommonBaseButtons',{attrs:{"deleteVisible":false,"createVisible":false,"backVisible":false,"refreshVisible":false,"fabBtn":true,"saveVisible":false}})],1)],1):_vm._e()],1),_c('v-expand-transition',[(item.select)?_c('v-card',{staticClass:"transition-fast-in-fast-out v-card--reveal pa-0",staticStyle:{"height":"100%","width":"100%"}},[_c('RkMitarbeiterTemplateReiseStatus',{ref:"statusInfo",refInFor:true,attrs:{"query":"Web_Reise_Status_Anzeige","zeitbereichUebergabe":_vm.editZeitbereich,"aic":item.aic,"showInfo":_vm.showMemo}}),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(_vm.modell && _vm.showCalc)?_c('v-btn',_vm._g({attrs:{"width":"150px","text":"","icon":"done","color":"success"},on:{"click":function($event){return _vm.onCalcFunction(_vm.modell, item.aic)}}},on),[_c('v-icon',[_vm._v("done")]),_c('span',[_vm._v(_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "RK_WebReiseabrechnungVollstaendig", "vollständig" )))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on1 = ref.on1;
return [(_vm.modell1 && _vm.showCalc)?_c('v-btn',_vm._g({attrs:{"width":"150px","text":"","icon":"clear","color":"error"},on:{"click":function($event){return _vm.onCalcFunction(_vm.modell1, item.aic, item.number)}}},on1),[_c('v-icon',[_vm._v("clear")]),_c('span',[_vm._v(_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "RK_Web_ReiseZurueckZiehen", "zurückziehen" )))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('CommonBaseButtons',{attrs:{"deleteVisible":false,"createVisible":false,"backVisible":true,"refreshVisible":false,"saveVisible":false,"backText":" ","backIcon":"mdi-close"},on:{"back":function($event){return _vm.$emit('back')}}})],1)],1)],1)],1):_vm._e()],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }