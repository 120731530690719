


































import { Vue, Component, Prop } from "vue-property-decorator";
import {
  DashboardRow,
  getBoardDefinitions,
  DashboardParams,
} from "./ChartTypesAndFunctions";
import { refreshFullPageContent, showSite } from "@/NavigationHelper";

@Component
export default class AufgabenTemplatedPage extends Vue {
  @Prop({ default: null }) private dashboardNameStart!: string;
  @Prop({ default: false }) private persoenlicheAufgaben!: boolean;
  @Prop() public siteData!: DashboardParams;

  private static lastAufgabeDashboard: string = "default";
  private dashboardName: string = "default";
  private boardDefinitions: DashboardParams[] = [];
  private showLoading = true;
  public showZeitraumInHeader: boolean = true;

  private persoenlich = false;
  private dashboardDefinition?: DashboardRow[] = [];

  public mounted() {
    this.persoenlich = this.persoenlicheAufgaben;
    this.refresh();
  }
  public async refresh() {
    try {
      const me = this;
      if (!this.boardDefinitions || this.boardDefinitions.length === 0) {
        this.boardDefinitions =
          (await getBoardDefinitions(this.persoenlich, me)) ?? [];
        if (this.boardDefinitions.length > 0) {
          let def = this.boardDefinitions[0]; // default is das erste
          if (AufgabenTemplatedPage.lastAufgabeDashboard) {
            def =
              this.boardDefinitions.find( // finden und auswählen
                (d) => d.name === AufgabenTemplatedPage.lastAufgabeDashboard
              ) ?? def; // oder das erste nehmen wenns das alte nimma gibt
          }
          this.dashboardName = def.name;
          this.dashboardDefinition = def.definition;
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.showLoading = false;
    }
  }

  private getMenuButtonColor(args: DashboardParams) {
    return this.dashboardName === args.name ? "green" : "white";
  }

  private loadBoard(args: DashboardParams) {
    this.showLoading = true;
    this.dashboardDefinition = args.definition;
    AufgabenTemplatedPage.lastAufgabeDashboard = this.dashboardName = args.name;
    this.showLoading = false;
  }
}
