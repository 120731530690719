












































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditDatePicker from "@/editor/EditDatePicker.vue";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditDateTimePicker extends EditDatePicker {
  @Prop() protected timeDate!: string | null;

  private menuLeft: number = 0;
  private menuTop: number = 0;

  protected inputText!: string;
  private mask: string = "D1.M1.1111 H1:11";
  protected showPicker: boolean = false;
  private calendarVal: string = "";
  protected showField: boolean = true;

  public created() {
    this.inputText = "";
  }
  protected get disabledText() {
    if (this.currentDateValue === null) {
      return "";
    }
    return this.valueToFormatStr(this.currentDateValue, this.format);
  }
  public getDefault() {
    return new Date().toISOStringWithTZ();
  }

  public mounted() {
    this.mountedDateTimePicker();
  }

  public mountedDateTimePicker() {
    try {

      this.mountedBaseDatePicker();
      if (this.format.indexOf("EE") >= 0) {
        this.format = this.format.replace(/EE/, "").trim();
      }
      this.mask = this.getMask(this.format);

      if (this.currentDateValue != null) {
        this.inputText = this.valueToFormatStr(
          this.currentDateValue,
          this.format
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  public clickAppend() {
    this.showPicker = !this.showPicker;
    try {
      const rect = (this.$refs
        .markerspan as HTMLSpanElement).getBoundingClientRect();
      const txtRect = (this.$refs
        .masked as any).$vnode.elm.getBoundingClientRect();

      this.menuLeft = rect.left;
      if (rect.width + 250 > window.innerWidth) {
        this.menuLeft -= 250;
      } else if (rect.top + 300 > window.innerHeight) {
        this.menuLeft += txtRect.width;
      }
      this.menuTop = rect.top;
    } catch (e) {
      console.error(e);
    }
  }
  public validate(): boolean {
    try {
      if (this.errorText && this.errorText.length > 0) {
        return false;
      }
      return true;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  private changed(x: any) {
    try {

      let val = this.getDateFromInput(x, this.format);
      console.log(x + " -> " + val);
      if (val) {
        if (val.getFullYear() === 1970 && this.timeDate) {
          const newVal = new Date(this.timeDate).addMinutes(
            val.getHours() * 60 + val.getMinutes()
          );
          val = newVal;
        }
        if (this.required && this.format.indexOf("y") > 0 && val.getFullYear() === 1970) {
          this.errorText += " " + this.requiredMessage;
        }

        this.currentDateValue = val;
        this.calendarVal = val.toISOStringWithTZ().substr(0, 10);
        // this.$emit("input", val.toISOStringWithTZ());
        this.valueChanged(val.toISOStringWithTZ());
      } else {
        if (this.required) {
          this.errorText += " " + this.requiredMessage;
        }
        this.valueChanged(null);
      }
    } catch (e) {
      console.error(e);
    }
  }
  private pickerPicked(args: any) {


    if (args && args.value) {
      const isodate = args.value.toISOStringWithTZ().substr(0, 10);
      let isodateval = "";
      let hour = 0;
      let min = 0;
      if (this.currentDateValue) {
        isodateval = this.currentDateValue.toISOStringWithTZ().substr(0, 10);
        hour = this.currentDateValue.getHours();
        min = this.currentDateValue.getMinutes();
      }
      if (isodate !== isodateval) {
        const newDate = new Date(isodate);
        newDate.setHours(hour);
        newDate.setMinutes(min);
        this.inputText = this.valueToFormatStr(newDate, this.format);
        this.showField = false;
        this.$nextTick(() => {
          this.showField = true;
          this.showPicker = false;

          this.valueChanged(newDate.toISOStringWithTZ());
        });
      }
    }
  }
}
