var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showMutable)?_c('div',{staticStyle:{"margin":"0px"}},[(_vm.showtooltip)?_c('ejs-tooltip',{ref:"combo",attrs:{"content":_vm.tooltip,"disabled":!_vm.showtooltip,"position":_vm.tipposition}}):_vm._e(),(!_vm.useAutocomplete)?_c('div',{staticClass:"e-float-input"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0"},[(!_vm.showSelect)?_c('v-combobox',{ref:"combobox",staticStyle:{"padding-left":"5px"},attrs:{"clearable":_vm.clearable,"dense":"","flat":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"bezeichnung","loading":_vm.showLoading,"cacheItems":false,"no-data-text":_vm.$globalsBezeichnung(
              _vm.Globals.Begriff,
              'Web_keineDaten_gefunden',
              'keine Daten gefunden'
            ),"filter":_vm.filterFunction,"menu-props":"auto","hint":_vm.requiredMessage},on:{"change":_vm.selectedValueChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item},on:{"changeFav":_vm.changeFav}})]}}],null,false,3234668787),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.showSelect)?_c('v-select',{ref:"combobox",staticStyle:{"padding-left":"5px"},attrs:{"clearable":_vm.clearable,"dense":"","flat":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"bezeichnung","loading":_vm.showLoading,"cacheItems":_vm.cacheItems,"no-data-text":_vm.$globalsBezeichnung(
              _vm.Globals.Begriff,
              'Web_keineDaten_gefunden',
              'keine Daten gefunden'
            ),"filter":_vm.filterFunction,"menu-props":"auto"},on:{"change":_vm.selectedValueChanged},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item},on:{"changeFav":_vm.changeFav}})]}}],null,false,3234668787),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1),(_vm.barcodeButtonVisible)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"green","text":""},on:{"click":_vm.scanBarcode}},[_vm._v("qr_code_scanner")])],1):_vm._e(),(_vm.showSettings)?_c('v-col',{staticClass:"py-0 pl-0 mr-5",staticStyle:{"cursor":"hand"},attrs:{"cols":"1"},on:{"click":_vm.openSettings}},[_c('v-icon',{attrs:{"text":""}},[_vm._v("more_vert")])],1):_vm._e()],1),(this.errorText.length > 0)?_c('label',{staticClass:"errorLabel e-float-text",staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.errorText)+" *")]):_vm._e()],1):_vm._e(),(_vm.useAutocomplete)?_c('div',[_c('v-autocomplete',{attrs:{"items":_vm.list,"loading":_vm.showLoading,"search-input":_vm.search,"flat":"","dense":"","item-text":"bezeichnung","item-value":"bezeichnung","label":_vm.description,"placeholder":"Start typing to Search","append-icon":"mdi-database-search","auto-select-first":"","hint":_vm.requiredMessage,"persistent-hint":"","filter":_vm.filterFunction},on:{"change":_vm.selectedValueChanged,"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('EditComboBoxItem',{style:('width:' + _vm.menuwith),attrs:{"item":item},on:{"changeFav":_vm.changeFav}})]}}],null,false,3234668787),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }