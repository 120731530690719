


































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";

@Component
export default class TagesSaldenBelege extends EditPage {
  @Prop({}) protected zeitbereichUebergabe: any;
  constructor() {
    super();
    this.query = "Web_SonstigeBelege";
    this.showLoading = true;
  }

  public created() {
    this.zeitbereich = this.zeitbereichUebergabe;
  }
}
