

















































































































































































































































import BaseEditor from "@/components/BaseEditor.vue";
import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import { getKZValueDisplay } from "@/DataHelper";

@Component({
  provide: {}
})
export default class StammAuftragDetail extends EditPage {
  constructor() {
    super();
    this.query = "Web_Stammdaten_Auftrag";
    this.bewegungsdaten = false;
    this.showLoading = true;
  }
  // @Prop() public query!: string | undefined; // per default nicht initialisiert ;)

  // private queryAIC: any = undefined;
  private position: string = "Left";
  private drawer: boolean = true;
  private mini: boolean = true;

  private showBackdrop: boolean = true;
  private content0 = "...";
  private dialogOpen: boolean = false;
  private showDialog: boolean = false;
  private step: number = 0;
  private myHight: number = window.innerHeight;
  private fab: boolean = false;
  private sttVon: number = 0;
  private sttBis: number = 0;
  private showData: boolean = false;
  private dialog: any = {
    aic: 0,
    title: "--!!--",
    componentName: "None",
    zeitbereich: null,
    showCustomEditor: false,
    showCustomGrid: false,
    query: "Web_Neuanlage_Auftrag",
    lastValues: null,
    newData: false,

    width: 455,
    height: 200,
    fullscreen: false
  };
  private items: any[] = [];
  public mounted() {
    this.items.push({
      text: "Allgemein",
      icon: "assignment",
      color: "primary",
      step: 1
    });
    this.items.push({
      text: "Aufteilung",
      icon: "table_rows",
      color: "success",
      step: 2
    });
    this.items.push({
      text: "Kosten",
      icon: "monetization_on",
      color: "success",
      step: 3
    });
  }
  public initialize() {
    this.sttVon = this.header.aicStt;
    this.sttBis = this.header.aicStt;
  }
  private loaded(x: any) {
    // this.showData = true;
  }
  private valueChanged(x: any) {
    this.showData = true;
    this.queryAIC = x[0];
    this.mountedBase();
    // this.reloadData();
    // this.$nextTick(() => {
    //   this.$forceUpdate();
    // });
  }

  private group: any = null;
  @Watch("group")
  private groupWatch(val: boolean) {
    if (!val) {
      this.drawer = false;
    }
  }
  protected backdrop() {
    const backdropBtn = this.$refs.backdropBtn as any;
    if (backdropBtn.$el.classList.contains("e-active")) {
      backdropBtn.$el.textContent = "True";
      // enable the backdrop property
      this.showBackdrop = false;
    } else {
      backdropBtn.textContent = "False";
      // disable the backdrop property
      this.showBackdrop = true;
    }
  }
  // private onNew() {
  //   // this.loadNew();
  //   // daten alle ausleeren?
  // }
  private newItem(item: any) {
    this.dialog.aic = undefined;
    this.dialog.newData = true;
    this.dialogOpen = true;
  }
  private editItem(item: any) {
    this.dialog.aic = this.queryAIC;
    this.dialog.newData = false;
    this.dialogOpen = true;
  }
  private onBack() {
    this.dialogOpen = false;
  }
  private onDialogClose() {
    this.reloadData();
    this.dialogOpen = false;
  }
}
