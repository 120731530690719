<template>
  <v-input
    :messages="messages"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    @click:append="clickAppend"
    @click:prepend="clickPrepend"
  >
    <div class="wrap" style="width: 100%">
      <div :class="cssContainer">
        <my-masked-input
          :disabled="disabled"
          :value="inputText"
          class="e-control"
          :mask="inputMask"
          :placeholder="placeholder"
          type="text"
          @input="textChanged"
          placeholder-char="-"
          @focus="onFocus"
          @blur="onBlur"
          id="textbox"
          ref="masked"
        />
        <span
          v-if="appendSyncIcon"
          @click="clickAppend"
          :class="'e-input-group-icon ' + appendSyncIcon + ' e-icons'"
          aria-label="select"
        ></span>
        <label :class="cssLabel" id="label_textbox" for="textbox">{{
          label
        }}</label>
        <label
          v-if="errorText && errorText.length > 0"
          style="font-size: 10px"
          class="e-float-text errorLabel"
          >{{ errorText }} *</label
        >
      </div>
    </div>
  </v-input>
</template>
<script>
import vue from "vue";
import { Keyboard } from "@capacitor/keyboard";
import { registerForKeyBoardEvent, unregisterForKeyBoardEvent } from "@/UIHelper";
// from https://niksmr.github.io/vue-masked-input/
export default {
  //  components: { MaskedInput },
  props: {
    label: String,
    value: String,
    mask: String,
    placeholder: String,
    messages: Array,
    appendIcon: String,
    prependIcon: String,
    errorText: String,
    appendSyncIcon: String,
    disabled: Boolean,
  },
  data: () => {
    return {
      inputText: "",
      inputMask: "+++++++++++++++++++++++++",
      focused: false,
      cssLabel: "",
      cssContainer: "",
      hadFocusBeforeKeyboard: false,
    };
  },
  created() {
    this.checkCss();
    const me = this;
    registerForKeyBoardEvent({
      element: this,
      keyboardWillShow: () => {
        element.hadFocusBeforeKeyboard = element.focused;
      },
      keyboardDidShow: () => {
        element.$nextTick(() => {
          if (element.hadFocusBeforeKeyboard) {
            element.focusMe();
          }
        });
      },
      keyboardDidHide: () => {
        element.$nextTick(() => {
          if (element.hadFocusBeforeKeyboard) {
            element.focusMe();
          }
        });
      }
    });
  },
  destroyed() {
    unregisterForKeyBoardEvent(this);
  },
  mounted() {
    const me = this;
    this.$nextTick(() => {
      me.checkCss();

      me.inputText = this.value;
    });
  },
  updated() {
    if (this.value && this.inputText !== this.value) {
      this.inputText = this.value;
    }
    if (this.mask) {
      this.inputMask = this.mask;
    }
  },
  watch: {
    inputText() {
      this.checkCss();
    },
  },
  methods: {
    clickAppend(e) {
      e.cancelBubble = true;
      this.$emit("click:append");
    },
    clickPrepend() {
      this.$emit("click:prepend");
    },
    onFocus() {
      // console.log("focused");
      this.focused = true;
      this.checkCss();
    },
    focusMe() {
      this.$refs.masked.setFocus();
    },
    onBlur() {
      // console.log("blurred");
      this.focused = false;
      this.checkCss();
    },
    textChanged(x) {
      this.$emit("input", x);
    },
    checkCss() {
      let cc = "e-float-input e-control-wrapper e-input-group ";
      if (this.inputText || this.focused) {
        cc += "e-input-focus";
      }
      this.cssContainer = cc;
      let cl = "e-float-text ";
      if (this.inputText || this.focused) {
        cl += "e-label-top";
      } else {
        cl += "e-label-bottom";
      }
      this.cssLabel = cl;
      console.log(this.cssLabel + " - " + this.inputText);
    },
  },
};
</script>
<style scoped>
.inputmask {
  border-bottom: 0px solid black;
}
.inputmask:focus {
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0px solid black;
}
.e-input-group-icon.e-date-icon::before,
.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: "\E901";
  font-family: "e-icons";
}
.e-input-group-icon.e-date-icon::before {
  content: "\E901";
  font-family: "e-icons";
}
.e-time-icon.e-icons::before {
  content: "\E20C";
  font-family: "e-icons";
}

.e-float-input,
.e-float-input.e-control-wrapper {
  line-height: 1.4;
  margin-bottom: 4px;
  margin-top: 16px;
  padding-top: 0;
  position: relative;
  width: 100%;
}
.e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error) input[readonly]:focus ~ label.e-float-text,
.e-small
  .e-float-input:not(.e-error)
  input[readonly]:focus
  ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-small:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error)
  input[readonly]:focus
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error)
  input[readonly]:focus
  ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
  input[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-float-text,
.e-small
  .e-float-input:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-small:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
  input[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-float-text,
.e-small
  .e-float-input:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-small:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
  textarea[readonly]:focus
  ~ label.e-label-top.e-float-text,
.e-float-input:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-float-text,
.e-small
  .e-float-input:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-float-text.e-label-top,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-small:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
  textarea[readonly]
  ~ label.e-label-top.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error) input:focus ~ label.e-float-text,
.e-bigger .e-float-input.e-small:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error) textarea:focus ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-small:not(.e-error) textarea:focus ~ label.e-float-text,
.e-small .e-float-input:not(.e-error) textarea:focus ~ label.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-small:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error) input:focus ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error)
  input:focus
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error)
  input:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error)
  input:focus
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error)
  input:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
  input:focus
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
  input:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error)
  textarea:focus
  ~ label.e-float-text,
.e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-bigger:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-bigger .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-small:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-small .e-float-input:not(.e-error).e-input-focus input ~ label.e-float-text,
.e-float-input.e-small.e-bigger:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-small:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-bigger:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-small
  .e-float-input.e-control-wrapper:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error).e-input-focus
  input
  ~ label.e-float-text,
.e-bigger
  .e-float-input.e-control-wrapper.e-small:not(.e-error).e-input-focus
  input
  ~ label.e-float-text {
  color: rgba(0, 0, 0, 0.87);
}
</style>
