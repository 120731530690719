















































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getMonthNames, getWeekdayNamesShort } from "@/editor/CalenderHelper";
import { getKennungValueDisplay, getKZValue } from "@/DataHelper";
// import { showSnackbar } from "@/UIHelper";
import { Globals } from "@/Globals";
import { reloadApp } from "@/NativeUpdateProvider";
@Component
export default class ReiseUebersicht extends EditPage {
  protected myHeight: number = window.innerHeight - 200;
  protected day: Date = this.siteData.zeitbereich.von;
  protected editZeitbereich = this.siteData.zeitbereich;
  private e1: number = 1;
  private tag: any = 0;
  private wochenTag: any = 0;
  private monat: string = "-";
  private selectFahrzeug: boolean = false;
  private sperre: boolean = false;
  private sperreNacht: boolean = false;
  private activeTabIndex: number = 0;
  private anzahlTage: number = 0;
  constructor() {
    super();
    this.query = "Web_RK_EditWebReisen";
  }

  public mounted() {
    // this.backOnSave = false;
    const Stamm = this.$globalsStt(Globals.Stt, "", "RK_TRAVEL");
    this.$api.setSyncStamm(Stamm.aic, this.siteData.aicReise);
    // this.sperre = this.siteData.sperre;
    this.mountedBase();
    try {
      const days = getWeekdayNamesShort();
      if (!this.day) {
        this.day = this.$api.zeitbereich.von;
      }
      const d = this.day;
      this.wochenTag = days[d.getDay()];
      this.tag = this.day.getDate();
      this.monat = getMonthNames()[this.day.getMonth()];
      const me = this;
      this.editZeitbereich = {
        von: this.day,
        bis: this.day,
        bereich: "Tag"
      };
      if (!this.editZeitbereich) {
        return;
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  public bewSave() {
    this.siteData.onReloadData = true;
    const x = this.$refs.kostenBelege as any;
    x?.reloadData();
    this.$emit("save");
  }
  private onReloadData(reloadData: any) {
    this.siteData.onReloadData = reloadData.dataSaved;
    const x = this.$refs.kostenBelege as any;
    x?.reloadData();
    const y = this.$refs.kostenKM as any;
    y?.reloadData();
  }
  protected initialize() {
    this.anzahlTage = this.siteData.anzahlTage;
    this.sperreNacht = this.siteData.sperreNacht;
    this.sperre = this.siteData.sperre;
    this.selectFahrzeug = getKennungValueDisplay(
      this.data,
      "RK_TRANSPORTMITTEL",
      this.columns
    );
  }
}
