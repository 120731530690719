



import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import { Globals } from "@/Globals"; // könnte man das in die EditComponent rein hängen?
import EditComponent from "./EditComponent";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
@Component
export default class EditMap extends EditComponent {
  @Prop() protected Abflatlong!: any[];
  @Prop() protected Anklatlong!: any[];
  @Prop() protected kmAnzeige!: string;
  @Prop() protected Abfahrtsort!: string;
  @Prop() protected Ankunftsort!: string;
  private lat = 47.92525;
  private long = 16.87635;
  private abflat = 48.20798;
  private abflong = 15.62468;
  private ortLayer: any = L.layerGroup();
  private initalized: boolean = false;
  private mymap?: L.Map;
  private popup = L.popup();
  public mounted() {
    if (this.Abflatlong && this.Anklatlong) {
      this.lat = this.Anklatlong[0];
      this.long = this.Anklatlong[1];
      this.abflat = this.Abflatlong[0];
      this.abflong = this.Abflatlong[1];
    }
    this.mymap = L.map(this.$refs.map as any).setView(
      [this.lat, this.long],
      19
    );

    const logo = L.icon({
      iconUrl: "map_marker.png",
      iconSize: [41, 41],
      iconAnchor: [21, 41]
    });

    const tiles = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
      {
        // tileSize: 512,
        // zoomOffset: -1,
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 19,
        minZoom: 9,
        errorTileUrl: '/path/to/default/tile.png'
      }
    ).addTo(this.mymap);
    // this.mymap = L.map('map', { layers: tiles, tap: false } ) ;
    // L.control.scale({imperial:false}).addTo(this.mymap);
    const abfahrt = L.marker([this.abflat, this.abflong], { icon: logo })
      .bindPopup("Abfahrtsort: " + this.Abfahrtsort)
      .openPopup();
    abfahrt.addTo(this.mymap);
    const ankunft = L.marker([this.lat, this.long], { icon: logo })
      .bindPopup("Ankunftsort: " + this.Ankunftsort)
      .openPopup();
    ankunft.addTo(this.mymap);

    // L.map('mapid', { layers: tiles, tap: false } ) ;
    // L.control.scale({ imperial: false }).addTo(this.mymap);

    this.mymap.fitBounds([
      [this.abflat, this.abflong],
      [this.lat, this.long]
    ]);

    // // Linien mit MouseOver Effekt
    L.polyline(
      [
        [this.abflat, this.abflong],
        [this.lat, this.long]
      ],
      { color: "green", weight: 10, opacity: 1 }
    ) .bindPopup(this.kmAnzeige + " " + "km").openPopup()
      .addTo(this.mymap)
      .on("mouseover", e => {
        e.sourceTarget.setStyle({ color: "red" });
      })
      .on("mouseout", e => {
        e.sourceTarget.setStyle({ color: "green" });
      });
    //     npm install --save leaflet-routing-machine
    // http://www.liedman.net/leaflet-routing-machine/
    //     L.Routing.control({
    //   waypoints: [
    //     L.latLng(57.74, 11.94),
    //     L.latLng(57.6792, 11.949)
    //   ]
    // }).addTo(this.mymap);
  }
  private beforeDestroy() {
    if (this.mymap) {
      this.mymap.remove();
    }
  }

  // private layer = L.marker([this.lat, this.long], { icon: this.icon })
  //   .addTo(this.mymap)
  //   .bindPopup("I am here.")
  //   .openPopup();
  // private circle = L.circle([this.lat, this.long], {
  //   color: "grey",
  //   fillColor: "#f03",
  //   fillOpacity: 0.5,
  //   radius: 100
  // }).addTo(this.mymap);
  // private popup = L.popup() // extra popup - zB für Firmenadresse interessant...
  //   .setLatLng([this.lat, this.long])
  //   .setContent("I am a standalone popup.")
  //   .openOn(this.mymap);‚
  private onMapClick(e: any) {
    console.log("markierte Position:" + e);

    if (this.mymap) {
      this.popup
        .setLatLng(e.latlng)
        .setContent("<p>" + e.latlng.lat + "," + e.latlng.lng + "</p>")
        .openOn(this.mymap);
    }
  }
}
