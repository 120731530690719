

















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
// import Constants from "@/Constants";
import EditComponent from "@/editor/EditComponent";
import { getKennungValueDisplay, getKennungTitle } from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class DienstReiseBeginn extends EditPage {
  private ort: string = "";
  private selectFahrzeug: boolean = false;
  private fahrtkosten: boolean = false;

  constructor() {
    super();
    this.query = "Web_RK_DREnde_Reisezeiten";
  }
  public mounted() {
    this.mountedBase();
  }
  protected initialize() {
    if (this.data) {
      this.ort = getKennungValueDisplay(this.data, "CITY", this.columns);
      const ereignis = getKennungValueDisplay(
        this.data,
        "RK_REISEEREIGNISSE",
        this.columns
      );
      this.selectFahrzeug = getKennungValueDisplay(
        this.data,
        "RK_TRANSPORTMITTEL",
        this.columns
      );
      if (!ereignis) {
        const meldung = "Reise wurde bereits beendet";
        showSnackbar({
          text: meldung,
          color: "warning"
        });
        this.$emit("back");
      }
    } else {
      const meldung = "Reise wurde bereits beendet";
      showSnackbar({
        text: meldung,
        color: "warning"
      });
      this.$emit("back");
    }
  }

  public save() {
    if (this.ort) {
      const aicAbfAll = 0;
      const aic: string = "0";
      const me = this;
      // this.onSave(aicAbfAll, "", "", aic);
      this.onSave(aicAbfAll, "", "", aic)?.then(() => {
        this.$nextTick(() => me.$emit("calculated"));
      });
    } else {
      const ortText = getKennungTitle("CITY", this.columns);

      let meldung = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        "WEB_SpeichernNichtMoeglich",
        "Speichern kann nicht durchgeführt werden da folgende Daten leer sind"
      );
      meldung = meldung + ": " + ortText;
      showSnackbar({
        text: meldung,
        color: "warning"
      });
    }
  }
  private checkValue(evt: any) {
    this.fahrtkosten = evt.value.setShow;
  }
}
