import axios from "axios";
import Vue from "vue";
import AUApi from "./api";
import { isTablet, isPhone, isWeb } from "./DeviceInfoPlugin";
import { Globals, StaticGlobals } from "./Globals";
import LocalisationFunctions from "./local";

export default function OurCommonPlugin<T>(vue: typeof Vue, options?: T): void {
    const local: LocalisationFunctions = new LocalisationFunctions();
    local.api = AUApi.Current;
    Vue.prototype.$api = AUApi.Current;

    StaticGlobals.Current = local;
    Vue.prototype.$begriffBezeichnung = (s: string): string =>
        local.begriffBezeichnung(s);
    Vue.prototype.$begriffAic = (s: number): string => local.begriffAic(s);
    Vue.prototype.$begriffTooltip = (s: string): string =>
        local.begriffTooltip(s);
    Vue.prototype.Globals = Globals;
    Vue.prototype.$globalsAic = (type: Globals, aic: number): string =>
        local.globalsAic(type, aic);
    Vue.prototype.$globalsKennung = (
        type: Globals,
        kennung: string,
        warn: boolean = true
    ): string => local.globalsKennung(type, kennung, warn);

    Vue.prototype.$globalsBezeichnung = (
        type: Globals,
        kennung: string,
        alt: string | null = null
    ): string => {
        const x = local.globalsKennung(type, kennung, false);
        if (x && x.bezeichnung) {
            return x.bezeichnung;
        }
        if (!alt) {
            return kennung;
        }
        return alt;
    };
    Vue.prototype.$globalsBegriffMemo = (
        type: Globals,
        kennung: string,
        alt: string | null = null
    ): string => {
        const x = local.globalsKennung(type, kennung, false);
        if (x?.memo) {
            return x.memo;
        }
        if (!alt) {
            return x.bezeichnung;
        }
        return alt;
    };
    Vue.prototype.$globalsModellFarbe = (
        type: Globals,
        kennung: string,
        alt: string | null = null
    ): string => {
        const x = local.globalsKennung(type, kennung, false);
        if (x && x.farbe) {
            return x.farbe;
        }
        if (!alt) {
            return kennung;
        }
        return alt;
    };
    // Modelle & Formulare können nur bei Mobilen geräten aktiv sein!
    Vue.prototype.$globalsBegriffMobile = (
        type: Globals,
        kennung: string,
        alt: string | null = null
    ): string => {
        const x = local.globalsKennung(type, kennung, false);
        if (x) {
            return x.alias;
        }
        if (!alt) {
            return "null";
        }
        return alt;
    };
    Vue.prototype.$globalsBegriffCache = (
        type: Globals,
        kennung: string,
        alt: string | null = null
    ): string => {
        const x = local.globalsKennung(type, kennung, false);
        if (x) {
            return x.SbRefresh as string;
        }
        if (!alt) {
            return "null";
        }
        return alt;
    };
    Vue.prototype.$globalsStt = (type: Globals, stt?: number, kennung?: string, noFilter?: boolean): string =>
        local.globalsStt(type, stt, kennung, noFilter);
    Vue.prototype.$globalsDruck = (type: Globals, pers: boolean, stammtyp: any, aic?: boolean, aicDruck?: number):
        string => local.globalsDruck(type, pers, stammtyp, aic, aicDruck);
    Vue.prototype.$globalsFrame = (type: Globals, pers: boolean, stammtyp?: any): string =>
        local.globalsFrame(type, pers, stammtyp);
    Vue.prototype.$globalsLayout = (type: Globals): string =>
        local.globalsLayout(type);
    Vue.prototype.$globalsAufgabe = (pers: boolean): string =>
        local.globalsAufgaben(pers);


    Vue.prototype.$isTablet = () => isTablet();
    Vue.prototype.$isPhone = () => isPhone();
    Vue.prototype.$isWeb = () => isWeb();
}
