




































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import EditZahl from "@/editor/EditZahl.vue";

@Component
export default class EditWaehrung extends EditZahl {
  constructor() {
    super();
  }
  // props: ["initValue", "column", "description"],

  protected formatFix: string = "C";
  // private format: string = "00,00"
  protected number: any = "";
  protected decimals: number = 2;
  protected min: any = "";
  protected max: any = "";
  protected errorMessages: string[] = [];

  public mounted() {
    this.number = this.getInitValue();
  }
  public validate() {
    if (this.disabled) {
      this.errorText = "";
      return true;
    } // disabled Controls sind IMMER valide ;)
    if (this.required) {
      if (this.number) {
        this.errorText = "";
        return true;
      }
      if (this.min || this.max) {
        this.errorText = "min: " + this.min + " max: " + this.max;
        return false;
      } else {
        this.errorText = this.requiredMessage;
        return false;
      }
    }
    if (this.min || this.max) {
      this.minmaxText = "min: " + this.min + " max: " + this.max;
      return true;
    }
    if (this.number || this.number === 0) {
      if (this.max && this.number > this.max) {
        this.errorText = this.$begriffBezeichnung("Web_Maximum");
        return false;
      }
      if (this.min && this.number < this.min) {
        this.errorText = this.$begriffBezeichnung("Web_Mimimum");
        return false;
      }
    }

    this.errorText = "";
    return true;
  }
}
