import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { fixKennungForProperty, getValue } from "@/DataHelper";
import { Globals } from "@/Globals";
import IQueryDataProvider from "@/editor/IQueryDataProvider";
import { calcFunction } from "@/CalcHelperStatic";
import { showSnackbar } from "@/UIHelper";
import { goBack } from "@/NavigationHelper";
import { AxiosResponse } from "axios";

export default abstract class CreateList extends Vue implements IQueryDataProvider {
  @Prop() public aic: any;
  @Prop({ default: null }) public formular!: any;
  @Prop({ default: false }) public useVecStamm!: boolean; // dann wird auf die Berechtiging geprüft
  @Prop({ default: false }) public useVec!: boolean; // Vector soll übergeben werdden
  @Prop() public zeitbereich!: APIZeitbereich | null;
  @Prop() public query!: string | undefined;
  @Prop() public queryEdit!: string | undefined;
  @Prop({ default: null }) public siteData: any;
  @Prop({ default: false }) public monthly!: boolean;
  @Prop({ default: false }) public showError!: boolean;
  @Prop({ default: false }) public showTagessaldo!: boolean;
  @Prop({ default: false }) public showNacherfassung!: boolean;
  @Prop({ default: false }) public showProjektstunden!: boolean;
  @Prop({ default: false }) public showReisekosten!: boolean;
  @Prop({ default: false }) public freigabeAntrag!: boolean;

  @Prop() public dataloadedCallback?: (data: QueryResponse) => void;

  protected queryMutable: string = "";
  protected queryAIC: any = null;
  protected editZeitbereich: APIZeitbereich | null = null;
  protected initialized: boolean = false;
  protected vecStamm: any[] = [];
  protected items: ListItems[] | null = null;
  protected showLoading: boolean = false;
  protected header: any = "...";
  public data: any = {};
  public columns: any[] = [];

  protected ampelZulage: any = 0;
  protected colorZulagen: string = "grey";
  protected statusAmpel: string = "grey";
  protected zulagenAmpel: any = 0;

  protected showCalc: boolean = false;
  protected showCalcText: boolean = false;

  public mountedBase(): boolean {
    try {
      if (this.aic) {
        this.queryAIC = this.aic;
      }
      // if (this.siteData?.isVorgesetzter) {
      //   this.aic = this.siteData.isVorgesetzte;
      // }
      if (!this.editZeitbereich && this.zeitbereich) {
        this.editZeitbereich = this.zeitbereich;
      }
      if (
        (this.query == null || this.query === "") &&
        this.queryMutable === ""
      ) {
        showSnackbar(
          this.$globalsBezeichnung(
            Globals.Begriff,
            "Web_keine_Abfrage",
            "keine Abfrage gefunden"
          )
        );
        this.showLoading = false;
        this.header.bezeichnung = { bezeichnung: "" };
        return false;
      }
      this.queryMutable = this.query ?? this.queryMutable;
      this.reloadData();
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
      return false;
    }
    return true;
  }

  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }

  public reloadData() {
    // if (this.showLoading) { return; }// Problem mit eingebetteten Masken.. diese werden dann nicht mehr geladen..
    this.reloadDataBase();
  }
  public reloadDataBase() {
    this.showLoading = true;
    const me = this;
    let formAic = "";
    if (this.formular) {
      formAic = this.formular.aic;
    }
    let setztVector = "";
    if (this.useVecStamm) {
      setztVector = "1"; // es wird auf die berechtigung geprüft!
    }
    if (this.queryMutable) {
      this.$api.getQuery(
        this.queryMutable,
        data => me.dataLoaded(data),
        this.queryAIC,
        this.editZeitbereich,
        0,
        false,
        false,
        setztVector,
        formAic,
        this.vecStamm
      );
      this.initialized = true;
    }
  }

  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
  }
  protected dataLoadedBase(response: AxiosResponse<QueryResponse>) {
    // this.showLoading = false;
    // console.log("CreateList data loaded " + (response?.data?.data?.length ?? 0) + " rows ");
    // Fehlerbehandlung
    if (response.data.error !== undefined) {
      this.$api.onfail(response.data.error);
      console.log(response.data.error);
      alert("ACHTUNG: " + response.data.error);
      this.$emit("back");
      return;
    }

    const queryResp = response.data as QueryResponse;

    this.header = queryResp.header;
    this.columns = queryResp.columns;
    this.data = queryResp.data;

    if (this.dataloadedCallback) {
      this.dataloadedCallback(queryResp);
    }

    this.refreshItems();
    this.showLoading = false;
  }

  protected abstract refreshItems(): void;

  public registerEditComponent(comp: Vue, column: any): void {
    // validierung brauch ma noch nicht
  }

  protected openSettings(subForm: string, title: string) {
    this.$root.$emit("showSite", {
      site: subForm,
      title,
      data: { zeitbereich: this.editZeitbereich }
      // replaceCurrent: true
    });
  }
  protected loadAmpel(
    query: string,
    bool3?: boolean,
    status?: boolean,
    aic?: string,
    checkData?: boolean
  ) {
    const setzBool3 = bool3;
    const statusSetzen = status;
    const ampelQuery = query;
    const me = this;
    const vecStamm: any[] = [];
    let aicUebergabe = this.queryAIC;
    if (aic) {
      aicUebergabe = aic;
    }
    let formAic = "";
    if (this.formular) {
      formAic = this.formular.aic;
    }
    return this.$api.getQuery(
      ampelQuery,

      (data: any) => me.showAmpelData(data, setzBool3, statusSetzen, checkData),
      aicUebergabe,
      this.editZeitbereich,
      0,
      false,
      false,
      "",
      formAic,
      vecStamm
    );
  }
  protected showAmpelData(
    ampelData: any,
    setzBool3?: boolean,
    status?: boolean,
    checkData?: boolean
  ) {
    if (ampelData.data.error !== undefined) {
      this.$api.onfail(ampelData.data.error);
      console.log(ampelData.data.error);
      alert("ACHTUNG: " + ampelData.data.error);
      this.$emit("back");
      return;
    }
    const isBool3 = setzBool3;
    const isStatus = status;
    const datenAmpel = ampelData.data.data;
    this.columns = ampelData.data.columns;
    if (datenAmpel.length > 0) {
      const row = datenAmpel[0];
      this.ampelZulage = this.getKennungValue(row, "ampel");
      this.zulagenAmpel = this.ampelZulage;
      let color: string = "";
      if (checkData) {
        this.colorZulagen = "warning";
      } else if (isBool3) {
        if (this.ampelZulage === 1) {
          color = "success";
          this.showCalc = false;
          this.showCalcText = true;
        } else if (this.ampelZulage === 2) {
          color = "warning";
          this.showCalc = true;
          this.showCalcText = false;
        } else if (this.ampelZulage === 3) {
          color = "error";
          this.showCalc = true;
          this.showCalcText = false;
        } else if (this.ampelZulage === 0) {
          color = "warning";
          this.showCalc = true;
          this.showCalcText = false;
        }
      } else {
        if (this.ampelZulage === 1) {
          color = "success";
          this.showCalc = false;
          this.showCalcText = true;
        } else if (this.ampelZulage === 2) {
          color = "warning";
          this.showCalc = true;
          this.showCalcText = false;
        } else if (this.ampelZulage === 3) {
          color = "error";
          this.showCalc = false;
          this.showCalcText = true;
        }
      }
      if (color) {
        if (isStatus) {
          this.statusAmpel = color;
        } else {
          this.colorZulagen = color;
        }
      }
    }
  }
  protected getKennungValue(data: any, kennung: string) {
    if (!kennung) {
      return "-!-";
    }

    const col = this.getColumnByKennung(kennung);
    if (!col) {
      return "";
    }
    const val = getValue(data, col);
    return val;
  }

  protected getColumnByKennung(kennung: string) {
    return this.columns.find(
      (c: any) =>
        c.kennung &&
        (c.kennung === kennung ||
          fixKennungForProperty(c.kennung) === kennung)
    );
  }
  protected onDelete(aic: any, modell?: string, zeitbereich?: any, stammAic?: number) {
    const bewPool = aic;
    this.$api.DeleteData(this.header, bewPool, "", zeitbereich)?.then((res: any) => {
      if (res) {
        const msgType = res.data.msgType;
        let info = res.data.info;
        const memo = res.data.memo;
        const title = res.data.titel;
        if (memo) {
          info = memo;
        }
        if (info) {
          if (msgType === "Error") {
            showSnackbar({
              text: info,
              color: "error"
            });
          } else if (msgType === "Info") {
            showSnackbar({
              text: info,
              color: "info"
            });
          } else if (msgType === "Warnung") {
            showSnackbar({
              text: info,
              color: "warning"
            });
          }
          this.showLoading = false;
          return msgType;
        }
      }
      showSnackbar(
        this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_geloescht",
          "Daten wurden gelöscht"
        )
      ); // Geloescht

      const me = this;
      if (modell) {
        calcFunction(
          modell,
          me.aic,
          false,
          me,
          this.editZeitbereich,
          ""
        )
          ?.then((response: any) => {
            me.reloadData();
            return;
          });
      } else {
        me.reloadData();
        return;
      }
    });
  }
}
