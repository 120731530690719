























































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";
import {
  fixKennungForProperty,
  getDisplay,
  getKZValueDisplay
} from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class MyVCarousel extends Vue {
  @Prop() public query!: string | undefined;
  @Prop() protected aic!: any;
  @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  @Prop() protected subForm!: string;
  @Prop() protected subFormAic!: string;
  @Prop() protected mainForm!: string;

  private header: any = { bezeichnung: " -- " };
  private queryAIC: any = undefined;
  private rowdata: any[] = [];
  public columns: any[] = [];
  private items: any[] = [];
  private overlay: any = false;
  private showArrows: any = false;
  private hideDelimiters: any = false;
  private cycle: any = false;
  private headerStamm: any = "";
  private rowAic: any = "";
  private initialized: boolean = false;

  public mounted() {
    console.log("Carousel list start...");
    try {
      if (this.query == null || this.query === "") {
        showSnackbar(
          this.$globalsBezeichnung(
            Globals.Begriff,
            "Web_keine_Abfrage",
            "keine Abfrage gefunden"
          )
        );

        this.header = { bezeichnung: ";(" };
        this.initialized = true;
        return;
      }

      const me = this;
      const vecStamm: any[] = [];
      this.$api.getQuery(
        // Query
        this.query,
        // Done function
        (data: any) => me.dataLoaded(data),
        this.queryAIC,
        this.zeitbereich,
        0,
        false,
        false,
        "0",
        "",
        vecStamm
      );
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }
  private dataLoaded(data: any) {
    console.log("list data loaded");
    // Fehlerbehandlung
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      alert("ACHTUNG: " + data.data.error);
      this.$emit("back");
      return;
    }
    this.headerStamm = data.data.header.aicStt;
    this.header = data.data.header.bezeichnung;
    this.columns = data.data.columns;
    this.rowdata = data.data.data;

    if (this.headerStamm) {
      // ist der Stammtyp des Formulars nicht ident
      // mit dem Mitarbeiter Stt - dann SnyStamm setzen!
      if (this.$api.user.stt !== this.headerStamm) {
        if (this.rowdata.length > 0) {
          this.$api.setSyncStamm(this.headerStamm, this.rowdata[0].aic_Stamm); // das stimmt noch nicht...
        }
      }
    }

    for (let i = 0; i < this.rowdata.length; i++) {
      const row = this.rowdata[i];

      // if (this.headerStamm) {
      //   this.$api.setSyncStamm(this.headerStamm, row.aic_Stamm);
      // }
      const header = getKZValueDisplay(row, "header", this.columns);
      const titel = getKZValueDisplay(row, "titel", this.columns);
      const subtitel = getKZValueDisplay(row, "subtitel", this.columns);
      const text = getKZValueDisplay(row, "text", this.columns);
      const icon = getKZValueDisplay(row, "icon", this.columns);
      const color = getKZValueDisplay(row, "color", this.columns);

      this.items.push({
        header,
        titel,
        subtitel,
        text,
        divider: true,
        inset: true,
        icon,
        color
      });
    }
    this.initialized = true;
  }
  protected openSettings(subForm: string, title: string) {
    if (this.headerStamm) {
      const subAic = this.subFormAic;
      // Aic von SubFormular holen
      const sub = this.$globalsKennung(Globals.Begriff, subAic);
      const subStamm = sub.stt; // Stammtyp SubFormular
      this.rowAic = this.rowdata[0].aic_Stamm;
      if (this.headerStamm !== subStamm) {
        if (this.rowdata.length > 0) {
          this.$api.setSyncStamm(this.headerStamm, this.rowdata[0].aic_Stamm);
        }
      }
    }
    // const titel = "$globalsBezeichnung(Globals.Begriff, 'this.subForm', 'neue Seite')";
    this.$root.$emit("showSite", {
      site: this.subForm,
      title: "neue Seite",
      data: { aic: this.rowAic }
    });
  }
}
