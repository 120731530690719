<template>
  <ejs-schedule id="Schedule" ref="ScheduleObj" height="650px" cssClass='time-scale' :currentView="currentView" :eventSettings="eventSettings" :group="group" :renderCell="onRenderCell">
    <e-views>
      <e-view option="TimelineWeek"></e-view>      
    </e-views>
    <e-resources>
      <e-resource field="TaskId" title="Category" name="Categories" :dataSource="categoriesData" :allowMultiple="allowMultiple" 
        textField="text" idField="id" groupIDField="groupId" colorField="color"></e-resource>
    </e-resources>
  </ejs-schedule>
</template>

<script>
import {
  ScheduleComponent,
  TimelineViews,
  DragAndDrop,
  Resize,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective
} from "@syncfusion/ej2-vue-schedule";
import { Globals } from "@/Globals";
import Constants from "@/Constants";

export default {
  name: "App",
  components: {
    "ejs-schedule": ScheduleComponent,
    "e-views": ViewsDirective,
    "e-view": ViewDirective,
    "e-resources": ResourcesDirective,
    "e-resource": ResourceDirective
  },
  data() {
    return {
      eventSettings: {
        dataSource: []
      },
      currentView: "TimelineWeek",
      allowMultiple: true,
      group: {
        resources: ["Categories"]
      },
      selectedDate: this.$api.zeitbereich.von,
      categoriesData: [
        { text: "Nancy", id: 1, groupId: 1, color: "#df5286" },
        { text: "Steven", id: 2, groupId: 1, color: "#7fa900" },
        { text: "Robert", id: 3, groupId: 2, color: "#ea7a57" },
        { text: "Smith", id: 4, groupId: 2, color: "#5978ee" },
        { text: "Michael", id: 5, groupId: 3, color: "#df5286" },
        { text: "Root", id: 6, groupId: 3, color: "#00bdae" }
      ]
    };
  },
  methods: {
    onRenderCell(args) {
      if (args.elementType === "resourceHeader") {
        // Fetching resource details using public method
        const resourceDetails = this.$refs.ScheduleObj.getResourcesByIndex(
          args.groupIndex
        );
        // Setting up the color to the resource from fetched resource details
        args.element.style.backgroundColor =
          resourceDetails.resourceData[resourceDetails.resource.colorField];
      } else if (args.date && args.element) {
        const days = [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday"
        ];
        args.element.classList.add("e-" + days[args.date.getDay()]);
      }
    }
  },
  provide: {
    schedule: [TimelineViews, DragAndDrop, Resize]
  }
};
</script>
<style>
.e-sunday {
  background-color: #f5e5e6 !important;
}

.e-monday {
  background-color: #e7eef6 !important;
}

.e-tuesday {
  background-color: #e8eaf6 !important;
}

.e-wednesday {
  background-color: #e3f2fd !important;
}

.e-thursday {
  background-color: #e1f5fe !important;
}

.e-friday {
  background-color: #e0f7fa !important;
}

.e-saturday {
  background-color: #e0f2f1 !important;
}
.dvh .e-appointment {
  height: 60px !important;
  margin: 1px;
}
.e-schedule .e-timeline-view .e-work-cells,
.e-schedule .e-timeline-month-view .e-work-cells {
  height: 60px !important;
}
.e-schedule .e-timeline-view .e-resource-cells,
.e-schedule .e-timeline-month-view .e-resource-cells {
  /* background-color: #aacadf !important; */
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
  height: 60px !important;
  padding-left: 15px;
  padding-right: 0;
  vertical-align: middle;
}
/* .e-schedule .e-timeline-view .e-resource-group-cells,
.e-schedule .e-timeline-month-view .e-resource-group-cells,
.e-schedule .e-work-cells .e-resource-group-cells .e-work-hours {
  background-color: #aacadf !important;
} */
/* das darf ich nicht - sonst sind die Fehlzeiten falsch gefärbt..
.e-schedule .e-month-view .e-appointment {
    background: rgb(237, 194, 102) !important;
    border: 1px solid #e8eaf6;
    border-radius: 2px;
    color: #fff;
    cursor: default;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    height: 22px;
    overflow: hidden;
    position: absolute;
} */
/* .e-schedule .e-timeline-view .e-child-node,
.e-schedule .e-timeline-month-view .e-child-node {
  background-color: #fff !important;
} */
/* .e-schedule .e-timeline-view .e-appointment,
.e-schedule .e-timeline-month-view .e-appointment {
  height: 100%;
} */
.dvh .e-schedule .template-wrap {
  display: flex;
  text-align: left;
  height: 100%;
  line-height: 57px;
}
.dvh .templatewrap {
  text-align: center;
  width: 100%;
  height: 100%;
}
.dvh .templatewrap img {
  width: 20px;
  height: 20px;
}
.dvh .weekend {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05) !important;
}
.e-schedule .e-timeline-month-view .e-current-day,
.e-schedule .e-timeline-month-view .e-current-date {
  background: #cfe1ed !important;
}
.e-schedule .e-timeline-month-view .e-weekend,
.e-schedule .e-timeline-month-view .e-weekend {
  background: red !important;
}
.e-treeview .e-list-item.e-active > .e-fullrow {
  background-color: transparent !important;
}
.dvh.e-schedule .e-timeline-view .e-resource-left-td .e-resource-text {
  display: flex;
  font-weight: 500;
  padding: 0;
  height: 36px;
}
</style>
<style scoped>
.dvh.e-schedule .e-schedule-toolbar .e-icons {
  font-family: "Schedule_print", "e-icons";
}

.dvh.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e204";
}

.dvh.e-schedule .e-schedule-toolbar .e-toolbar-item.e-today {
  display: none;
}
.dvhDialog {
  padding: 0px;
  width: 450px;
  /* NOTCH bei Iphone umgehen */
  padding-top: 20px;
  padding-bottom: 20px;
  padding-top: constant(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-top);
  /* Status bar height on iOS 11+ */
  padding-bottom: env(safe-area-inset-top);
}
.dvh {
  min-width: 500px;
  min-height: 250px;
  width: 100%;
  height: 100%;
}
.dvh.e-schedule .e-schedule-table {
  border: 0 none;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.dvh.e-schedule .e-read-only {
  opacity: 0.5;
}
.dvh.e-schedule .e-vertical-view .e-day-wrapper .e-appointment.e-past-app {
  background-color: #d2691e;
}
/* .templatewrap {
  text-align: right;
  width: 100%;
  height: 100%;
} */
.dvh.e-schedule .e-date-header-wrap .e-schedule-table thead {
  display: none;
}
.dvh.e-schedule .e-date-header div {
  display: inline-grid !important;
  width: -webkit-fill-available;
  height: fit-content !important;
  text-align: center;
}
@media (max-width: 550px) {
  .dvh {
    min-width: 300px;
    min-height: 450px;
    width: 100%;
    height: 100%;
  }
  .dvhDialog {
    padding: 0px;
    width: auto;
  }
  .e-schedule .e-month-view .e-work-cells {
    height: auto;
    vertical-align: top;
    max-width: auto;
  }
  .e-schedule .e-timeline-view .e-work-cells {
    position: relative;
  }
  .dvh.e-schedule .e-timeline-view .e-resource-left-td {
    width: 100px;
  }
}
.dvh.e-quick-popup-wrapper .e-event-popup .e-custom-row {
  color: #212529;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
}

.dvh.e-quick-popup-wrapper .e-event-popup .e-custom-icon {
  color: #495057;
  font-size: 18px;
  padding-right: 13px;
  font-size: 16px;
  padding: 2px 10px 0 0;
}

.dvh.e-quick-popup-wrapper .e-event-popup .e-custom-status {
  color: #212529;
  cursor: default;
  font-size: 14px;
}

.dvh.e-quick-popup-wrapper .e-custom-icon::before {
  content: "\e77d";
}
.dvh .e-custom-icon .e-custom-icon::before {
  content: "\e77d";
}
/* .dvh.template-wrap {
  width: 100%;
} */
.dvh.e-schedule .e-employee-cells.e-child-node .employee-category,
.block-events.e-schedule .e-employee-cells.e-child-node .employee-name {
  padding: 5px;
}
.cssPersonlich {
  background-color: green;
}
.cssAlle {
  background-color: lightblue;
}
.dvh .block-events.e-schedule .template-wrap {
  width: 100%;
}

/* .dvh .block-events.e-schedule .e-vertical-view .e-resource-cells {
 height: 58px;
} */

.dvh .block-events.e-schedule .e-timeline-view .e-resource-left-td,
.dvh .block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 170px;
}

.dvh .block-events.e-schedule .e-resource-cells.e-child-node .employee-category,
.dvh .block-events.e-schedule .e-resource-cells.e-child-node .employee-name {
  padding: 5px;
}
.dvh
  .groupbychild.e-schedule:not(.e-device)
  .e-agenda-view
  .e-content-wrap
  table
  td:first-child {
  width: 90px;
}

.dvh .groupbychild.e-schedule .e-agenda-view .e-resource-column {
  width: 100px;
}
.dvh .block-events.e-schedule .employee-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
}

.dvh .block-events.e-schedule .employee-name {
  font-size: 13px;
}

.dvh .block-events.e-schedule .employee-designation {
  font-size: 10px;
}
/* .dvh .e-schedule .e-timeline-view .e-date-header-wrap table tbody td,
.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td {
  height: 36px;
  overflow: hidden;
  padding: 0px;
  text-overflow: ellipsis;
  white-space: normal;
} */
.dvh .e-upload {
  border: 0;
  padding-left: 0 !important;
}

.dvh .e-upload .e-file-select-wrap {
  padding: 0;
}

.dvh .e-upload .e-file-select-wrap .e-file-drop {
  display: none;
}
.excel-export.e-schedule
  .e-schedule-toolbar
  .e-icon-schedule-excel-export::before {
  content: "\e242";
}
</style>
