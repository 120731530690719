























































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import Constants from "@/Constants";
import { checkTokenRefresh } from "@/TokenHelper";
import { showDialog } from "@/UIHelper";
import { Globals } from "@/Globals";
import { onShowSite, showSite } from "@/NavigationHelper";
import {
  getAzureUserName
} from "@/azureLoginHelper";
@Component
export default class UserMenu extends Vue {
  @Prop() protected tokenEnde!: any;
  private showToken: string = "";
  private printItems: PrintItems[] = [];
  private closeOnContentClick: boolean = true;
  private mounted() {
    this.showToken = this.tokenEnde;
    this.addDruck();
  }
  private addDruck() {
    const stammTyp = this.$globalsStt(Globals.Stt, "", "ARBEITNEHMER");
    this.printItems = this.$globalsDruck("Begriff", true, stammTyp);
  }
  private openDruckVorschau(item: any) {
    const me = this;
    const printItem = item;
    let fullscreen = false;
    console.log("isPhone: " + this.$isPhone());
    if (Constants.isMobile && this.$isPhone()) {
      fullscreen = true;
    }

    showDialog({
      title: printItem.bezeichnung,
      site: "BaseDruck",
      fullscreen,
      width: 510,
      data: { zeitbereich: this.$api.zeitbereich, printItem, pers: true },
      titleColor: "dvhBackground",
      titleClass: "white--text",
      onClose: () => {
        return true;
        /* nix tun*/
      }
    });
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(this.Globals.Begriff, kennung, false);
    return x;
  }
  protected openSettings(formular: string, title: string) {
    onShowSite({
      site: formular,
      title
    });
  }
  private loadToken() {
    const me = this;
    const res = checkTokenRefresh();
    this.showToken = res;
    this.$api.user.time = res;
  }
   private get isAzureLoginAccepted() {
    return getAzureUserName();
  }
}
