


















































































































































import { Component, Prop } from "vue-property-decorator";
// import EditComponent from "@/editor/EditComponent";
import EditPage from "@/components/EditPage";
import EditDateRange from "@/editor/EditDateRange.vue";
import { Globals } from "@/Globals";
import {
  getKennungValueDisplay,
  getKennungValue,
  getKennungTitle,
  getColumnByKennung
} from "@/DataHelper";
import { showSnackbar } from "@/UIHelper";

@Component
export default class Dialog extends EditPage {
  @Prop() public checkDateRange!:
    | ((von: Date, bis: Date, aic: string) => boolean)
    | undefined;
  private IsAllDay: boolean = false;
  private color: any = "success";
  private stellVertreter: boolean = false;
  private disabled: boolean = false;
  private stellBew: any = false;
  private date: any = "";
  private bewilligung: boolean = false;
  private stellOffen: boolean = false;
  private maName: string = "";
  private zeitart: string = "";
  private maNameTitel: string = "";
  private zeitartTitel: string = "";
  private b2: any = "";
  private b2Aic: any = "";
  private b2Titel: number = 0;
  private checkStellvertreter: string = "";
  private step: number = 1;
  private resturlaub: string | undefined = "";
  private loading: boolean = false;
  private loader: any = null;
  private aicOffen: number = 0;
  private aicEig: number = 0;
  protected saveVisible: boolean = false;
  protected deleteVisible: boolean = false;
  private editErlaubt: boolean = false;
  private bewilligenNein: boolean = false;
  private saveModell = "";

  constructor() {
    super();
    this.query = "Web_Planung_Dialog_Vorgesetzter";
    this.showLoading = true;
    // if (this.checkBegriff("Web_VGkeineBewilligung_Planungen")) {
    //   this.bewilligenNein = true;
    // möchte Wernig.. aber noch nicht bestellt
    // }
    if (this.checkBegriff("Web_KorrekturPlanunngen")) {
      // ist dieser Begriff freigeschalten - soll auch VG neue Urlaube erfassen können
      this.editErlaubt = true;
      this.query = "WebPlanungDialogVG_Erfassbar";
      this.saveVisible = true;
      this.deleteVisible = true;
      // this.saveModell = "WEB_PZE_PlanungDoppelbuchungen";
    }
  }
  public mounted() {
    this.mountedBase();
  }
  public get showDelete() {
    return this.data && this.data.aic_Bew_pool && this.data.aic_Bew_pool > 0;
  }

  public onDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.$emit("delete", this.data.aic_Bew_pool);
    }
  }
  private checkValue(evt: any) {
    const value = evt.value;
    const combo = evt.field.meta.combo;
    const textOffen = combo[0].bezeichnung;
    const aktAic = combo[2].aic; // = abgelehnt
    if (value === aktAic) {
      this.b2Aic = this.aicOffen;
      this.b2 = textOffen;
      const spalte = "e" + this.aicEig;
      for (const i in this.data) {
        if (i === spalte) {
          this.data[i] = this.b2Aic;
        }
      }
    }
  }
  protected initialize() {
    this.maNameTitel = getKennungTitle("MA", this.columns);
    this.zeitartTitel = getKennungTitle("ZA", this.columns);
    this.maName = getKennungValueDisplay(this.data, "MA", this.columns);
    this.zeitart = getKennungValueDisplay(this.data, "ZA", this.columns);
    const allDay = getKennungValueDisplay(
      this.data,
      "B_CALC_FIELD",
      this.columns
    );
    if (allDay === true) {
      this.IsAllDay = true;
    }
    const zuBewilligen = getKennungValueDisplay(
      this.data,
      "GRANTED",
      this.columns
    );
    if (zuBewilligen === "ja") {
      this.bewilligung = true;
    }
    const checkBewilligt = getKennungValueDisplay(
      this.data,
      "b1",
      this.columns
    );
    this.checkStellvertreter = getKennungValueDisplay(
      this.data,
      "SV",
      this.columns
    );

    if (this.checkStellvertreter) {
      this.stellVertreter = true;
      this.b2Titel = getKennungTitle("b2", this.columns);
      this.b2 = getKennungValueDisplay(this.data, "b2", this.columns);
      this.b2Aic = getKennungValue(this.data, "b2", this.columns);
      const column = getColumnByKennung("b2", null, this.columns);
      const combo = column.meta.combo;
      this.aicEig = column.aicEig;
      this.aicOffen = combo[0].aic;

      if (this.b2 === "bewilligt") {
        this.stellBew = true;
      } else if (checkBewilligt === "offen") {
        this.stellOffen = true;
      }
    }
    const self = this.checkBegriff("Web_VG_Selfservice");
    if (!self) {
      const maAIC = getKennungValue(this.data, "MA", this.columns);
      const userAIC = this.$api.user.aic;
      if (maAIC === userAIC) {
        this.disabled = true;
      }
    }
  }
  private checkChanged(val: any) {
    this.saveVisible = true;
  }
  private checkIntersection(skipChanging: boolean = false) {
    if (this.checkDateRange) {
      const dateRange = this.$refs.dateRange as EditDateRange;
      if (
        dateRange &&
        (skipChanging || dateRange.changing) &&
        dateRange.von &&
        dateRange.bis
      ) {
        const von = dateRange.von;
        let bis = dateRange.bis;
        if (von.valueOf() === bis.valueOf() && dateRange.IsAllDay) {
          bis = bis.addDays(1);
        }
        if (!this.checkDateRange(von, bis, this.data.aic_Bew_pool)) {
          return false;
        }
      }
    }
    return true;
  }
  private save() {
    if (!this.checkIntersection()) {
      showSnackbar(
        this.$globalsBezeichnung(
          Globals.Begriff,
          "Ueberschneidung",
          "Überschneidung!"
        )
      );
      return;
    }
    const aicAbfAllBez = this.$globalsKennung(
      Globals.Begriff,
      "WebPlanungSpeichern"
    );
    const aicAbfAll = aicAbfAllBez.aic;
    let aic: any;
    const me = this;
    if (this.aic) {
      aic = this.aic;
    }
    const getData = true;
    if (aicAbfAll) {
      this.onSave(aicAbfAll, "", "", aic, getData)?.then(() => {
        this.$nextTick(() => me.$emit("save"));
      });
    } else {
      showSnackbar({
        text: "Abfrage nicht gefunden",
        color: "error"
      });
      this.showLoading = false;
      return;
    }
  }
  private calcSalden() {
    this.loading = true;

    const modell = "Web_Resturlaub_per_Stichtag";
    let begriff: any;
    if (modell) {
      begriff = this.$globalsKennung(Globals.Begriff, modell);
    }
    this.$api.postCalc(
      [],
      [],
      begriff.aic,
      this.aic,
      this.zeitbereich,
      0,
      0,
      "",
      this.successCalc,
      begriff.maxB
    );
  }
  private successCalc(res: any) {
    this.resturlaub = res.data.ergebnis;
    this.loading = false;
    this.step++;
    return;
  }
}
