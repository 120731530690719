






















































































































































































































































































































































































































































































































































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import BaseSyncGrid from "@/editor/EditComponent";
import { calcFunction } from "@/CalcHelperStatic";
import {
  getDisplay,
  getKennungValueDisplay,
  getKZValueDisplay,
  getKZTitle,
  getValue,
  getKZValue,
  getDisplayValue
} from "@/DataHelper";
import EditPage from "@/components/EditPage";
import { Globals } from "../Globals";
import IQueryDataProvider from "@/editor/IQueryDataProvider";
import CreateList from "./CreateList";
import Constants from "@/Constants";
import { onShowSite, refreshFullPageContent } from "@/NavigationHelper";
import { closeDialog, showDialog, showSnackbar } from "@/UIHelper";
let lastGroupHeader = "";
let htmlprefixID: number = 0;
@Component
export default class BaseList extends CreateList {
  @Prop() protected Anwesend!: boolean;
  @Prop() protected print!: boolean;
  @Prop() protected showPrint!: boolean;
  @Prop({ default: true }) protected dialog!: boolean;
  @Prop({ default: false }) protected showClose!: boolean;
  @Prop({ default: false }) protected showFilter!: boolean;
  @Prop({ default: false }) protected showHeader!: boolean;
  @Prop({ default: false }) protected showSpeedDial!: boolean;
  @Prop({ default: false }) protected showAvatarLeft!: boolean;
  @Prop({ default: false }) protected showAvatar!: boolean;
  @Prop({ default: false }) public showChart!: boolean;
  @Prop() protected druckItems!: PrintItems[];
  @Prop() protected fixHeight!: number;
  @Prop({ default: false }) protected edit!: boolean;
  private showDialog: boolean = false;
  private currentVal: any = null;
  // protected vecStamm: any[] = [];
  public textKennung!: string;
  protected filteredItems: ListItems[] = [];
  protected pagedItems: ListItems[] = [];
  private menu: boolean = false;
  protected items: ListItems[] | null = null;
  protected groupedItems: ListItems[] = [];

  private filterColumns: FilteredDataColumn[] | null = null;
  private groupColumns: DataColumn[] | null = null;
  private groupColumn: DataColumn | null = null;
  private visibleColumn: any[] = [];
  private buttonItems: ButtonItems[] = [];
  private showQuittMsg: boolean = false;
  private showSelect: boolean = false;
  private selectAll: boolean = false;
  private showChartInternal: boolean = false;
  private subForm: string = "";

  private pageSize = 10;
  private pages = 1;
  private currentPage = 1;
  private fields: any = { id: "id", text: "text", groupBy: "groupBy" };
  private avatarIcon: any;
  private subHeader: boolean = false;
  private title: string = "";
  public searchText: string = "";
  protected backupData: any;
  private myHeight: number = 300;
  private jarVersion: boolean = false;
  @Prop({ default: false }) public pers!: boolean;

  private emptyFilterText: string = "- leer -";
  private emptyGroupText: string = "- leer -";
  private colorFilter: string = "white";

  // eindeutige ID als prefix für die liste erfinden - dass 2 in der selben ms erzeugt werden is extrem unwarscheinlich
  private htmlKeyPrefix = htmlprefixID++;

  // eindeutige ID generiern mit dem index
  private getListItemHtmlId(index: number, asId: boolean = false) {
    return (asId ? "" : "#") + "li" + this.htmlKeyPrefix + "_" + index;
  }

  private resetGroupHeader() {
    lastGroupHeader = "xxxxx";
  }
  private showGroupHeader(item: any) {
    const showit = lastGroupHeader !== item.group;
    lastGroupHeader = item.group;
    return showit;
  }
  public mounted() {
    this.showChartInternal = this.showChart;
    this.title = this.$globalsBezeichnung(
      Globals.Begriff,
      "Web_DatenLaden",
      "lade..."
    );
    // console.log("Baselist start...");
    if (this.fixHeight) {
      this.myHeight = this.fixHeight;
    } else {
      this.myHeight = window.innerHeight - 200;
    }
    const jar = this.$api.user.AllVersion;
    this.jarVersion = !jar.includes("5.15"); // erst ab 5.16 können pers. Parameter gespeichert werdeb
    this.mountedBase();
  }

  @Prop() protected checkDetailFormColumnCallback?: (
    col: DataColumn
  ) => boolean;
  protected checkDetailFormColumn(col: DataColumn): boolean {
    if (this.checkDetailFormColumnCallback) {
      return this.checkDetailFormColumnCallback(col);
    }
    return false;
  }

  public showDetailForm(columns: DataColumn[]) {
    const stt = this.header.aicStt;
    this.buttonItems = [];
    for (let i = 0; i < columns.length; i++) {
      const row = columns[i];
      if (this.checkDetailFormColumn(row)) {
        // nix weil das macht ne unterkomponente
      } else if (
        (row.meta.subAbf || row.meta.subFom) &&
        !row.meta.invisible &&
        !row.meta.subGra
      ) {
        let showCustomEditor = false;
        let compName;
        let compTitle;
        let persFrame = false;
        let query = "";
        let compNameFrm;
        if (row.meta.subAbf) {
          query = row.meta.subAbf.kennung;
          compTitle = row.meta.subAbf.bezeichnung;
        } else if (row.meta.subFom) {
          compTitle = row.meta.subFom.bezeichnung;
          const subAic = row.meta.subFom.aic;
          const sub = this.$globalsAic(Globals.Begriff, subAic);
          if (sub.pers) {
            persFrame = sub.pers;
          }
          if (row.meta.subFom.alias) {
            compNameFrm = row.meta.subFom.alias;
          } else {
            compNameFrm = row.meta.subFom.kennung;
          }
          if (compNameFrm) {
            compName = this.findComponent(compNameFrm);
          }
          showCustomEditor = true;
        }
        const fullscreen = row.meta.fullScreen ?? false;
        const showCustomGrid = row.meta.tabellenEingabe;
        const width = 455;
        const height = 0;
        const aicEig = row.aicEig;
        const kz = row.meta.kz;
        let color = "primary";
        if (row.meta.farbe) {
          color = row.meta.farbe;
        }
        let icon = "edit";
        if (row.meta.icon) {
          icon = row.meta.icon;
        }
        const tooltip = row.title;
        let toggle = "";
        if (row.kennung) {
          toggle = row.kennung;
        }
        if (kz === "edit" && compNameFrm) {
          if (this.pers && persFrame) {
            this.subForm = compNameFrm;
          } else if (!this.pers && !persFrame) {
            this.subForm = compNameFrm;
          }
        }
        this.buttonItems.push({
          width,
          height,
          fullscreen,
          showCustomEditor,
          query,
          compName,
          compNameFrm,
          compTitle,
          icon,
          text: 0,
          kz,
          aicEig,
          color,
          toggle,
          tooltip
        });
      }
    }
  }

  private countBooleanFilterableValues(fc: FilteredDataColumn) {
    if (this.filteredItems) {
      const filteredItemCount = this.filteredItems?.filter(
        item => getValue(item.row, fc) === true
      ).length;

      return (filteredItemCount ?? "??") + "/" + this.filteredItems.length;
    }
    const setItemCount = this.items?.filter(
      item => getValue(item.row, fc) === true
    ).length;

    return (setItemCount ?? "??") + "/" + (this.items?.length ?? "??");
  }

  private setBooleanFilterValue(fc: FilteredDataColumn, value: boolean) {
    fc.filterSwitchValue = value;
    if (value) {
      const jaVal = getDisplayValue(true, fc);
      fc.filterValues.forEach(fv => (fv.checked = fv.value === jaVal));
    } else {
      fc.filterValues.forEach(fv => (fv.checked = true));
    }
    this.updateFilteredItems();
  }

  private findComponent(name: string) {
    name = name
      .substr(2)
      .replace(/_/g, "")
      .toLowerCase();
    for (const compName in this.$root.$options.components) {
      if (compName.toLowerCase().indexOf(name) >= 0) {
        return compName;
      }
    }
    return "";
  }
  private openFrame(item: ListItems) {
    const me = this;
    const aic = this.queryAIC;
    const site = this.subForm;
    const tag = item.date;
    const bereich = "Tag";
    if (tag) {
      const dayIso = new Date(tag);
      const dialogZeitbereich = {
        von: dayIso,
        bis: dayIso,
        bereich: "Tag"
      };
      if (site === "SoftTermBuchungen") {
        this.$parent.$emit("back"); // mein parent is der dialog - den mag ich zumachen :)
        this.$root.$emit("showSite", {
          title: "",
          showTitle: false,
          site: "SoftTermBuchungen",
          width: 400,
          data: { zeitbereich: dialogZeitbereich, goDate: tag },
          titleColor: "white",
          // titleClass: "white--text",
          onClose: () => {
            return true;
          }
        });
      } else {
        showDialog({
          title: "",
          showTitle: false,
          site,
          data: {
            zeitbereich: dialogZeitbereich,
            aic
          },
          width: 450,
          onClose: () => {
            return true;
          }
        });
      }
    } else {
      return;
    }
  }
  private dynamicSort(property: any) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a: any, b: any) => {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  private quittMsg(sb: any) {
    if (sb) {
      if (this.pers) {
        this.$api.user.msg = this.$api.user.msg - 1;
      }
      this.$api.quittMessage(sb)?.then((res: any) => {
        if (res) {
          const msgType = res.data.msgType;
          let info = res.data.info;
          const memo = res.data.memo;
          const title = res.data.titel;
          if (memo) {
            info = memo;
          }
          if (info) {
            if (msgType === "Error") {
              showSnackbar({
                text: info,
                color: "error"
              });
            } else if (msgType === "Info") {
              showSnackbar({
                text: info,
                color: "info"
              });
            } else if (msgType === "Warnung") {
              showSnackbar({
                text: info,
                color: "warning"
              });
            }
          }
        }
      });
    }
  }
  protected dataLoaded(data: any) {
    this.dataLoadedBase(data);
    // BaseList nach zB Datum oder Kostenstelle gruppieren
    this.groupColumns = this.columns.filter(
      (c: any) => c.meta.gruppierbar === true
    );
    if (this.groupColumns) {
      const rows = [...this.groupColumns];
      if (rows && rows.length > 0 && rows[0].meta) {
        const meta = rows[0].meta;
        if (meta.pGruppiert === true) {
          this.groupColumnChanged(this.groupColumns[0]);
        }
      }
    }
  }
  protected refreshItems() {
    try {
      this.title = this.header.bezeichnung;
      // this.count = this.data.data.header.zeilen;
      const filterColumns: FilteredDataColumn[] = (this.filterColumns = this.columns.filter(
        (c: any) =>
          c.meta.filterOK === true && !c.meta.invisible && !c.meta.banned
      ));
      this.visibleColumn = this.columns.filter(
        (c: any) => c.meta.invisible !== true && !c.meta.banned
      );
      this.items = [];
      this.showDetailForm(this.columns);
      for (let i = 0; i < this.data.length; i++) {
        const row = this.data[i];

        if (filterColumns.length > 0) {
          filterColumns.forEach((fc, ix) => {
            // for (let j = 0; j < filterColumns.length; j++) {
            // const fc = filterColumns[j];
            if (!fc.filterValues) {
              fc.filterValues = [];
            }
            const filterVal = getDisplay(row, fc) ?? this.emptyFilterText;
            const filterAic = getValue(row, fc, true);

            const setFilter = fc.meta.value;
            const isCheckbox = fc.type === "checkBox";
            if (isCheckbox && fc.filterValues.length === 0) {
              fc.filterValues.push({
                checked: setFilter?.length === 1 && setFilter[0] === true,
                value: filterVal
              });
              fc.filterSwitchValue =
                setFilter?.length === 1 && setFilter[0] === true;
              return;
            }

            if (filterVal) {
              if (!fc.filterValues.find((f: any) => f.value === filterVal)) {
                let checked = true;
                if (setFilter) {
                  if (setFilter.indexOf(filterAic) >= 0) {
                    checked = true;
                    if (isCheckbox && filterAic) {
                      fc.filterSwitchValue = isCheckbox; // setzen wenn true und checkbox
                    }
                  } else {
                    checked = false;
                  }
                }
                /* else if (isCheckbox) {
                    const jaVal = getDisplayValue(true, fc);
                    checked = filterVal === jaVal;
                }*/
                fc.filterValues.push({
                  checked,
                  value: filterVal,
                  aic: filterAic
                });
              }
              fc.filterValues.sort(this.dynamicSort("value"));
            }
          });
        }
        if (!this.Anwesend && !this.showFilter) {
          // let date = new Date();
          const date = getKZValueDisplay(row, "header", this.visibleColumn); // TODO Kennung
          const title = getKZValueDisplay(row, "titel", this.visibleColumn);
          const gueltigkeit = this.getKennungValue(row, "VALIDITY");
          const subtitel = getKZValueDisplay(
            row,
            "subtitel",
            this.visibleColumn
          );
          const text = getKZValueDisplay(row, "text", this.visibleColumn);
          const color = getKZValueDisplay(row, "color", this.visibleColumn);
          const icon = getKZValueDisplay(row, "icon", this.visibleColumn);
          const pdf = getKZValueDisplay(row, "pdf", this.visibleColumn);
          const ampel = getKZValueDisplay(row, "ampel", this.visibleColumn);
          const modell = getKZValue(
            row,
            "modell",
            this.visibleColumn,
            false,
            true
          );
          const modell1 = getKZValue(
            row,
            "modell1",
            this.visibleColumn,
            false,
            true
          );
          const edit = getKZValue(row, "edit", this.visibleColumn, false, true);
          const aic = getKZValue(row, "modellAic", this.visibleColumn);
          const checkbox = getKZValueDisplay(
            row,
            "checkbox",
            this.visibleColumn
          );
          let quittMsg = false;
          const existMsg = getKZTitle(row, "quittMsg", this.visibleColumn);
          if (existMsg) {
            this.showQuittMsg = true;
            quittMsg = false;
          }
          let select = false;
          const selectAic = getKZTitle(row, "select", this.visibleColumn);
          if (selectAic) {
            this.showSelect = true;
            select = false;
          }
          row.title = getKZValueDisplay(row, "FEHLER", this.visibleColumn);
          row.subtitle = getKZValueDisplay(row, "MEMO", this.visibleColumn);
          if (date) {
            // gibt es kein Datum zum Anzeigen, dann darf die Zeile auch nicht erzeugt werden
            this.items.push({ header: date });
          }
          this.items.push({
            title,
            subtitel,
            text,
            color,
            icon,
            modell,
            modell1,
            edit,
            aic,
            ampel,
            pdf,
            checkbox,
            row,
            group: "",
            divider: true,
            quittMsg,
            select,
            date: gueltigkeit
          });
        } else {
          let avatar;
          if (!(this.buttonItems.length > 0 && Constants.isMobile)) {
            avatar = getKZValueDisplay(row, "avatar", this.visibleColumn);
          }
          const header = getKZValueDisplay(row, "header", this.visibleColumn);
          const title = getKZValueDisplay(row, "titel", this.visibleColumn);
          const subtitel = getKZValueDisplay(
            row,
            "subtitel",
            this.visibleColumn
          );
          const text = getKZValueDisplay(row, "text", this.visibleColumn);
          const icon = getKZValueDisplay(row, "icon", this.visibleColumn);
          const memo = getKZValueDisplay(row, "MEMO", this.visibleColumn);
          const ampel = getKZValueDisplay(row, "ampel", this.visibleColumn);
          const modell = getKZValue(
            row,
            "modell",
            this.visibleColumn,
            false,
            true
          );
          const modell1 = getKZValue(
            row,
            "modell1",
            this.visibleColumn,
            false,
            true
          );
          const errorbtn = getKZValue(row, "errorbtn", this.visibleColumn);
          const warningbtn = getKZValue(row, "warningbtn", this.visibleColumn);
          const watchbtn = getKZValue(row, "watchbtn", this.visibleColumn);
          const starbtn = getKZValue(row, "starbtn", this.visibleColumn);
          const calendarbtn = getKZValue(
            row,
            "calendarbtn",
            this.visibleColumn
          );
          const reisebtn = getKZValue(row, "reisebtn", this.visibleColumn);
          const eurobtn = getKZValue(row, "eurobtn", this.visibleColumn);
          const edit = getKZValue(row, "edit", this.visibleColumn, false, true);
          const checkbox = getKZValueDisplay(
            row,
            "checkbox",
            this.visibleColumn
          );
          let aic = getKZValue(row, "modellAic", this.visibleColumn);
          if (!aic || aic === undefined) {
            if (row.aic_Bew_pool) {
              aic = row.aic_Bew_pool;
            } else {
              aic = row.aic_Stamm;
            }
          }
          const auftrag = getKZValueDisplay(row, "Auftrag", this.visibleColumn);
          let textAuftrag = "";
          if (auftrag) {
            textAuftrag = getKZTitle(row, "Auftrag", this.visibleColumn);
          }
          const taetigkeit = getKZValueDisplay(
            row,
            "Taetigkeit",
            this.visibleColumn
          );
          let quittMsg = false;
          const existMsg = getKZTitle(row, "quittMsg", this.visibleColumn);
          if (existMsg) {
            quittMsg = true;
          }
          let select = false;
          const selectAic = getKZTitle(row, "select", this.visibleColumn);
          if (selectAic) {
            this.showSelect = true;
            select = false;
          }
          let textTaetigkeit = "";
          if (taetigkeit) {
            textTaetigkeit = getKZTitle(row, "Taetigkeit", this.visibleColumn);
          }
          const handy = getKennungValueDisplay(
            row,
            "firmenHandy",
            this.columns
          );
          const handyHref = "tel:" + handy;
          const telefon = getKZValueDisplay(row, "telefon", this.visibleColumn);
          const dw = getKennungValueDisplay(
            row,
            "INTERNE_DW",
            this.visibleColumn
          );
          const telHref = "tel:" + telefon + dw;

          if (avatar) {
            if (avatar.data) {
              avatar = avatar.data;
            } else {
              this.loadImage(avatar);
              avatar = this.avatarIcon;
            }
          }
          let buttonItems: any[] = [];
          if (this.buttonItems && this.buttonItems.length > 0) {
            buttonItems = JSON.parse(JSON.stringify(this.buttonItems)); // [...this.buttonItems];
            for (let j = 0; j < buttonItems.length; j++) {
              buttonItems[j].aic = aic;
              if (buttonItems[j].kz === "errorbtn") {
                if (errorbtn) {
                  buttonItems[j].text = errorbtn;
                } else {
                  buttonItems[j].text = 0;
                }
              } else if (buttonItems[j].kz === "watchbtn") {
                if (watchbtn) {
                  buttonItems[j].text = watchbtn;
                }
              } else if (buttonItems[j].kz === "starbtn") {
                if (starbtn) {
                  buttonItems[j].text = starbtn;
                }
              } else if (buttonItems[j].kz === "calendarbtn") {
                if (calendarbtn) {
                  buttonItems[j].text = calendarbtn;
                }
              } else if (buttonItems[j].kz === "eurobtn") {
                if (eurobtn) {
                  buttonItems[j].text = eurobtn;
                }
              } else if (buttonItems[j].kz === "warningbtn") {
                if (warningbtn) {
                  buttonItems[j].text = warningbtn;
                }
              } else if (buttonItems[j].kz === "reisebtn") {
                if (reisebtn) {
                  buttonItems[j].text = reisebtn;
                }
              } else {
                buttonItems[j].text = "";
              }
            }
          }
          if (this.Anwesend) {
            this.items.push({
              header,
              title,
              subtitel,
              text,
              memo,
              avatar,
              ampel,
              icon,
              modell,
              modell1,
              edit,
              checkbox,
              aic,
              menu: false,
              mail: getKennungValueDisplay(row, "E-MAIL", this.visibleColumn),
              handy,
              telHref,
              handyHref,
              telefon,
              dw,
              auftrag,
              textAuftrag,
              taetigkeit,
              textTaetigkeit,
              row,
              group: "",
              buttonItems,
              quittMsg,
              select
            });
          } else {
            this.items.push({
              header,
              title,
              subtitel,
              text,
              memo,
              avatar,
              ampel,
              icon,
              modell,
              modell1,
              edit,
              checkbox,
              aic,
              auftrag,
              textAuftrag,
              taetigkeit,
              textTaetigkeit,
              row,
              group: "",
              buttonItems,
              quittMsg,
              select
            });
          }
        }
      }
    } finally {
      this.$emit("dataloaded", { items: this.items });
      if (this.items) {
        if (this.items.length > 0) {
          if (this.header.pagAnzahl) {
            this.pageSize = this.header.pagAnzahl;
          }
          this.filteredItems = this.items;
          // man muss aufrunden!
          this.pages = Math.max(
            1,
            Math.ceil(this.filteredItems.length / this.pageSize)
          );

          if (BaseList.lastQuery === this.query) {
            if (this.pages > BaseList.lastPage) {
              this.currentPage = BaseList.lastPage;
            } else {
              this.currentPage = BaseList.lastPage = 1;
              BaseList.lastQuery = this.query;
            }
          } else {
            this.currentPage = BaseList.lastPage = 1;
            BaseList.lastQuery = this.query;
          }
          this.updateFilteredItems();
        }
      }
    }
  }

  protected static lastPage: number = 1;
  protected static lastQuery: string | undefined;

  public updatePagedItems() {
    BaseList.lastPage = this.currentPage;
    BaseList.lastQuery = this.query;
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.pagedItems = [];
    this.pagedItems = this.filteredItems.slice(
      startIndex,
      Math.min(this.filteredItems.length, startIndex + this.pageSize)
    );
  }

  protected updateFilteredItems() {
    this.colorFilter = "white";
    if (!this.filterColumns && !this.searchText) {
      if (this.items) {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = [];
      }
      return;
    }
    const me = this;
    const oldPagePos = this.currentPage / Math.max(1, this.pages);
    this.filteredItems = [];
    if (this.items) {
      this.filteredItems = this.items.filter(item => {
        let match = true;
        if (me.groupColumn) {
          item.group = getDisplay(item.row, me.groupColumn);
          if (!item.group || item.group === "") {
            item.group = this.emptyGroupText;
          }
        }

        if (me.searchText) {
          this.colorFilter = "green";
          const search = me.searchText.toLowerCase();
          const inHeader =
            item.header && item.header.toLowerCase().indexOf(search) >= 0;
          const inTitle =
            item.title && item.title.toLowerCase().indexOf(search) >= 0;
          const inSub =
            item.subtitel != null &&
            item.subtitel.toLowerCase().indexOf(search) >= 0;
          if (!inHeader && !inTitle && !inSub) {
            return false;
          }
        }

        if (!me.filterColumns) {
          return true;
        }
        // for (let i = 0; i < me.filterColumns.length; i++) {
        me.filterColumns.forEach(fc => {
          const fv = getDisplay(item.row, fc) ?? this.emptyFilterText;

          if (fc.type === "checkBox") {
            if (fc.filterSwitchValue) {
              this.colorFilter = "green";
              // wenn nicht dann alle
              const jaVal = getDisplayValue(true, fc);
              match = match && jaVal === fv;
            }
          } else {
            const checkFilter = fc.filterValues.find(
              (ffv: any) => !ffv.checked
            );
            if (checkFilter) {
              this.colorFilter = "green";
            }
            match =
              match &&
              fc.filterValues.find(
                (ffv: any) => ffv.value === fv && ffv.checked
              ) != null;
          }
        });
        return match;
      });
    }
    if (this.groupColumn) {
      this.filteredItems = this.filteredItems.sort((a, b) => {
        if (a.group > b.group) {
          return 1;
        } else if (b.group > a.group) {
          return -1;
        }
        return 0;
      });
    }
    this.pages = Math.max(
      1,
      Math.ceil(this.filteredItems.length / this.pageSize)
    );
    this.currentPage = Math.max(Math.floor(this.pages * oldPagePos), 1);

    this.updateGroupedItems();
  }

  protected filterChanged() {
    this.updateFilteredItems();
  }

  private loadImage(avatar: any) {
    if (avatar.data) {
      // this.avatarIcon = avatar.data;
      // const start = this.avatarIcon.indexOf('"') + 1;
      // const len = this.avatarIcon.lastIndexOf('"') - start;
      // this.avatarIcon = this.avatarIcon.substr(start, len);
    } else {
      this.avatarIcon = this.$api.fullUrl(
        "imageM/" + this.$api.user.id + ":" + +avatar.aic + "_" + avatar.name
      );
    }
  }
  private onShowPdf(pdfFile: any) {
    const url = this.$api.fullUrl(
      "pdf2/" + this.$api.user.id + ":" + pdfFile.aic + "_" + pdfFile.name,
      "",
      true
    );
    const varUbergabe: any[] = [];
    const tabUbergabe: any[] = [];
    const res = window.open(url);
    const begriff = this.$globalsKennung(
      Globals.Begriff,
      "Web_Dokumente_gedrucktsetzen"
    );
    const debug: number = 0;
    const abbruch: number = 0;
    const bemerkung: string = "WEB - baseList";
    this.$api.postCalc(
      varUbergabe,
      tabUbergabe,
      begriff.aic,
      this.data[0].aic_Bew_pool,
      this.zeitbereich,
      abbruch,
      debug,
      bemerkung,
      "",
      begriff.maxB
    );
    this.reloadData();
  }

  private checkChanged(fv: any) {
    fv.checked = !fv.checked;
    this.filterChanged();
  }
  private groupColumnChanged(gv?: DataColumn) {
    if (gv === this.groupColumn) {
      gv = undefined;
    }

    this.groupColumn = gv ?? null;
    this.updateFilteredItems();
  }
  private updateGroupedItems() {
    if (this.groupColumn != null) {
      this.subHeader = true;

      if (this.filteredItems.length > 0) {
        this.groupedItems = this.filteredItems.slice();
      } else {
        if (this.items && this.items.length > 0) {
          this.groupedItems = this.items.slice();
        }
      }

      this.filteredItems = this.groupedItems;
    } else {
      this.subHeader = false;
      this.groupedItems = this.filteredItems;
    }
    this.updatePagedItems();
  }
  private setUserPara() {
    const varUbergabe: any[] = [];
    let value: any[] = [];
    const cols = this.filterColumns;
    if (cols && cols.length > 0) {
      for (let i = 0; i < cols.length; i++) {
        if (cols[i].type === "checkBox") {
          varUbergabe.push({
            aic: cols[i].aic,
            value: [cols[i].filterSwitchValue ?? false]
          });
          continue;
        }
        const filterItems = cols[i].filterValues;
        value = [];
        const valueList = filterItems.filter((c: any) => c.checked === true);
        for (let j = 0; j < valueList.length; j++) {
          if (valueList[j].aic || valueList[j].aic === 0) {
            value.push(valueList[j].aic);
          }
        }
        varUbergabe.push({
          aic: cols[i].aic,
          value
        });
      }
    }
    if (this.groupColumns) {
      if (this.groupColumn) {
        varUbergabe.push({
          aic: this.groupColumn.aic,
          pGruppiert: true
        });
      } else if (this.groupColumns && this.groupColumns.length > 0) {
        varUbergabe.push({
          aic: this.groupColumns[0].aic,
          pGruppiert: false
        });
      }
    }
    this.colorFilter = "green";
    this.$api.setPersParameter(varUbergabe);
  }
  private deleteUserPara() {
    this.colorFilter = "white";
    const aicBegriff = this.header.aicBegriff;
    this.$api.deletePersParameter(aicBegriff);
    this.groupColumns = [];
    this.groupColumnChanged();
    this.reloadData(); // es soll alles auf Original zurück gesetzt werden
  }
  private resetFilter() {
    if (this.filterColumns && this.filterColumns.length > 0) {
      this.filterColumns.forEach((fc: any, ix) => {
        if (fc.type === "checkBox") {
          fc.filterSwitchValue = false;
        }
        if (fc.filterValues.length > 0) {
          for (let j = 0; j < fc.filterValues.length; j++) {
            fc.filterValues[j].checked = true;
          }
        }
      });
    }
    this.colorFilter = "white";
    this.searchText = "";
    this.refreshItems();
    this.groupColumns = [];
    this.groupColumnChanged();
    this.updateFilteredItems();
    this.$emit("reloadData");
  }
  private onCalcFunction(modell: string, aic: number) {
    const me = this;
    if (modell) {
      calcFunction(modell, aic, false, me, this.editZeitbereich, "")?.then(
        (response: any) => {
          me.reloadData();
          return;
        }
      );
    } else {
      me.reloadData();
      return;
    }
  }
  private selectChange(val: any) {
    const select = val.select;
    if (select) {
      this.vecStamm.push(val.aic);
    } else {
      this.vecStamm = this.vecStamm.filter((c: any) => c.aic === val.aic);
    }
    this.$emit("vector", this.vecStamm);
  }
  private selectChangeAll(data: any) {
    if (data) {
      if (this.filteredItems) {
        this.vecStamm = [];
        for (let i = 0; i < this.filteredItems.length; i++) {
          const row = this.filteredItems[i];
          this.vecStamm.push(row.aic);
          this.filteredItems[i].select = true;
        }
      }
    } else {
      this.vecStamm = [];
      if (this.filteredItems) {
        for (let i = 0; i < this.filteredItems.length; i++) {
          const row = this.filteredItems[i];
          this.filteredItems[i].select = false;
        }
      }
    }
    this.$emit("vector", this.vecStamm);
  }
}
declare interface ButtonItems {
  width?: number;
  height?: number;
  fullscreen: boolean;
  showCustomEditor: boolean;
  query: string;
  compName?: string;
  compNameFrm?: string;
  compTitle?: string;
  icon: string;
  text: number;
  kz?: string;
  aicEig?: number;
  color: string;
  toggle: string;
  tooltip: string;
}
