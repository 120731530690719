var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"transition":"slide-y-transition","bottom":"","offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on2;
var attrs2 = ref.attrs2;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.loadToken()}}},[_c('v-icon',[_vm._v("mdi-history")]),_c('span',_vm._g(_vm._b({staticClass:"red--text"},'span',attrs2,false),on2),[_vm._v("Token: "+_vm._s(_vm.showToken))])],1)]}}])},[_c('span',[_vm._v("Token manuell neu laden")])])],1),(_vm.checkBegriff('SoftTermSalden'))?_c('v-list-item',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openSettings('SoftTermSalden', 'Salden')}}},[_c('v-icon',[_vm._v("trending_up")]),_c('span',[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "SoftTermSalden", "Salden"))+" ")])],1)],1):_vm._e(),(_vm.checkBegriff('PW') && (!_vm.isAzureLoginAccepted))?_c('v-list-item',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openSettings('BasePassword', 'Passwort')}}},[_c('v-icon',[_vm._v("lock")]),_c('span',[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "PW", "Passwort"))+" ")])],1)],1):_vm._e(),(_vm.printItems.length > 0)?_c('v-list-item',{staticClass:"pt-0"},[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"20px, padding: 0px"},attrs:{"text":""}},on),[_c('v-icon',[_vm._v("print")]),_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "Web_pers_Drucke", "pers. Drucke" ))+" ")],1)]}}],null,false,539869620)},[_c('v-list',{staticClass:"shortList",attrs:{"nav":"","tile":"","outlined":"","elevation":"12"}},_vm._l((_vm.printItems),function(printItem,ux){return _c('v-list-item',{key:'ui' + ux,staticClass:"pl-2",on:{"click":function($event){return _vm.openDruckVorschau(printItem)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(" "+_vm._s(printItem.bezeichnung)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(printItem.tooltip))])])],1)}),1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openSettings('BasePersEinstellungen', 'Pers. Einstellungen')}}},[_c('v-icon',[_vm._v("account_box")]),_c('span',[_vm._v(" "+_vm._s(_vm.$globalsBezeichnung( _vm.Globals.Begriff, "PersEinstellungen", "Pers. Einstellungen" ))+" ")])],1)],1),(_vm.checkBegriff('Lizenz_QR_Code_Web'))?_c('v-list-item',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openSettings('AppConnect', 'App verbinden')}}},[_c('v-icon',[_vm._v("qr_code_scanner")]),_c('span',[_vm._v(_vm._s(_vm.$globalsBezeichnung(_vm.Globals.Begriff, "Web_QRCode", "QR - Code"))+" ")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"pt-0"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openSettings('BaseSystemInfo', 'Info')}}},[_c('v-icon',[_vm._v("info")]),_c('span',[_vm._v("Info ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }