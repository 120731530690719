










































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import EditComponent from "@/editor/EditComponent";
import {
  getKennungValueDisplay,
  getKZValue,
  getKennungValue
} from "@/DataHelper";
// import { showSnackbar } from "@/UIHelper";

@Component
export default class EditReisen extends EditPage {
  private ort: string = "";
  private selectFahrzeug: boolean = false;
  private showAbfahrt: boolean = false;
  private showZeit: boolean = false;
  private fahrtkosten: boolean = false;
  private editTransport: boolean = false;
  constructor() {
    super();
    this.query = "Web_RK_EditReisezeiten";
  }
  public mounted() {
    if (this.siteData?.showZeit) {
      this.showZeit = this.siteData?.showZeit;
    }
    this.mountedBase();
  }
  protected initialize() {
    this.selectFahrzeug = getKennungValueDisplay(
      this.data,
      "RK_TRANSPORTMITTEL",
      this.columns
    );
    this.fahrtkosten = getKennungValue(this.data, "Fahrtkosten", this.columns);
    this.ort = getKennungValueDisplay(this.data, "CITY", this.columns);
    this.showAbfahrt = getKZValue(this.data, "showAbfahrt", this.columns);
    // bei Abfahrt darf das Transportmittel IMMER verwändert werden
    this.editTransport = getKZValue(
      this.data,
      "showTransportmittel",
      this.columns
    );
    if (this.showAbfahrt) {
      this.fahrtkosten = true;
      this.editTransport = true;
    }
  }
  private checkValue(evt: any) {
    this.fahrtkosten = evt.value.setShow;
  }
}
