

















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component({
  provide: {}
})
export default class StammOrt extends Vue {
  public query: string | undefined = "Web_Definitionen_Ort"; // per default nicht initialisiert ;)
}
