

























































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  DashboardRow,
  ItemContainer,
  ItemGroup
} from "./ChartTypesAndFunctions";

declare interface AnyDashboardItem {
  id: number;
  groups?: AnyDashboardItem[];
  items?: AnyDashboardItem[];
}
import draggable from "vuedraggable";
Vue.component("draggable", draggable);

let idCounter = 1;
@Component
export default class Dashboard extends Vue {
  @Prop() public value!: DashboardRow[];
  @Prop() public onAddElement?: (x: ItemContainer) => Promise<void>;
  @Prop({ default: false }) public readonly!: boolean;

  public mounted() {
    if (this.value) {
      this.rows = this.value;
    }
  }

  protected editMode: boolean = false;
  private rows: DashboardRow[] = [
    {
      groups: [],
      id: idCounter++
    }
  ];

  public toggleEditMode() {
    if (this.editMode) {
      this.$emit("save", this.rows);
      this.editMode = false;
    } else {
      this.$emit("editMode", this.rows);
      this.editMode = true;
    }
  }

  @Watch("value")
  private refreshID() {
    idCounter = 0;

    const checkId = (v: AnyDashboardItem) => {
      if (idCounter < v.id) {
        idCounter = v.id;
      }
      if (v.groups && v.groups.length > 0) {
        v.groups.forEach(g => checkId(g));
      }
      if (v.items && v.items.length > 0) {
        v.items.forEach(g => checkId(g));
      }
    };
    idCounter++;

    this.value.forEach(v => checkId(v));
  }

  private insertRow(ix: number) {
    console.log("insert row " + ix);
    const row = { groups: [], id: idCounter++ };
    this.addGroup(row);

    this.rows.splice(ix, 0, row);
  }

  private addRow() {
    const row = { groups: [], id: idCounter++ };
    this.addGroup(row);
    this.rows.push(row);
  }

  private addGroup(row: DashboardRow) {
    row.groups.push({ items: [], id: idCounter++ });
  }
  private addItem(group: ItemGroup) {
    const newElement: ItemContainer = {
      active: true,
      edit: false,
      id: idCounter++,
      hideTitle: false
    };
    if (this.onAddElement) {
      this.onAddElement(newElement).then(t => {
        group.items.push(newElement);
      });
    } else {
      group.items.push(newElement);
    }
  }

  private removeRow(row: DashboardRow) {
    let c = 0;
    row.groups.forEach(g => (c += g.items?.length ?? 0));
    if (
      confirm(
        "Soll wirklich die ganze Zeile mit den " +
          row.groups.length +
          " Spalten und " +
          c +
          " Elementen gelöscht werden?"
      )
    ) {
      this.rows = this.rows.filter(i => i !== row);
    }
  }
  private removeGroup(row: DashboardRow, grp: ItemGroup) {
    if (
      confirm(
        "Soll wirklich die ganze Gruppe mit den " +
          grp.items.length +
          " Elementen gelöscht werden?"
      )
    ) {
      row.groups = row.groups.filter(i => i !== grp);
    }
  }
  private removeItem(grp: ItemGroup, item: ItemContainer) {
    if (confirm("Das Element gelöscht wirklich werden?")) {
      grp.items = grp.items.filter(i => i !== item);
    }
  }

  private getGroupCols() {
    // wenn klein dann umbrechen und alles groß machen...
    if (
      this.$vuetify.breakpoint.name === "sm" ||
      this.$vuetify.breakpoint.name === "xs"
    ) {
      return 12;
    }
  }
}
