















































import { Vue, Component, Prop, Provide } from "vue-property-decorator";

import EditComponent from "@/editor/EditComponent";

@Component
export default class EditBild extends EditComponent {
  constructor() {
    super();
  }
  private imageName: string = "";
  private imageFile: File | null = null;
  private loading: boolean = false;
  private base64data: string = "";
  private field: any = this.queryColumn;

  public mounted() {
    const x: {
      name: string | null;
      data: string | null;
      type: string | null;
    } = this.getInitValue();
    if (x?.data) {
      // bei pdf nicht laden!
      if (x.type !== "application/pdf") {
        this.base64data = x.data;
      }
    }
    if (x?.name) {
      this.imageName = x.name;
      if (!this.base64data) {
        this.loadImage(x);
      }
    }
  }
  private loadImage(image: any) {
    if (image.datentyp === "Doku") {
      this.base64data = this.$api.fullUrl(
        "pdf2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
    } else {
      this.base64data = this.$api.fullUrl(
        "image2/" + this.$api.user.id + ":" + +image.aic + "_" + image.name
      );
    }
  }
  private fileChanged(file: File) {
    if (file !== undefined) {
      this.imageName = file.name;
      if (this.imageName.lastIndexOf(".") <= 0) {
        return;
      }
      const me = this;
      this.createBase64Image(file)
        .then(base64 => {
          this.base64data = base64;
          me.valueChanged({ data: base64, name: file.name });
        })
        .catch(error => {
          me.loading = false;
          console.log("error reading File:" + error);
          alert("Kann File leider nicht lesen!");
          me.imageFile = null;
        });
    }
  }
  private createBase64Image(fileObject: any) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        this.loading = true;
        if (e.target) {
          resolve(e.target.result as string);
          this.loading = false;
        }
      };
      reader.readAsDataURL(fileObject);
    });
  }
  public validate() {
    if (this.required) {
      if (this.imageName) {
        this.errorText = "";
        return true;
      }
      this.errorText = this.requiredMessage;
      return false;
    }

    return true;
  }
}
