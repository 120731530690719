var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dvh",attrs:{"loading":_vm.showLoading}},[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{style:('overflow-y:scroll'),attrs:{"height":"64px"}},[_c('v-toolbar',{attrs:{"color":"dvhBackground","flat":""}},[_c('v-btn',{staticClass:"mr-2 pa-0",attrs:{"small":"","text":"","color":"white darken-2"},on:{"click":_vm.setToday}},[_vm._v(" heute ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"white darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"white darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),_c('v-toolbar-title',{staticClass:"white--text",staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"flex-grow-1"}),(_vm.printItems && _vm.printItems.length > 0)?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"width":"20px, padding: 0px"},attrs:{"dark":"","text":""}},on),[_c('v-icon',[_vm._v("print")])],1)]}}],null,false,668094353)},[_c('v-list',{staticClass:"shortList",attrs:{"nav":"","tile":"","outlined":"","elevation":"12"}},_vm._l((_vm.printItems),function(printItem,ux){return _c('v-list-item',{key:'ui' + ux,staticClass:"pl-2",on:{"click":function($event){return _vm.openDruckVorschau(printItem)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-title',_vm._g({},on),[_vm._v(" "+_vm._s(printItem.bezeichnung)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(printItem.tooltip))])])],1)}),1)],1):_vm._e(),(!_vm.showLoading && _vm.initialized)?_c('CommonBaseFilter',{ref:_vm.BaseFilter,staticClass:"inlineBlock",attrs:{"queryData":_vm.queryData},on:{"filteredDataChanged":_vm.filteredDataChanged}}):_vm._e(),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","text":"","color":"white darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day';
                  _vm.refreshCalender();}}},[_c('v-list-item-title',[_vm._v("Tag")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week';
                  _vm.refreshCalender();}}},[_c('v-list-item-title',[_vm._v("Woche")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month';
                  _vm.refreshCalender();}}},[_c('v-list-item-title',[_vm._v("Monat")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day';
                  _vm.refreshCalender();}}},[_c('v-list-item-title',[_vm._v("4 Tage")])],1)],1)],1)],1)],1),(_vm.initialized)?_c('v-sheet',{staticClass:"dvh",attrs:{"height":"600"}},[(_vm.showSchedule)?_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"event-margin-bottom":3,"event-ripple":false,"event-overlap-mode":_vm.mode,"event-overlap-threshold":60,"event-more":true,"event-height":_vm.eventHeight,"event-text-color":"white","first-time":"6am","type":_vm.type,"now":_vm.today,"start":_vm.calenderStartDate,"locale":_vm.$api.user.locale,"show-week":_vm.showWeek,"short-months":_vm.showFullWidth,"short-weekdays":_vm.showFullWidth,"short-intervals":true,"show-month-on-first":true,"locale-first-day-of-year":"4","weekdays":_vm.weekday},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange,"mousedown:event":_vm.startDrag,"mousemove:time":_vm.mouseMove,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"event",fn:function(ref){
                  var event = ref.event;
return [_c('span',{staticClass:"vertical",domProps:{"innerHTML":_vm._s(event.name)}}),_c('div',[_vm._v(_vm._s(event.moreInformation))])]}}],null,false,14842605)}):_vm._e(),(_vm.editorComponent && _vm.selectedOpen)?_c('v-menu',{attrs:{"absolute":true,"fullscreen":_vm.showFullWidth,"close-on-content-click":false},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-container',{staticClass:"dvhDialog",attrs:{"grid-list-md":"","text-center":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.tagesAnzeige)}})],1),_c(_vm.editorComponent,{tag:"component",attrs:{"siteData":{
                tag: _vm.selectedEvent.tag,
                readOnlyMutable: _vm.readOnlyMutable,
                aic: _vm.selectedEvent.id,
                bewegungsdaten: true
              },"backOnSave":false,"aic":_vm.selectedEvent.aic,"druckItems":_vm.druckItems},on:{"back":_vm.dialogBack,"delete":function($event){return _vm.onDelete(_vm.selectedEvent.id)}}})],1)],1):_vm._e(),(!_vm.editorComponent)?_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{staticClass:"pa-0",attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.tagesAnzeige)}})],1),_c('v-card-text',[_c('div',[_vm._v(_vm._s(_vm.selectedEvent.name))]),_c('div',[_vm._v(_vm._s(_vm.selectedEvent.name1))]),_c('div',[_vm._v(_vm._s(_vm.selectedEvent.von1))]),_c('v-text-field',{staticClass:"pt-10",attrs:{"type":"text","disabled":true,"label":"Memo"},model:{value:(_vm.selectedEvent.moreInformation),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "moreInformation", $$v)},expression:"selectedEvent.moreInformation"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }