import { render, staticRenderFns } from "./DialogDetailantrag.vue?vue&type=template&id=39e79b59&scoped=true&"
import script from "./DialogDetailantrag.vue?vue&type=script&lang=ts&"
export * from "./DialogDetailantrag.vue?vue&type=script&lang=ts&"
import style0 from "./DialogDetailantrag.vue?vue&type=style&index=0&id=39e79b59&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e79b59",
  null
  
)

export default component.exports