














































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungValueDisplay } from "@/DataHelper";

@Component
export default class ReiseDaten extends EditPage {
  constructor() {
    super();
    this.query = "Web_ReiseDatenVG_Details";
  }
  @Prop({ default: false }) public showMemo: boolean = false;
  protected zeitbereichUebergabe!: APIZeitbereich | null;
  public created() {
    this.zeitbereich = this.zeitbereichUebergabe;
  }
  public mounted() {
    this.mountedBase();
  }
}
