import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";
import { getKennungTitle, getKennungValueDisplay } from "@/DataHelper";
import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "@/UIHelper";

@Component
export default class EditPage extends Vue {
  protected query!: string | undefined;
  @Prop({ default: null }) protected lastValues: any;
  @Prop() protected aic!: number;
  @Prop() protected mainAic!: number;
  @Prop() protected mainStt!: number;
  protected zeitbereich!: APIZeitbereich | null;
  public bewegungsdaten!: boolean;
  // ohne @Prop sonst kommt ein Error - Zeitbereich kann auch aus SiteData kommmen
  @Prop({ default: true }) protected backOnSave!: boolean;
  @Prop({ default: null }) public siteData: any;
  @Prop({ default: false }) public insertMode!: boolean;
  public insertHint: boolean = false;
  public vecStamm: any[] = [];
  protected useVec!: boolean;
  public data: any = {};
  protected header: any = { bezeichnung: "lade..." };
  protected headerStamm: any = "";
  public queryAIC: any = undefined;
  public columns: any[] = [];
  protected showLoading: boolean = false;
  protected initialized: boolean = false;
  protected backupData: any;
  private validations: any[] = [];
  protected msgType: string = "";
  protected info: string = "";
  protected calcMessage: string = "";

  public mounted() {
    this.mountedBase();
  }
  public getKennungTitle(kennung: string, column: any) {
    const x = getKennungTitle(kennung, column);
    return x;
  }
  public getKennungValueDisplay(data: any, kennung: string, column: any) {
    const x = getKennungValueDisplay(data, kennung, column);
    return x;
  }
  protected checkBegriff(kennung: string) {
    const x = this.$globalsKennung(Globals.Begriff, kennung, false);
    return x;
  }
  protected checkMobile(kennung: string) {
    const x = this.$globalsBegriffMobile(Globals.Begriff, kennung, false);
    return x;
  }

  protected mountedBase() {
    if (this.query === "") { // ist die Abfrage leer - dann brauch ich nichts laden!
      return;
    }
    if (this.aic) {
      this.queryAIC = this.aic;
    }
    this.insertHint = this.insertMode;
    if (this.siteData) {
      if (this.siteData.query) {
        this.query = this.siteData.query;
      }
      if (this.siteData.aic) {
        this.queryAIC = this.siteData.aic;
      }
      if (this.siteData.zeitbereich) {
        this.zeitbereich = this.siteData.zeitbereich;
      }
      if (this.siteData.tag) {
        this.zeitbereich = {
          von: this.siteData.tag,
          bis: this.siteData.tag,
          bereich: "Tag"
        };
      }
      if (this.siteData.insertHint) {
        this.insertHint = this.siteData.insertHint;
      }
      if (this.siteData.bewegungsdaten) {
        this.bewegungsdaten = this.siteData.bewegungsdaten;
      }
    }
    this.reloadData();
  }

  protected reloadData() {
    if (this.query != null) {
      this.showLoading = true;
      const me = this;
      let vecStamm: any[] = [];
      if (this.useVec) {
        vecStamm = this.vecStamm;
      }
      this.$api.getQuery(
        // Query
        this.query,
        // Done function
        (data: any) => me.dataLoaded(data),
        this.queryAIC,
        this.zeitbereich,
        0,
        false,
        this.bewegungsdaten,
        "",
        "",
        vecStamm
      );
    } else {
      this.showLoading = false;
      this.initialized = true;
      this.initialize();
    }
  }

  protected dataLoaded(data: any) {
    try {
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        console.log(data.data.error);
        alert("ACHTUNG: " + data.data.error);
        this.$emit("back");
        return;
      }

      // diese Schleife initialisiert die filter Property auf da Spalte die es vom Server her nicht gibt ;)
      for (let i = 0; i < data.data.columns.length; i++) {
        data.data.columns[i].filter = "";
      }
      this.header = data.data.header;
      this.headerStamm = data.data.header.aicStt;
      this.columns = data.data.columns;
      if (data.data.data && data.data.data.length > 0) {
        this.data = data.data.data[0];
      } else {
        this.data = {};
      }

      if (this.data) {
        // wenn keine Daten da sind kann ich auch nix aufbereiten
        this.backupData = JSON.parse(JSON.stringify(this.data));
      }
      // SyncStamm setzen..nur wenn es sich um eine Stamm Maske handelt
      if (this.data && this.headerStamm) {
        if (this.$api.user.stt !== this.headerStamm && this.data.aic_Stamm) {
          this.$api.setSyncStamm(this.headerStamm, this.data.aic_Stamm);
        }
      }
      // insert Hint wird nur gesetzt, damit er trotz keiner veränderter Daten glaubt
      // es hat sich was getan (brauch ma aber nicht speichern)
      if (this.insertHint) {
        this.backupData = null;
      }
    } finally {
      this.showLoading = false;
      this.initialized = true;
      this.initialize();
    }
  }

  protected initialize() {
    // todo some stuff
  }
  public registerEditComponent(comp: Vue, column: any) {
    const me = this;
    comp.$on("input", (e: any) => {
      me.data[column.name] = e;
    });
    comp.$on("addValidation", this.addValidation);
    if (this.data) {
      const val = this.data[column.name];
      if (val || val === 0) {
        const ecomp = comp as EditComponent;
      }
    }
    if (this.lastValues) {
      const lastVal = this.lastValues[column.name];
      if (lastVal || lastVal === 0) {
        const ecomp = comp as EditComponent;
        ecomp.lastValue = lastVal;
      }
    }
  }
  protected setValue(evt: any) {
    if (this.data.length === 0) {
      this.data.push({});
    }
    this.data[0][evt.field.name] = evt.value;
  }
  private addValidation(v: any) {
    this.validations.push(v);
  }
  protected onValidate() {
    let valid: boolean = true;
    for (let i = 0; i < this.validations.length; i++) {
      const val = this.validations[i];
      const res = val.isValid() as boolean;
      valid = valid && res;
      console.log("field " + val.field.column.name + " -> " + res);
    }
    return valid;
  }

  protected backOrReload() {
    if (this.backOnSave) {
      this.$emit("back", true);
    } else {
      this.reloadData();
    }
  }

  protected onDelete(aic: any, modell: string, zeitbereich?: any) {
    const bewPool = aic;
    const result = this.$api
      .DeleteData(this.header, bewPool, 0, zeitbereich)
      ?.then((res: any) => {
        if (res) {
          const msgType = res.data.msgType;
          let info = res.data.info;
          const memo = res.data.memo;
          const title = res.data.titel;
          if (memo) {
            info = memo;
          }
          if (info) {
            if (msgType === "Error") {
              showSnackbar({
                text: info,
                color: "error"
              });
            } else if (msgType === "Info") {
              showSnackbar({
                text: info,
                color: "info"
              });
            } else if (msgType === "Warnung") {
              showSnackbar({
                text: info,
                color: "warning"
              });
            }
            this.showLoading = false;
            return msgType;
          }
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Datensatz_geloescht",
              "Daten wurden gelöscht"
            )
          ); // Geloescht
        }
        if (modell) {
          this.calcFunction(modell, this.$api.user.aic, false)
            .then(() => this.backOrReload())
            .catch((err: any) => alert(JSON.stringify(err)));
        } else {
          this.backOrReload();
        }
      })
      .catch((ex: any) => {
        if (ex && ex.response) {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error"
          });
        }
        console.log(ex);
        if (ex !== this.$api.ApiBusyMessage) {
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Berechnung_lauft",
              "Berechnung läuft, bitte warten Sie diese ab."
            )
          );
        }
      });
  }
  // speichern und dann gleich ein Modell auslösen!
  protected onSave(
    aicAbfAll: number,
    modell: string,
    zeitbereich?: any,
    aic?: any,
    getData?: boolean
  ) {
    let AbfAll = 0;
    if (typeof aicAbfAll === "number") {
      AbfAll = aicAbfAll; // Abfrage für Planung speichern
    }
    const isvalid = this.onValidate();
    // text - Validierug fehlgeschlagen...
    if (!isvalid) {
      showSnackbar({
        text: "Validierung fehlgeschlagen!",
        duration: 4000,
        color: "error"
      });
      return;
    }
    if (zeitbereich) {
      this.zeitbereich = zeitbereich;
    }
    const setDataTrue = getData;
    this.showLoading = true;
    let aicUebergabe: number = aic;
    if (!aicUebergabe) {
      aicUebergabe = this.aic;
    }
    let saveHaupt = false;
    if (this.mainAic && this.header.aicBew) {
      getData = true;
      aicUebergabe = this.mainAic;
      saveHaupt = true;
      if (!this.mainStt) {
        // this.mainStt = this.header.aicStt;
      }
    }
    const me = this;
    const result = this.$api
      .SaveData(
        this.header,
        AbfAll,
        this.data.aic_Bew_pool,
        this.data,
        this.columns,
        this.zeitbereich,
        this.backupData,
        (res: any) => me.saved(res, modell),
        (ex: any) => {
          let errorMessage = "";
          this.showLoading = false;
          if (ex.response) {
            errorMessage = ex.response.data.error;
            if (!errorMessage && ex.response?.status && ex.response.status === 400) {
              errorMessage = ex.response.data.info;
            }
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error"
          });
          console.error("ERROR: " + ex);
        },
        aicUebergabe,
        getData,
        saveHaupt,
        this.mainStt,
      )
      .then(() => {
        // nach dem Speichern soll gleich ein Modell aufgerufen werden!
        if (modell) {
          this.calcFunction(modell, this.$api.user.aic, false).then(() => {
            // danach ggf zumachen...
            if (this.backOnSave) {
              this.$emit("back", this.updateDataForAsync);
            }
          });
        }
      });
    return result;
  }
  private updateDataForAsync: any = null;

  protected saved(res: any, modell: any) {
    if (res.data) {
      const msgType = res.data.msgType;
      let info = res.data.info;
      const memo = res.data.memo;
      const title = res.data.titel;
      if (memo) {
        info = memo;
      }
      if (info) {
        if (msgType === "Error") {
          showSnackbar({
            text: info,
            duration: 6000,
            color: "error"
          });
        } else if (msgType === "Info") {
          showSnackbar({
            text: info,
            duration: 6000,
            color: "info"
          });
        } else if (msgType === "Warnung") {
          showSnackbar({
            text: info,
            duration: 6000,
            color: "warning"
          });

        }
        this.showLoading = false;
        return msgType;
      }
    }
    this.showLoading = false;
    if (!res.nothingHappend) {
      // wenn nix passiert is aber weitermachen ;)
      showSnackbar({
        text: this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_gespeichert",
          "Daten wurden gespeichert"
        ),
        duration: 100,
      }
      );
    } else {
      showSnackbar(
        this.$globalsBegriffMemo(
          Globals.Begriff,
          "Datensatz_keine_Aenderung",
          "Daten wurden nicht verändert, kein Speichern durchgeführt!"
        )
      );
    }
    let updateData = null;
    if (res && res.data) {
      updateData = res.data;
    }
    // backOnSave nur wenn nicht modellberechnung danach gefordert.
    if (this.backOnSave && !modell) {
      this.$emit("back", updateData);
    } else {
      this.updateDataForAsync = updateData;
    }
    if (updateData && updateData.data && updateData.data.length > 0) {
      const update = updateData.data[0];
      this.data.aic_Bew_pool = update.aic_Bew_pool;
      this.initialized = false;
      const me = this;
      this.columns.forEach(col => {
        if (update[col.name] !== undefined) {
          me.data[col.name] = update[col.name];
          console.log("updated " + col.name + " -> " + update[col.name]);
        }
      });

      this.$nextTick(() => (this.initialized = true));
    } else if (updateData.aic_Bew_pool) {
      // wird nur der aic_Bew_pool zurück geliefert, dann auch diesen ersetzen!
      this.data.aic_Bew_pool = updateData.aic_Bew_pool;
    }
  }

  protected onCancel() {
    this.$emit("back");
  }
  private calcFunction(
    modell: string,
    aic: number,
    fromDialog: boolean = false
  ) {
    let begriff: any;
    if (modell) {
      begriff = this.$globalsKennung(Globals.Begriff, modell);
    }
    const me = this;
    if (begriff.userFrage && fromDialog !== true) {
      const userFrage = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        begriff.userFrage,
        "Soll die Berechnung durchgeführt werden?"
      );
      showDialog({
        title: "Frage",
        titleColor: "white",
        titleClass: "black--text",
        width: 300,
        height: 400,
        text: userFrage,
        persistent: true,
        okButton: true,
        onClose: (x: any) => {
          if (x === true) {
            me.calcFunction(modell, aic, true);
          }
          return true;
        }
      });
      return Promise.resolve();
    }
    this.showLoading = true;
    const varUbergabe: any[] = [];
    const tabUbergabe: any[] = [];
    const bemerkung: string = "Web - EditPage";
    return this.$api.postCalc(
      varUbergabe,
      tabUbergabe,
      begriff.aic,
      aic,
      this.zeitbereich,
      0,
      0,
      bemerkung,
      this.success,
      begriff.maxB
    );
  }
  private success(res: any) {
    const vorfehler = res.data.vorfehler;
    if (vorfehler && vorfehler.length > 0 && vorfehler[0].error) {
      console.error("Thread - Modell Fehler: " + res.data.header.vorfehler[0].error);
      showSnackbar({
        text: vorfehler.error,
        color: "error"
      });
    }
    this.calcMessage = res.data.error;
    this.msgType = res.data.msgType;
    this.header = res.data.header;
    this.info = res.data.info;
    const memo = res.data.memo;
    const title = res.data.titel;
    if (memo) {
      this.info = memo;
    }
    this.showLoading = false;

    if (this.msgType === "Error") {
      showSnackbar({
        text: this.info,

        color: "error"
      });
    } else if (this.msgType === "Info") {
      showSnackbar({
        text: this.info,
        color: "info"
      });
    } else if (this.msgType === "Warnung") {
      showSnackbar({
        text: this.info,
        color: "warning"
      });
    }
    this.showLoading = false;
  }
}
