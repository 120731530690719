




































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class Stempelungen extends EditPage {
  constructor() {
    super();
    this.showLoading = true;
  }
  protected myHeight: number = 900;
  protected day: Date = this.siteData.zeitbereich.von;
  private titel: string = this.siteData.title;

  public onRefresh() {
    (this.$refs.timelines as any).onRefresh();
  }
  public mounted() {
    this.mountedBase();
    this.myHeight = window.innerHeight - 100;
  }
}
