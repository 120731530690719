import { render, staticRenderFns } from "./BaseVGrid.vue?vue&type=template&id=13d995ca&scoped=true&"
import script from "./BaseVGrid.vue?vue&type=script&lang=ts&"
export * from "./BaseVGrid.vue?vue&type=script&lang=ts&"
import style0 from "./BaseVGrid.vue?vue&type=style&index=0&id=13d995ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d995ca",
  null
  
)

export default component.exports