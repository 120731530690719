
























import CreateList from "../CreateList";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class MaterialChartCard extends Vue {
  @Prop({ default: false }) protected showTitle!: boolean;
  private title: string = "";
}
