









import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import {
  SidebarPlugin,
  TabPlugin,
} from "@syncfusion/ej2-vue-navigations";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(SidebarPlugin, ButtonPlugin, TabPlugin);

@Component({
  provide: {}
})
export default class WebHSStammdatenMA extends Vue {
  @Prop() public query!: string | undefined; // per default nicht initialisiert ;)
}
