
























































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { getUniqueID, createElement } from "@syncfusion/ej2-base";

import {
  GridPlugin,
  Filter,
  Page,
  Edit,
  Toolbar,
  ColumnChooser,
  PdfExport,
  Resize,
  Sort,
  ColumnMenu,
  Group,
  Reorder,
  Aggregate,
  Grid,
} from "@syncfusion/ej2-vue-grids";
Grid.Inject(Aggregate);
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { getDisplay, getDisplayValue, getGroupSumValue } from "@/DataHelper";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { TimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import GridMapper from "@/editor/GridMapper.vue";
// Templates für Submasken importieren?
// import TempWarnungen from "@/templateWorkflow/WebPZEalleWarnung.vue";
// import TempPause from "@/templateWorkflow/WebWorkflowAntragPause.vue";
// import TempUest from "@/templateWorkflow/WebWorkflowAntragUest.vue";
// import TempVerfallzeit from "@/templateWorkflow/WebWorkflowAntragVerfallzeit.vue";
import GridButton from "./GridButton.vue";
import GridInPlaceEditor from "./GridInPlaceEditor.vue";
import { Globals } from "@/Globals";
import GridAggregateCell from "./GridAggregateCell.vue";
import { showSnackbar } from "@/UIHelper";
// import EditInlineEditor from '../editor/EditInlineEditor.vue';

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DatePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(NumericTextBoxPlugin);

@Component({
  components: { GridButton, GridInPlaceEditor },
  // dependency injection - das diese Subkomponenten zur Verfügung stehen
  provide: {
    grid: [
      Filter,
      ColumnChooser,
      Page,
      Edit,
      Toolbar,
      PdfExport,
      Resize,
      Sort,
      Group,
      ColumnMenu,
      Reorder,
    ],
  },
})
export default class BaseSyncGrid extends Vue {
  @Prop() public query!: string | undefined;
  @Prop() public aic!: number | undefined;
  @Prop({ default: 0 }) public pagZeilen!: number; // Anzahl Zeilen die geladen werden sollen
  @Prop({ default: "VALIDITY" }) public zeitbereichEditorKennung!: string;
  @Prop() public preloadedData!: any;
  @Prop() public allowFilteringProp!: boolean;
  @Prop({ default: null }) public formular!: any;

  private floatLabelType: string = "Never";
  private finishedInit: boolean = false;
  private showLoading: boolean = false;

  public header: any = { bezeichnung: " -- " };
  private queryAIC: any = undefined;
  @Prop() private zeitbereich: any;

  public allColumns: DataColumn[] = [];
  private gridColumns: any[] = [];
  private aggregates: any[] = [];
  private rowdata: any[] = [];
  private gridDataSource: any;
  private color: string = "";
  private SubMask: string = "";

  public data: any[] = [];

  private filterSettings: any = { type: "Excel" }; // gibt: CheckBox, Menu, FilterBar, Excel
  private groupOptions: any = {
    showGroupedColumn: true,
    columns: [],
    disablePageWiseAggregates: true,
  };
  private columnMenu: boolean = false;
  private allowGrouping: boolean = false;
  private allowFiltering: boolean = true;
  private selectOptions: any = { type: "Multiple", mode: "Row" }; // type gibt es Multiple/Single, Mode: Row, Cell,
  // Both = Row und CellSelection allowed

  // Dialog Settings
  private showDialog: boolean = false;
  private dialog: any = {
    aic: 0,
    title: "--!!--",
    componentName: "None",
    zeitbereich: null,
    showCustomEditor: false,
    showCustomGrid: false,
    query: "",
    lastValues: null,

    width: 455,
    height: null,
    fullscreen: false,
  };
  // marker damit wir wissen, dass wir ein add machen und im unselect die markierte Zeile merken
  private willAddRow: boolean = false;
  private lastSelectedRow: any = null;

  private allowPaging: boolean = false;
  private pageOptions: any = { pageSize: "", pageCount: "" };

  private editable: boolean = false;
  private editSettings: any = {
    showConfirmDialog: true,
    showDeleteConfirmDialog: true,
    allowEditing: false,
    allowAdding: false,
    allowDeleting: false,
    mode: "Normal",
  }; // Batch Model = modified Status
  private toolbar: any = [
    "Add",
    "Edit",
    "Delete",
    "Update",
    "Cancel",
    "Print",
    "ColumnChooser",
    // "PdfExport",
    // "CsvExport"
  ];

  private validations: any = [];
  public created() {
    this.$on("showDetailForm", this.showDetailForm);
  }

  public showDetailForm(opt: any) {
    console.log("showForm");

    const me = this;
    // Zeitbereich kann auch null sein.. oder aus Beginn und Ende
    const colZeitbereich = this.allColumns.find(
      (c) => c.kennung === me.zeitbereichEditorKennung
    );
    if (!colZeitbereich) {
      // console.log("Detailmaske wird OHNE Zeitbereich geladen!!!");
      this.dialog.zeitbereich = {
        bereich: "Kein",
        von: null,
        bis: null,
      };
    } else {
      const date = new Date(opt.row[colZeitbereich.name]);
      // daraus Zeitbereich machen
      this.dialog.zeitbereich = {
        bereich: "Tag",
        von: date,
        bis: date,
      };
    }

    // const date = new Date(opt.row[colZeitbereich.name]);

    // this.dialog.zeitbereich = {
    //   bereich: "Tag",
    //   von: date,
    //   bis: date
    // };

    // Stammtyp für SubFormulare übergeben..
    const stt = this.header.aicStt;
    this.dialog.lastValues = null;

    if (opt.column.meta.subFom) {
      const subAic = opt.column.meta.subFom.aic;
      const sub = this.$globalsAic(Globals.Begriff, subAic);
      const subStamm = sub.stt;
      this.dialog.fullscreen = opt.column.meta.fullScreen;
      this.dialog.showCustomGrid = opt.column.meta.tabellenEingabe;
      // AIC für Subformular muss NICHT der MA AIC sein...
      if (subStamm === stt) {
        // wenn Stammtyp der Abfrage und des SubForm ident sind, dann AIC aus der Zeile nehmen und übergeben...
        this.dialog.aic = opt.row.aic_Stamm;
        // SyncStamm für Submaske setzen!
        // TODO - bitte fehlerbehandlung und erst im .then weitermachen!!!
        // Isabelle: ist der AIC leer - darf ich auch nix setzen!
        if (this.dialog.aic) {
          this.$api.setSyncStamm(subStamm, this.dialog.aic);
        }
      } else {
        // dann aus SYSAIC für identen Stt den AIC holen und übergeben...
        // meta.Stt = dann von dieser Spalte den AIC nehmen
        this.dialog.aic = this.$api.user.aic;
      }
      this.dialog.lastValues = opt.row;

      if (sub.gruppe === "Frame") {
        console.log("opening custom form " + sub.kennung);
        const compNameFrm = sub.kennung;
        const compName = this.findComponent(compNameFrm);
        if (compName == null) {
          alert(
            "Entschuldung die Komponente '" +
              compNameFrm +
              "' ist nicht verfügbar!"
          );
          return;
        }

        console.log(
          "dialog parameter: AIC:" +
            this.dialog.aic +
            " Zeitbereich: " +
            this.dialog.zeitbereich.bereich
        );
        this.dialog.item = opt.row;
        this.dialog.componentName = compName;
        this.dialog.showCustomEditor = true;
      } else if (sub.gruppe === "Abfrage") {
        console.log("opening query " + sub.kennung);
        this.dialog.query = sub.kennung;
      }
      this.dialog.width = 455;
      this.dialog.height = null;
      this.showDialog = true;
    }
  }

  private findComponent(name: string) {
    name = name.substr(2).replace(/_/g, "").toLowerCase();
    for (const compName in this.$root.$options.components) {
      if (compName.toLowerCase().indexOf(name) >= 0) {
        return compName;
      }
    }
    return null;
  }

  // private provide: any =  {grid: [Edit]};
  public mounted() {
    if (this.aic) {
      this.queryAIC = this.aic;
    }
    console.log("grid start...");
    try {
      if (!this.query && !this.preloadedData) {
        showSnackbar(
          this.$globalsBezeichnung(
            Globals.Begriff,
            "Web_keine_Abfrage",
            "keine Abfrage gefunden"
          )
        );
        this.showLoading = false;
        this.header = { bezeichnung: ";(" };
        return;
      }
      let formAic = "";
      if (this.formular) {
        formAic = this.formular.aic;
      }
      const vecStamm: any[] = [];
      // Paging setzen
      if (this.pagZeilen) {
        this.allowPaging = true;
        const page = this.pagZeilen;
        this.pageOptions = { pageSize: page, pageCount: 4 };
      }

      // Filtering setzen
      if (this.allowFilteringProp === false) {
        this.allowFiltering = false;
      }
      if (this.preloadedData) {
        this.dataLoaded(this.preloadedData);
      } else if (this.query) {
        const me = this;
        this.$api.getQuery(
          // Query
          this.query,
          // Done function
          (data: any) => me.dataLoaded(data),
          this.queryAIC,
          this.zeitbereich,
          this.pagZeilen,
          false,
          false,
          "",
          formAic,
          vecStamm
        );
      }
    } catch (e: any) {
      console.error("mounterror: " + e, e?.stack);
    }
  }

  private dataLoaded(data: any) {
    this.showLoading = false;
    console.log("grid data loaded");
    // Fehlerbehandlung
    if (data.data.error !== undefined) {
      this.$api.onfail(data.data.error);
      console.log(data.data.error);
      this.$emit("back");
      return;
    }

    this.header = data.data.header;
    this.rowdata = data.data.data;
    this.allColumns = data.data.columns;

    // Dialog Modus einschlaten - TEMMPLATE fehlt noch-...
    const editDialog = data.data.header.DialogEdit;
    if (editDialog) {
      this.editSettings.mode = "Dialog";
    }
    // Tabelle auf editierbar setzen
    const allowEditing = data.data.header.edit;
    if (allowEditing) {
      this.editable = true;
      if (!data.data.header.keinNeu) {
        this.editSettings.allowAdding = true;
      }

      if (!data.data.header.keinDel) {
        this.editSettings.allowDeleting = true;
      }
      // if (!data.data.header.keinSave){
      //   this.editSettings.allowEditing = true;
      // }
      this.editSettings.allowEditing = true;
      // this.editSettings.allowDeleting = this.editSettings.allowAdding = allowEditing;
    } else {
      this.editable = false;
    }
    const columns = data.data.columns.filter(
      (c: any) => c.meta.invisible !== true && !c.meta.banned // banned wird bei keiner Berechtigung gesetzt!
    ); // bitte filtern nach dem was ma wirklich brauchen ;)

    // gruppierung setzen
    const keineGruppe = this.header.verdichten; // ist verdichten gesetzt, dann soll nicht gruppiert werden!
    if (keineGruppe) {
      const groupColumns = data.data.columns.filter(
        (c: any) => c.meta.gruppiert === true
      );
      let gruppierbar = false;

      if (groupColumns.length > 0) {
        gruppierbar = true;
        this.columnMenu = true; // bei gruppierung ist der Button für die Columns Auswahl weg.. so schalte ich es ein!
        this.allowGrouping = true;
        if (groupColumns.length > 0) {
          const group = groupColumns.map(
            (col: any) => this.fixKennungForProperty(col) // kennung vom feld nehmen nicht den TITEL!!!
          );
          this.groupOptions = { showGroupedColumn: true, columns: group };
        }
      }
    }

    const me: any = this;

    // die Columns die wir ans Grid binden
    this.gridColumns = columns.map((col: any) => {
      {
        let header: any = col.title;
        let alignText: any = "Right";

        let widthNumber = col.meta.webWidth * 1.45;
        if (!widthNumber) {
          widthNumber = 200;
        }
        let headerCss = "default";
        if (col.meta.required) {
          header = col.title + " " + "*";
          headerCss = "requiredHeader";
        }

        if (
          col.type === "textField" ||
          col.type === "comboBox" ||
          col.type === "datePicker" ||
          col.type === "checkBox3"
        ) {
          alignText = "Left";
        }
        let allowGrouping = col.meta.gruppiert;
        if (allowGrouping && keineGruppe) {
          allowGrouping = false;
          this.allowGrouping = false;
        }
        // Subformulare nur anzeigen wenn Werte> 0 sind?
        if (col.meta.subFom) {
          return {
            field: this.fixKennungForProperty(col),
            headerText: header,
            showInColumnChooser: false,
            width: widthNumber,
            textAlign: alignText,
            headerTextAlign: "left",
            showColumnMenu: false,
            allowFiltering: false,
            template: () => {
              const VueGridButton: any = Vue.extend(
                me.$options.components.GridButton
              );
              VueGridButton.column = col;
              VueGridButton.grid = this;
              return { template: VueGridButton };
            },
          };
        }
        return {
          headerText: header,
          field: this.fixKennungForProperty(col),
          enableGroupByFormat: col.type === "date",
          // headerText: col.title + " " + col.meta.width,
          edit: this.getEditCell(col),
          type: "text",
          allowGrouping,
          visible: !col.meta.reserve,
          showInColumnChooser: !col.meta.required,
          width: widthNumber,
          clipMode: "Ellipsis", // nicht mehr "EllipsisWithTooltip",
          allowEditing: !col.meta.readOnly,
          textAlign: alignText,
          headerTextAlign: "left",
          customAttributes: { class: headerCss },
          // enableGroupByFormat: groupFormat // setzen bei Datum und Währung,...

          // editType: col.type zB dropdownedit, numericedit....
          // footerTemplate="sumTemplate" // avg etc siehe Aggregate Definition...
        };
      }
    });

    // Summenzeilen

    const aggregateColumns = data.data.columns.filter(
      (c: any) =>
        c.meta.ergebnisArt !== undefined && c.meta.ergebnisArt !== null
    );

    if (aggregateColumns && aggregateColumns.length > 0) {
      const aggrGroup = [];
      const aggrFooter = [];
      for (let i = 0; i < aggregateColumns.length; i++) {
        const col = aggregateColumns[i];

        const agrGroupTemplate = () => {
          return {
            template: this.getAgregateCell(col),
          };
        };
        const agrFooterTemplate = () => {
          return {
            template: this.getAgregateCell(col),
          };
        };

        aggrGroup.push({
          type: "Custom",
          format: "0.00",
          field: this.fixKennungForProperty(col),
          groupFooterTemplate: agrGroupTemplate,
          customAggregate: (a: any) => me.getAggregateValue(a, col),
        });
        aggrFooter.push({
          type: "Custom",
          format: "0.00",
          field: this.fixKennungForProperty(col),
          footerTemplate: agrFooterTemplate,
          customAggregate: (a: any) => me.getAggregateValue(a, col),
        });
      }

      this.aggregates = [{ columns: aggrGroup }, { columns: aggrFooter }];
    }
    // grid.appendTo('#Grid'); ??

    // let setting = this.$api.getUserSettingsForBegriff(me.header.aicBegriff);
    // AIC Spalte auf  :isPrimaryKey='true' setzen!
    // später in eine computed property packen -> autorefresh ;)

    const fixZeile = this.allColumns.find(
      (c) => c && c.meta && c.meta.kz === "fixZeile"
    );
    let celldata = this.rowdata;
    if (fixZeile) {
      const fixRow = this.rowdata.find((r) => {
        if (r[fixZeile.name]) {
          return true;
        }
        return false;
      });
      celldata = this.rowdata.filter((r) => {
        if (r[fixZeile.name]) {
          return false;
        }
        return true;
      });

      this.gridColumns.forEach((col) => {
        if (!col.allowGrouping) {
          const qc = this.allColumns.find(
            (cc) => this.fixKennungForProperty(cc) === col.field
          );
          let val = "";
          if (qc) {
            // &nbsp; erzwungender leerspace damit nicht der feldname drin steht
            val = "&nbsp;" + (getDisplay(fixRow, qc) ?? "");
          }
          col.columns = [
            {
              headerText: val,
              disableHtmlEncode: false,
              textAlign: col.textAlign,
              headerTextAlign: col.headerTextAlign,
              width: col.width,
              field: col.field,
              enableGroupByFormat: col.enableGroupByFormat,
              edit: col.edit,
              type: col.text,
              allowGrouping: col.allowGrouping,
              visible: col.visible,
              showInColumnChooser: col.showInColumnChooser,
              clipMode: col.clipMode, // nicht mehr "EllipsisWithTooltip",
              allowEditing: col.allowEditing,
              customAttributes: col.customAttributes,
              template: col.template,
            },
          ];
        }
      });
    }
    this.data = celldata.map((row: any) => {
      const mapped: any = { row };
      for (let i = 0; i < this.gridColumns.length; i++) {
        const col = columns[i];
        if (col.kennung) {
          const propertyname = this.fixKennungForProperty(col);

          if (
            row[col.name] ||
            (row[col.name] === 0 && col.type === "checkBox3")
          ) {
            mapped[propertyname] = getDisplay(row, col);
          } else {
            mapped[propertyname] = null;
            // kann man weglassen wenns "undefined" sein soll
          }
        }
      }
      if (data.aic) {
        mapped.aic = data.aic; // wenn kein aic da dann mach ma einen
      }
      return mapped;
    });

    this.finishedInit = true;
  }

  public fixKennungForProperty(column: any): string {
    let x = "g" + column.name;
    if (this.allColumns) {
      x += "_" + this.allColumns.indexOf(column);
    }
    return x.replace(/\s+/g, "_");
  }

  private farbeColumn: any = null;
  private rowDataBound(args: any) {
    try {
      if (this.data.indexOf(args.data) <= 0) {
        this.farbeColumn = this.allColumns.find((c) => c.kennung === "FARBE");
      }

      if (this.farbeColumn) {
        const color = args.data.row[this.farbeColumn.name];
        if (color) {
          args.row.bgColor = color;
        }
      }
    } catch (e) {
      console.log("error rowDataBund:" + e);
    }
  }

  private getAgregateCell(col: any) {
    const me = this;
    // define a mixin object
    const gridMixIn: any = {
      data: () => {
        return { column: col, data: null };
      },
    };

    const VueGridAggregate = Vue.extend(GridAggregateCell).extend({
      mixins: [gridMixIn],
    });
    return VueGridAggregate;
  }

  private getAggregateValue(a: any, col: any) {
    let txt = "-";
    if (a && a.items) {
      try {
        const rows = a.items.map((x: any) => x.row);
        const val = getGroupSumValue(rows, col);
        txt = getDisplayValue(val, col);
      } catch (ex) {
        console.log(ex);
      }
    } else {
      if (a.result && a.result.length > 0) {
        try {
          const val = getGroupSumValue(this.rowdata, col);
          txt = getDisplayValue(val, col);
        } catch (ex) {
          console.log(ex);
        }
      }
    }
    return txt;
  }

  private getEditCell(column: any) {
    try {
      const pid = getUniqueID("templateParentDiv"); // create a emty div as container
      const iid = getUniqueID("templateParentDiv"); // create a emty div as container
      const ele = createElement("div", { id: pid });

      document.body.appendChild(ele); // append it somewhere

      // https://css-tricks.com/creating-vue-js-component-instances-programmatically/
      // prepare the vue Component for it
      const VueGridMapper = Vue.extend(GridMapper);
      let instance: any = null; // no current instance
      let rowData: any = null;
      let syncColumn: any = null;

      const me = this;

      return {
        create(data: any) {
          console.log("template create()");
          return ele; // return the container element
        },
        read() {
          try {
            console.log("template read()");
            // return the value of the instance
            // if (instance.column.name === "e585") { // Prüfung auf e Nummer darf nirgendst vorkommen!
            // }
            const newdata = instance.getValue();
            // mappen für Anzeige
            const txt = getDisplayValue(newdata, instance.column);
            console.log(
              instance.column.name + " -> " + JSON.stringify(newdata)
            );

            const ix = me.data.indexOf(rowData);
            if (ix >= 0) {
              me.data[ix][column.name] = newdata;
            }

            rowData.row[column.name] = newdata;

            return txt;
          } catch (error) {
            console.error(error);
          }
        },
        write(val: any) {
          try {
            console.log("template write()"); // mount the component to the container element
            rowData = val.rowData; // remember the data
            syncColumn = val.column; // remember the column

            let selected = null;
            if (me.lastSelectedRow) {
              selected = me.lastSelectedRow.row;
            }

            instance = new VueGridMapper({
              // create a new one
              propsData: {
                column,
                row: rowData.row,
                uid: val.column.uid,
                lastRow: selected,
              },
            });
            instance.$vuetify = me.$vuetify;
            instance.$mount(); // mount it and pass the container
            ele.appendChild(instance.$el);

            instance.setValue(val.rowData[syncColumn.field]); // set the current data in the component
            if (instance.validate) {
              me.addValidation({
                field: instance,
                isValid: instance.validate,
              });
            }
          } catch (error) {
            console.error(error);
          }
        },
        destroy() {
          try {
            me.validations = [];
            console.log("template destroy()"); // destroy it again...
            if (instance) {
              ele.removeChild(instance.$el);
              instance.$destroy();
              instance = null;
            }
          } catch (ex) {
            console.log("error destroying:", ex);
          }
        },
      };
    } catch (error) {
      console.error(error);
    }
  }
  private addValidation(v: any) {
    this.validations.push(v);
  }

  private onValidate() {
    let valid: boolean = true;
    for (let i = 0; i < this.validations.length; i++) {
      const val = this.validations[i];
      const res = val.isValid() as boolean;

      valid = valid && res;

      console.log(
        "field " +
          val.field.column.name +
          "/" +
          val.field.column.title +
          " -> " +
          res
      );
    }

    return valid;
  }

  private rowSelected(args: any) {
    /*    console.log(
      "select row: " +
        args.row.getAttribute("aria-rowindex") +
        " col: " +
        args.target.getAttribute("aria-colindex")
    );
*/
    this.lastSelectedRow = args.data;
  }
  private rowDeselected(args: any) {
    console.log("select rowDeselected");
    if (!this.willAddRow) {
      this.lastSelectedRow = null;
    } else {
      console.log("rememberling row for adding");
    }
  }

  // onback liefert ggf daten mit die im Grid upgedatet werden müssen...
  private onBack(updateData: any) {
    if (updateData) {
      const data = updateData.data[0];
      const gridrow = this.data.find(
        (row: any) => row.row === this.dialog.item
      );
      if (data) {
        for (const key of Object.keys(data)) {
          if (key !== "aic_Bew_pool") {
            const column = this.allColumns.find((col: any) => col.name === key);
            if (column) {
              console.log(
                "update grid " +
                  key +
                  " from " +
                  this.dialog.item[key] +
                  " to " +
                  data[key]
              );
              try {
                // value in data-row (originaldaten) updaten...
                this.dialog.item[key] = data[key];
                // anzeige refreshen
                const rowProp: any = this.fixKennungForProperty(column);
                gridrow[rowProp] = getDisplayValue(data[key], column);
              } catch (ex) {
                console.log(ex);
              }
            }
          }
        }
        this.data = [...this.data]; // datasource refreshen für grid
      }
    }

    this.showDialog = false;
  }

  private onDialogResizeMe(args: any) {
    if (typeof args === "object") {
      if (args.width) {
        this.dialog.width = args.width;
      }
      if (args.height) {
        this.dialog.height = args.height;
      }
      if (args.fullscreen) {
        this.dialog.fullscreen = args.fullscreen;
      }
    }
  }

  private backupData: any;
  private actionBegin(args: any) {
    console.log("grid action begin: " + args.requestType);
    if (args.requestType === "save") {
      const isvalid = this.onValidate();

      if (!isvalid) {
        args.cancel = true;
        showSnackbar({
          text: "Validierung fehlgeschlagen - " + args.data.bezeichnung,
          // this.$begriffBezeichnung("Fehlerliste3"), // "Validierung fehlgeschlagen - Bitte prüfen!",
          duration: 4000,
          color: "error",
        });
        return;
      }

      const data = args.data.row;
      // if (Object.entries(data).length === 0 && data.constructor === Object) {
      for (let i = 0; i < this.validations.length; i++) {
        const valid = this.validations[i];
        const val = valid.field.getValue();

        if (val) {
          data[valid.field.column.name] = val;
          console.log("field " + valid.field.column.name + " -> " + val);
        }
      }
      // }
    } else if (args.requestType === "add") {
      this.willAddRow = true;
      args.data.row = {};
    }
  }
  private actionCompleted(args: any) {
    this.willAddRow = false; // zurücksetzen - wir sind fertig ;)
    if (args.requestType === "delete") {
      const data = args.data[0].row;

      const bewPool = data.aic_Bew_pool;

      this.$api.DeleteData(
        this.header,
        bewPool,
        0,
        this.zeitbereich,
        (res: any) => {
          const msgType = res.data.msgType;
          let info = res.data.info;
          const memo = res.data.memo;
          const title = res.data.titel;
          if (memo) {
            info = memo;
          }
          if (info) {
            if (msgType === "Error") {
              showSnackbar({
                text: info,
                color: "error",
              });
            } else if (msgType === "Info") {
              showSnackbar({
                text: info,
                color: "info",
              });
            } else if (msgType === "Warnung") {
              showSnackbar({
                text: info,
                color: "warning",
              });
            }
            this.showLoading = false;
            return msgType;
          }
          showSnackbar(
            this.$globalsBegriffMemo(
              Globals.Begriff,
              "Datensatz_geloescht",
              "Daten wurden gelöscht"
            )
          );
        },
        (ex: any) => {
          let errorMessage = "";
          if (ex.response) {
            errorMessage = ex.response.data.error;
          } else {
            errorMessage = ex;
          }
          this.$root.$emit("alert", {
            text: errorMessage,
            type: "error",
          });
          console.log(ex);
          alert("Entschuldigung es ist ein Fehler beim Löschen aufgetreten");
        }
      );
    } else if (args.requestType === "cancel") {
      // nix passiert
      return;
    } else if (args.requestType === "add") {
      // TODO: Prefill new args.data
    } else if (args.requestType === "beginEdit") {
      // TODO: Editor initialisieren wenn noch extra was passieren soll...
      // (bitte noch checken ob das noch im event davor passieren muss)

      // klon von objekt erzeugen mit allen unterproperties ;)
      this.backupData = JSON.parse(JSON.stringify(args.rowData.row));
    } else if (args.requestType === "save") {
      // Daten zum Server schicken
      const data = args.data.row;

      // leere Objekte brauchen wir nicht speichern
      if (Object.entries(data).length === 0 && data.constructor === Object) {
        showSnackbar(
          this.$globalsBegriffMemo(
            Globals.Begriff,
            "Datensatz_keine_Aenderung",
            "Daten wurden nicht verändert, kein Speichern durchgeführt!"
          )
        );
        return;
      }

      if (this.header.aicBew) {
        const me = this;

        this.$api.SaveData(
          this.header,
          0,
          data.aic_Bew_pool,
          data,
          this.allColumns,
          this.zeitbereich,
          this.backupData,
          (res: any) => {
            if (!res.nothingHappend) {
              // wenn nix passiert is aber weitermachen ;)
              showSnackbar({
                text: this.$globalsBegriffMemo(
                  Globals.Begriff,
                  "Datensatz_gespeichert",
                  "Daten wurden gespeichert"
                ),
                duration: 4000,
              });
            } else {
              showSnackbar(
                this.$globalsBegriffMemo(
                  Globals.Begriff,
                  "Datensatz_keine_Aenderung",
                  "Daten wurden nicht verändert, kein Speichern durchgeführt!"
                )
              );
            }
          },
          (ex: any) => {
            let errorMessage = "";
            if (ex.response) {
              errorMessage = ex.response.data.error;
            } else {
              errorMessage = ex;
            }
            this.$root.$emit("alert", {
              text: errorMessage,
              type: "error",
            });
            console.log(ex);
            alert(
              "Entschuldigung es ist ein Fehler beim Speichern aufgetreten"
            );
          }
        );
      }
    }
  }
}
