





























































import { Component, Prop } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class DialogDienstplanVG extends EditPage {
  public allColumns: any[] = [];
  private saveVisible: boolean = true;
  private showGanztag: boolean = false;
  constructor() {
    super();
    this.query = "Web_TE_TGM_Editierbar";
    this.showLoading = true;
  }
  public mounted() {
    if (this.siteData.readOnlyMutable) {
      this.saveVisible = false;
      this.showGanztag = false;
    }
    if (this.siteData.tag) {
      this.zeitbereich = {
        von: this.siteData.tag,
        bis: this.siteData.tag,
        bereich: "Tag"
      };
    }
    this.mountedBase();
  }

  public get showDelete() {
    return (
      this.data &&
      this.data.aic_Bew_pool &&
      this.data.aic_Bew_pool > 0 &&
      !this.siteData.readOnlyMutable
    );
  }

  public onDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.$emit("delete", this.data.aic_Bew_pool);
    }
  }
}
