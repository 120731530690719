var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showMutable)?_c('div',{staticStyle:{"margin":"0px"}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0"},[(_vm.sttVon)?_c('v-select',{ref:"combobox",staticStyle:{"padding-left":"5px"},attrs:{"clearable":_vm.clearable,"dense":"","flat":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"aic","loading":_vm.showLoading,"no-data-text":_vm.$globalsBezeichnung(
            _vm.Globals.Begriff,
            'Web_keineDaten_gefunden',
            'keine Daten gefunden'
          ),"filter":_vm.filterFunction,"error-messages":_vm.errorText},on:{"change":_vm.selectedValueChanged},model:{value:(_vm.selectedElement),callback:function ($$v) {_vm.selectedElement=$$v},expression:"selectedElement"}}):_c('v-select',{ref:"combobox",staticStyle:{"padding-left":"5px, padding-bottom: 5px"},attrs:{"clearable":_vm.clearable,"dense":"","flat":"","label":_vm.description,"items":_vm.list,"disabled":!_vm.enabled,"item-text":"bezeichnung","item-value":"aic","loading":_vm.showLoading,"no-data-text":_vm.$globalsBezeichnung(
            _vm.Globals.Begriff,
            'Web_keineDaten_gefunden',
            'keine Daten gefunden'
          ),"filter":_vm.filterFunction,"error-messages":_vm.errorText,"persistent-hint":"","return-object":"","menu-props":"auto","hint":_vm.hintText},on:{"change":_vm.selectedValueChanged,"click":function($event){_vm.showList = true}},scopedSlots:_vm._u([{key:"item",fn:function(ref){return [(_vm.showList)?_c('v-card',{staticClass:"pa-0"},[(!_vm.noSearch)?_c('v-text-field',{attrs:{"label":"Suche","flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-treeview',{staticClass:"pa-0",attrs:{"open-all":"","items":_vm.items,"item-key":"aic","item-text":"bezeichnung","selectable":"","selection-type":"independent","search":""},on:{"input":_vm.selectedValueChanged,"change":_vm.selectedValueChanged},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
          var open = ref.open;
return [_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e()]}}],null,false,2081130903),model:{value:(_vm.selectedElement),callback:function ($$v) {_vm.selectedElement=$$v},expression:"selectedElement"}})],1),(_vm.showSettings)?_c('v-col',{staticClass:"py-0 pl-0 mr-5",staticStyle:{"cursor":"hand"},attrs:{"cols":"1"},on:{"click":_vm.openSettings}},[_c('v-icon',{attrs:{"text":""}},[_vm._v("more_vert")])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }