import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "@/UIHelper";

@Component
export default class CalcHelper extends Vue {
  protected aic!: number;
  @Prop() public zeitbereich!: APIZeitbereich | null;
  @Prop({ default: true }) protected backOnSave!: boolean;
  @Prop({ default: null }) protected siteData: any;
  // protected disabled!: boolean;
  // @Prop({ default: false }) protected showResult!: boolean;
  protected begriffModell: ALLModell | null = null;
  protected tabUbergabe: any[] = [];
  protected showLoading: boolean = false;
  @Prop() protected varName!: any;
  @Prop() protected varDatum!: any;
  @Prop() protected varName2!: any;
  @Prop() protected varName3!: any;
  @Prop() protected varZeitart!: any;
  @Prop() protected variable!: any;
  @Prop() protected variable1!: any;
  @Prop() protected variable2!: any;
  @Prop() protected variable3!: any;
  @Prop({ default: false }) public checkTimeZone!: boolean;
  protected debug: number = 0;
  protected abbruch: number = 0;
  protected calcResult: string = "";
  protected calcMessage: string = "";
  protected calcInfo: string = "";
  protected calcWarnung: string = "";
  protected msgType: string = "";
  protected header: string = "";
  protected info: string = "";
  protected iconAmpel: string = "";
  @Prop() protected text!: string;

  public calcFunction(
    modell: string,
    aic: number,
    fromDialog: boolean = false,
    vecStamm: any
  ) {
    if (modell) {
      this.begriffModell = this.$globalsKennung(Globals.Begriff, modell);
    }
    const me = this;
    // wird der Begriff nicht gefunden - ABBRUCH!
    if (!this.begriffModell) {
      showSnackbar({
        text: "Modell nicht gefunden",
        color: "error"
      });
      this.showLoading = false;
      return;
    }
    if (this.begriffModell.userFrage && fromDialog !== true) {
      const userFrage = this.$globalsBegriffMemo(
        this.Globals.Begriff,
        this.begriffModell.userFrage,
        "Soll die Berechnung durchgeführt werden?"
      );
      showDialog({
        title: "Frage",
        titleColor: "white",
        titleClass: "black--text",
        width: 300,
        height: 400,
        text: userFrage,
        persistent: true,
        okButton: true,
        onClose: (x: any) => {
          if (x.dialogResult === true) {
            me.calcFunction(modell, aic, true, vecStamm);
          }
          return true;
        }
      });
      return Promise.resolve();
    }
    // dialog nur aufmachen wenn dialog erforderlich und aufruf nicht vom dialog kommt.
    if (
      this.begriffModell.abfrage &&
      this.begriffModell.MDialog &&
      fromDialog !== true
    ) {
      showDialog({
        title: "Modelldialog",
        width: 250,
        site: "ModellDialog",
        // zeitbereich: me.zeitbereich,
        titleColor: "dvhBackground",
        titleClass: "white--text",
        data: {
          zeitbereich: this.zeitbereich,
          query: this.begriffModell.abfrage
        },
        okButton: true,
        onClose: (x: any) => {
          if (x.returnedData.length > 0) {
            this.tabUbergabe = x.returnedData[0];
          }
          if (x.dialogResult === true) {
            me.calcFunction(modell, aic, true, vecStamm);
          }
          return true;
        }
      });
      return; // nicht weitermachen nur dialog aufmachen
    }
    this.showLoading = true;
    const varUbergabe: any[] = [];
    const tabUbergabe: any[] = [];
    const bemerkung: string = "Web - CalcHelper";
    // ans Modell muss immer die richtige Periode übergeben werden!
    if (this.begriffModell?.periode !== "offen" && this.zeitbereich) {
      if (this.zeitbereich.bereich !== this.begriffModell.periode) {
        this.zeitbereich.bereich = this.begriffModell.periode;
      }
    }
    if (this.varName || this.varName2 || this.varDatum || this.varName3) {
      if (this.variable) {
        varUbergabe.push({
          var: this.varName,
          type: "string",
          wert: this.variable,
          art: 1,
          user: this.$api.user.user,
          perm: false
        });
      }
      if (this.varZeitart) {
        varUbergabe.push({
          var: this.varName2,
          type: "string",
          wert: this.varZeitart,
          art: 1,
          user: this.$api.user.user,
          perm: false
        });
        // this.$api.setVariable(varUbergabe, this.$api.user.id, this.success); // soll das Memo übergeben
      }
      if (this.variable1) {
        let wert;
        if (this.variable3) {
          wert = this.variable3;
        } else {
          wert = new Date(this.variable1).toISOStringWithTZ();
        }
        varUbergabe.push({
          var: this.varDatum,
          type: "string",
          wert,
          art: 1,
          user: this.$api.user.user,
          perm: false
        });
      }
      if (this.variable2) {
        varUbergabe.push({
          var: this.varName3,
          type: "int", // für Integer - Ganzzahl
          wert: this.variable2,
          art: 1,
          user: this.$api.user.user,
          perm: false
        });
      }
      if (this.checkTimeZone && !this.variable3) { // wurde
        // ein abw. Zone gesetzt & der user ist in einer abw. Zone - dann darf ich das nicht übersteuern
        const lokalOffset = new Date().getTimezoneOffset() * -1;
        const serverTime = this.$api.user.time;
        const serverOffset = Date.getISOTimeZoneOffsetInMin(serverTime);
        if (lokalOffset !== serverOffset) {
          const newtimeZone = new Date('2021-01-01').getTimezoneOffset() * -1;
          varUbergabe.push({
            var: "differentTimeZone",
            type: "boolean",
            wert: true,
            art: 1,
            user: this.$api.user.user,
            perm: false
          });
          varUbergabe.push({
            var: "newtimeZone",
            type: "int",
            wert: newtimeZone,
            art: 1,
            user: this.$api.user.user,
            perm: false
          });
        }
      }
    }
    return this.$api.postCalc(
      varUbergabe,
      tabUbergabe,
      this.begriffModell.aic,
      aic,
      this.zeitbereich,
      0,
      0,
      bemerkung,
      this.success,
      this.begriffModell.maxB,
      vecStamm
    );
  }
  protected success(res: any) {
    this.calcResult = res.data.ergebnis;
    let ampelNumber: string = "";
    if (this.begriffModell?.abfrage) {
      if (this.calcResult === "Gelb") {
        ampelNumber = "2";
      } else if (this.calcResult === "Rot") {
        ampelNumber = "3";
      } else if (this.calcResult === "Gruen") {
        ampelNumber = "1";
      }
      this.iconAmpel =
        "<img src='/" + ampelNumber + "_Ampel.png' alt='" + ampelNumber + "' />";
    } else {
      this.iconAmpel = "";
    }

    res.data.text = this.text;
    this.$emit("calculated", res.data);
    const vorfehler = res.data.vorfehler;
    if (vorfehler && vorfehler.length > 0 && vorfehler[0].error) {
      console.error("Thread - Modell Fehler: " + res.data.header.vorfehler[0].error);
      showSnackbar({
        text: vorfehler.error,
        color: "error"
      });
    }
    this.calcMessage = res.data.error;
    this.msgType = res.data.msgType;
    this.header = res.data.header;
    this.info = res.data.info;
    const memo = res.data.memo;
    const title = res.data.titel;
    if (memo) {
      this.info = memo;
    }
    // this.showLoading = false;

    if (this.msgType === "Error") {
      showSnackbar({
        text: this.info,
        color: "error"
      });
    } else if (this.msgType === "Info") {
      showSnackbar({
        text: this.info,
        color: "info"
      });
    } else if (this.msgType === "Warnung") {
      showSnackbar({
        text: this.info,
        color: "warning"
      });
    }
    this.showLoading = false;
  }
}
