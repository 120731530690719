












































import { Vue, Component, Prop } from "vue-property-decorator";
import BaseSyncSchedule from "../baseSyncSchedule.vue";
import Constants from "@/Constants";

@Component
export default class TemplatesResourceHeader extends Vue {
  @Prop() public row!: any;
  @Prop() public schedule!: BaseSyncSchedule;
  public created() {
    // fix initialisation if icons
    const app = Constants.App;
    if (app) {
      (this.$vuetify as any).icons = (app as any).$vuetify.icons;
    }
  }
}
