






































import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component({
  provide: {}
})
export default class StammFirma extends Vue {
  @Prop() public query!: string | undefined; // per default nicht initialisiert ;)

  private queryAIC: any = undefined;
  private position: string = "Left";

  private showBackdrop: boolean = true;
  private content0 = "...";

  protected backdrop() {
    const backdropBtn = this.$refs.backdropBtn as any;
    if (backdropBtn.$el.classList.contains("e-active")) {
      backdropBtn.$el.textContent = "True";
      // enable the backdrop property
      this.showBackdrop = false;
    } else {
      backdropBtn.textContent = "False";
      // disable the backdrop property
      this.showBackdrop = true;
    }
  }
}
