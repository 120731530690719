













































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditZahl from "@/editor/EditZahl.vue";
// import EditComponent from "@/editor/EditComponent";
import { Globals } from "@/Globals"; // könnte man das in die EditComponent rein hängen?

@Component
export default class EditMass extends EditZahl {
  public mounted() {
    if (
      this.column &&
      this.column.meta &&
      this.column.meta.einheit &&
      !this.column.meta.originalEinheit
    ) {
      const einheit = this.column.meta.einheit;
      const anzeige = this.$globalsAic(Globals.Einheit, einheit);
      if (anzeige && anzeige.Kennung) {
        this.format = this.format + " " + anzeige.Kennung;
      }
    } else if (this.column.meta.originalEinheit) {
      const anzeige = this.data.kz;
    }
    // einheit aus globalen Tabelle Einheiten holen
  }
}
