





























































import { Component, Prop, Provide, Watch } from "vue-property-decorator";
import { formatDate } from "@/editor/CalenderHelper";
import {
  getDisplay,
  getEinheitFromColumn,
  getKZColumn,
  getKZTitle,
  getKZValueDisplay
} from "@/DataHelper";
import { getAlternateColor } from "../ChartTypesAndFunctions";

import ChartBase from "./ChartBase";
import BaseQueryChart from "./BaseQueryChart";

declare interface InfoData {
  header: string;
  title: string;
  subtitel: string;
  aic: number;
  icon: string;
}
declare interface KPIData {
  aic: number;
  text: string;
  color: string;
  titel: string;
  aicStamm?: number;
}

@Component
export default class KPI extends BaseQueryChart {
  protected noData: boolean = false;

  protected showLoading: boolean = true;
  private showLength: boolean = true;
  private tooltip: string[] = [];
  private alert: boolean = false;
  protected KPIs: KPIData[] = [];
  private titleTime: string = "";
  private showTitleTime: boolean = false;
  private infoText: string = "";
  private showInfo: boolean = false;
  private showZuordnung: boolean = true;
  private pers: boolean = false;
 @Prop({ default: false }) private persoenlich!: boolean;

  public mounted() {
    this.showTitleTime = this.getKZAufgabeDefiniton("showLastRefresh");
    this.refresh();
  }

  @Watch("QvAICs")
  public refreshOnQV() {
    this.refresh();
  }

  public dataLoaded(data: QueryResponse) {
    const frame = this.zuordnungen?.filter(z => z.gruppe !== "Frame");
    if (frame.length === 0 || frame.length === 1) {
      const drucke = this.zuordnungen?.filter(
        (z => z.gruppe === "Druck") || (z => z.gruppe === "Modell")
      );
      if (drucke.length === 0) {
        this.showZuordnung = false;
      }
    }
    try {
      this.KPIs = [];
      this.showLength = false;
      const titleColumn = getKZColumn("title", data.columns);
      const checkKPI = getKZTitle(data.data, "count", data.columns);
      const kpiColor = getKZColumn("color", data.columns);
      const color = kpiColor?.meta?.farbe ?? "blue";
      if ((data?.data?.length ?? 0) === 0) {
        if (this.QuickViewMode) {
          this.noData = true;
          return;
        }
        this.infoText = "";
        const text = "0";
        const titel = this.params.title ?? "";
        this.KPIs.push({ aic: 0, text, color, titel });
        return;
      } else {
        let unit = "";
        let kpiColumn = getKZColumn("count", data.columns);
        if (!checkKPI) {
          this.showLength = true;
          kpiColumn = data.data.length;
        } else {
          try {
            unit = " " + getEinheitFromColumn(kpiColumn);
          } catch {
            console.log("keine einheit für KPI...");
          }
        }
        // const kpiColor = getKZColumn("color", data.columns);
        // const color = kpiColor?.meta?.farbe ?? "blue";
        this.tooltip = [];
        if (this.showLength) {
          for (let i = 0; i < data.data.length; i++) {
            const row = data.data[i];
            const text = getKZValueDisplay(row, "info", data.columns);
            this.tooltip.push(text);
          }
          this.infoText = this.tooltip.join("<br>");
          this.showInfo = true;
        }
        const aicColumn = getKZColumn("aic", data.columns);
        if (this.QuickViewMode) {
          this.QvAICs.forEach(aic => {
            const row = data.data.find(r => getDisplay(r, aicColumn));
            let titel = this.params.title ?? "";
            if (titleColumn) {
              titel = getDisplay(row, titleColumn);
            }
            const text = getDisplay(row, kpiColumn) + " " + unit;
            this.KPIs.push({ aic, text, color, titel });
          });
        } else {
          const row = data.data[0];
          let titel = this.params.title ?? "";
          if (titleColumn) {
            titel = getDisplay(row, titleColumn);
          }
          let text = "";
          if (this.showLength) {
            text = kpiColumn;
          } else {
            text = getDisplay(row, kpiColumn) + " " + unit;
          }
          const aicStamm = data.header.aicStt;
          this.KPIs.push({ aic: 0, text, color, titel, aicStamm });
        }
      }
    } finally {
      this.showLoading = false;
      this.titleTime = formatDate(new Date(), "HH:mm:ss");
    }
  }

  private getStyle(kpi: KPIData, ix: number) {
    if (this.QuickViewMode || this.size) {
      let background = getAlternateColor(ix);
      if (kpi.color) {
        background = kpi.color;
      }
      return {
        height: this.size + "px",
        background,
        overflow: "hidden",
        cursor: this.zusatzInfo ? "hand" : "",
        padding: "15px"
      };
    }
    return {
      background: getAlternateColor(ix),
      cursor: this.zusatzInfo ? "hand" : ""
    };
  }
  private logData() {
    if (this.showLength) {
      this.alert = !this.alert;
    }
    console.log(this.lastQueryResponse);
  }
}
