



































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";

import EditPage from "@/components/EditPage";
import { Globals } from "@/Globals";
import { DateTimePicker, DateRangePicker } from "@syncfusion/ej2-calendars";
import EditDateRange from "@/editor/EditDateRange.vue";
// import CalcHelperStatic from "@/CalcHelperStatic";
import {
  getKennungValueDisplay,
  getKennungValue,
  getKennungTitle
} from "@/DataHelper";
import { isUndefined } from "lodash";

@Component
export default class Dialog extends EditPage {
  private IsAllDay: boolean = false;
  private color: any = "success";
  private stellVertreter: boolean = false;
  private disabled: boolean = false;
  private stellBew: any = false;
  private date: any = "";
  private bewilligung: boolean = false;
  private stellOffen: boolean = false;
  private maName: string = "";
  private zeitart: string = "";
  private maNameTitel: string = "";
  private zeitartTitel: string = "";
  protected saveVisible: boolean = false;

  constructor() {
    super();
    this.query = "Web_Planung_Dialog_Stellvertreter";
    this.showLoading = true;
  }
  public mounted() {
    if (this.data.length === undefined) {
      // this.$emit("closeDialog");
    } else {
      this.mountedBase();
    }
  }
  private checkChanged(val: any) {
    this.saveVisible = true;
  }
  public get showDelete() {
    return this.data && this.data.aic_Bew_pool && this.data.aic_Bew_pool > 0;
  }

  public onDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.$emit("delete", this.data.aic_Bew_pool);
    }
  }
  protected initialize() {
    this.maNameTitel = getKennungTitle("MA", this.columns);
    this.zeitartTitel = getKennungTitle("ZA", this.columns);
    this.maName = getKennungValueDisplay(this.data, "MA", this.columns);
    const abwText = getKennungValue(this.data, "abwText", this.columns);
    if (abwText) {
      const showText = getKennungTitle("abwText", this.columns);
      this.zeitart = showText;
    } else {
      this.zeitart = getKennungValueDisplay(this.data, "ZA", this.columns);
    }
    const allDay = getKennungValueDisplay(
      this.data,
      "B_CALC_FIELD",
      this.columns
    );
    if (allDay === true) {
      this.IsAllDay = true;
    }
    const zuBewilligen = getKennungValueDisplay(
      this.data,
      "GRANTED",
      this.columns
    );
    if (zuBewilligen === "ja") {
      this.bewilligung = true;
    }
    const checkBewilligt = getKennungValueDisplay(
      this.data,
      "b1",
      this.columns
    );

    const checkStellvertreter = getKennungValueDisplay(
      this.data,
      "SV",
      this.columns
    );

    if (checkStellvertreter) {
      this.stellVertreter = true;
      const bewStell = getKennungValueDisplay(this.data, "b2", this.columns);
      if (bewStell === "bewilligt") {
        this.stellBew = true;
      } else if (checkBewilligt === "offen") {
        this.stellOffen = true;
      }
    }
    const self = this.checkBegriff("Web_VG_Selfservice");
    if (!self) {
      const maAIC = getKennungValue(this.data, "MA", this.columns);
      const userAIC = this.$api.user.aic;
      if (maAIC === userAIC) {
        // this.disabled = true;
      }
    }
  }

  private save() {
    const aicAbfAllBez = this.$globalsKennung(
      Globals.Begriff,
      "WebPlanungSpeichern"
    );
    const aicAbfAll = aicAbfAllBez.aic;
    let aic: any;
    const me = this;
    if (this.aic) {
      aic = this.aic;
    }
    this.onSave(aicAbfAll, "", "", aic)?.then(() => {
      this.$nextTick(() => me.$emit("save"));
    });
  }
}
