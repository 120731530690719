


































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AufgabeStatusDruck } from "../ChartTypesAndFunctions";
import ChartBase from "./ChartBase";

@Component
export default class DashboardListe extends ChartBase {
  private showFilter: boolean = false;
  private showHeader: boolean = false;
  private showAvatarLeft: boolean = false;
  private showSpeedDial: boolean = false;
  private showAvatar: boolean = false;
  private drucke: AufgabeStatusDruck[] = [];
  private showZuordnung: boolean = true;
  @Prop({ default: false }) private persoenlich!: boolean;

  private baseListVector: number[] = [];

  public mounted() {
    if (!this.params.aufgabenDefinition) {
      console.log("no query given for ui");
      return;
    }
    this.drucke = this.zuordnungen
      ?.filter((z) => z.gruppe === "Druck")
      .map((z) => z as AufgabeStatusDruck);
    this.showAvatar = this.getKZAufgabeDefiniton("showAvatar");
    this.showAvatarLeft = this.getKZAufgabeDefiniton("showAvatarLeft");
    this.showSpeedDial = this.getKZAufgabeDefiniton("showSpeedDial");
    this.showHeader = this.getKZAufgabeDefiniton<boolean>("showHeader");
    this.showFilter = this.getKZAufgabeDefiniton<boolean>("showFilter");
    const hideZuordnung = this.getKZAufgabeDefiniton<boolean>("hideZuordnung");
    this.showZuordnung = !hideZuordnung;
    if (this.showHeader) {
      this.$emit("hideDialogTitel");
    }
  }
  protected QvAICsChanged(): void {
    // nothing to do here
  }
  private get concatVector() {
    return this.vector.concat(this.baseListVector);
  }

  private setzeVectorBaseListe(vec: number[]) {
    this.baseListVector = vec;
  }
}
