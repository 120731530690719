
































import EditPage from "@/components/EditPage";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class Stempelungen extends EditPage {
  constructor() {
    super();
    this.showLoading = true;
  }
  protected myHeight: number = 900;
  protected day: Date = this.siteData.zeitbereich.von;
  private titel: string = this.siteData.title;
  private name: string = this.siteData.name;
  private showNacherfassung: boolean = false;
  private showTagessaldo: boolean = false;

  public onRefresh() {
    (this.$refs.timelines as any).onRefresh();
  }
  public mounted() {
    if (this.siteData.aic) {
      this.aic = this.siteData.aic;
    }
    if (this.checkBegriff("Web_VG_InfoAnzeigen")) {
      this.showTagessaldo = true;
    }
    if (this.checkBegriff("Web_KorrekturStempelungen")) {
      this.showNacherfassung = true;
    }
    this.mountedBase();
    this.myHeight = window.innerHeight - 100;
  }
}
