



































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import CalcButton from "@/components/CalcButton.vue";

import VButtonCarousel from "@/components/common/VButtonCarousel.vue";
import Constants from "../../Constants";
import { formatDate } from "@/editor/CalenderHelper";
import { getKZValue, getKennungValueDisplay } from "@/DataHelper";

@Component
export default class SoftTerm extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  private bottomMenuVisible: boolean = false;
  private zeitbereichUebergabe: any;
  private keinReiseBeginn: boolean = false;
  constructor() {
    super();
    const d = new Date();
    const datumHeute = new Date(d.setHours(0, 0, 0, 0));
    this.zeitbereichUebergabe = {
      von: d,
      bis: d,
      bereich: "Tag"
    };
    this.zeitbereich = this.zeitbereichUebergabe;
    const reisen = this.checkBegriff("Web_ReiseUebersichtBuchungen");
    if (reisen) {
      this.query = "Web_ReiseUebersichtBuchungen";
    } else {
      this.query = "";
    }
  }
  private nameReise: string = "";

  public mounted() {
    this.refreshTime();
    this.bottomMenuVisible = Constants.BottomMenuVisible;
  }

  private titleTime: string = "Software Terminal";

  private refreshTime() {
    this.titleTime = formatDate(new Date(), "EE dd.MM.yyyy - HH:mm:ss");
    const me = this;
    setTimeout(this.refreshTime, 1000);
  }

  public onResize(args: any) {
    this.bottomMenuVisible = Constants.BottomMenuVisible;
    this.$forceUpdate();
  }

  protected initialize() {
    this.bottomMenuVisible = Constants.BottomMenuVisible;
    this.keinReiseBeginn = false;
    if (this.data?.aic_Stamm) {
      this.nameReise = getKZValue(this.data, "subject", this.columns);
      this.keinReiseBeginn = true;
    }
  }

  // private onCalculated(args: any) {
  //   this.letzteBuchung = args.text;
  //   this.letzteBuchungUm = args.ergebnis;
  //   this.memo = "";
  // }

  // protected onCancel() {
  //   this.$emit("back");
  // }
  // protected openSettings(subForm: string, title: string) {
  //   this.$root.$emit("showSite", {
  //     site: subForm,
  //     title,
  //     replaceCurrent: true
  //   });
  // }
  // private handleLogout() {
  //   this.$api.logout();
  //   return;
  // }
}
