




























import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditComponent from "@/editor/EditComponent";

@Component
export default class EditSwitchButton extends EditComponent {
  // props: ["initValue", "column", "description"],
  private min: number = 0;
  private max: number = 5;
  constructor() {
    super();
  }

  public mounted() {
    this.data = this.getInitValue();
  }
  protected checkedChanged(txt: any) {
    this.valueChanged(txt); // zum event triggern
  }
}
