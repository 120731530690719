

























import { getDisplay } from "@/DataHelper";
import { Vue, Component, Prop, Provide } from "vue-property-decorator";

@Component
export default class EditComboBoxItem extends Vue {
  @Prop({ default: null })
  private item!: any;

  private row: any = "--";
  private rowData: any[] = [];
  private columns: any[] = [];
  private visibleColumns: any[] = [];
  private percent: string = "100";
  private bezeichnung: string = "--";
  private favColumn: any = false;

  private iconFav: string = "favorite";
  private iconNoFav: string = "favorite_border";
  private favIcon: string = "favorite_border";
  private favIconColor: string = "black";
  private favVal: number = 0;

  public mounted() {
    this.updateMe();
  }
  public updated() {
    this.updateMe();
  }

  private getPercent(ix: number) {
    return (this.visibleColumns[ix].meta?.webWidth ?? this.percent);
  }

  public updateMe() {
    if (this.item) {
      this.bezeichnung = this.item.bezeichnung;
      this.row = this.item.row;
      this.visibleColumns = this.item.visibleColumns;
      this.columns = this.item.columns;
      this.percent = this.item.percent;
      this.favColumn = this.item.favColumn;
    }

    if (this.item.rowData) {
      this.rowData = this.item.rowData;
    }

    if (this.favColumn) {
      this.favVal = getDisplay(this.row, this.favColumn);
    }
  }

  private clickHeader(e: any) {
    if (this.item.headerHtml) {
      e.cancelBubble = true;
      return false;
    }
  }
  private toggleFav(e: any) {
    e.cancelBubble = true;
    if (this.favVal === 2) {
      console.log("cannot update global favorite");
      return;
    } else if (this.favVal === 1) {
      this.favVal = 0;
    } else if (this.favVal === 0) {
      this.favVal = 1;
    }
    this.row[this.favColumn.name] = this.favVal;
    let aic = this.row.aic_Stamm;
    if (!aic) {
      aic = this.row.aic;
    }
    this.$emit("changeFav", { val: this.favVal, aic });
    this.$nextTick(() => {
      this.$forceUpdate();
    });
  }
}
