




































































































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import {
  getKennungValueDisplay,
  getKennungTitle,
  getKennungValue
} from "@/DataHelper";

@Component
export default class TagesSaldenAntrag extends EditPage {
  // @Prop({ default: null }) protected zeitbereich!: APIZeitbereich | null;
  @Prop({}) protected zeitbereichUebergabe: any;
  @Prop({ default: false }) public showClose: boolean = false;
  constructor() {
    super();
    this.query = "Web_WF_Antrag_Tag";
    this.showLoading = true;
  }
  private anzeige: boolean = true;
  private anzeigeAntrag: boolean = true;
  private deleteVisible: boolean = false;
  private saveVisible: boolean = true;
  private showUestAuszahlen: boolean = false;
  private showauszahlungUest: boolean = false;
  private showZulage1: string = "";
  private showZulage2: string = "";
  private showZulage3: string = "";
  private showCloseMutable: boolean = false;

  public created() {
    this.zeitbereich = this.zeitbereichUebergabe;
    this.showCloseMutable = this.showClose;
  }
  // public mounted() {
  //   // this.zeitbereich = this.zeitbereichUebergabe;
  //   this.showCloseMutable = this.showClose;
  //   this.mountedBase();
  // }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "", this.zeitbereich);
      this.$emit("back");
    }
  }
  public save() {
    this.onSave(this.data, "");
  }
  protected initialize() {
    if (this.data && this.data.aic_Bew_pool) {
      this.deleteVisible = true;
    }
    const keinAntrag = getKennungValueDisplay(
      this.data,
      "keinAntrag",
      this.columns
    );

    const editierbar = getKennungValue(
      this.data,
      "ZE_ANTRAG_BOOL3",
      this.columns
    );
    if (editierbar === 1) {
      this.saveVisible = true;
      this.deleteVisible = false;
    }
    if (keinAntrag === "1.00") {
      this.anzeigeAntrag = false;
    }
    const uestAuszahlen = getKennungTitle("ZE_UEST_NICHT_AUSZAH", this.columns);
    if (uestAuszahlen) {
      this.showUestAuszahlen = true;
    }
    const auszahlungUest = getKennungTitle("ZE_AUSZAHLUNG_UEST", this.columns);
    if (auszahlungUest) {
      this.showauszahlungUest = true;
    }
    this.showZulage1 = getKennungTitle("Zulage_1", this.columns);
    this.showZulage2 = getKennungTitle("Zulage_2", this.columns);
    this.showZulage3 = getKennungTitle("Zulage_3", this.columns);
  }
}
