


















































































































































































































import AUApi from "../api";
import { Vue, Component, Prop } from "vue-property-decorator";
import RoleMenuMapping from "@/RoleMenuMapping";
import CryptoJS from "crypto-js";
import { Capacitor } from "@capacitor/core";
import {
  CheckVersion,
  curentALLVersion,
  setUpdateVersionCallback
} from "../VersionChecker";
/*  nativChecken ob update vorhanden  */
import { downloadNativeUpdate, reloadApp } from "../NativeUpdateProvider";
import Constants from "@/Constants";
import { encryptForAD } from "@/ADCrypter";
import { makeRequest } from "@/RequestHandler";
import { setCurrentUser } from "@/UserInfo";
import PasswordValidator from "@/PasswortValidator";
import { showSnackbar } from "@/UIHelper";
import { AxiosError, AxiosResponse } from "axios";
import {
  isAzureConfigSet,
  doAzureLogin,
  getAzureAccountName,
  getAzureToken,
  getAzureUserName,
  checkRefreshToken,
  initAzureLogin,
  getAzureCallbackUrl
} from "@/azureLoginHelper";
import { loadLocalData, saveLocalData } from "@/BrowserDataStore";
import {
  showNativeLoginDialog,
  getDeviceInfo,
  setDeviceInfoCallback
} from "@/DeviceInfoPlugin";

declare var ALLConfig: ALLConfigType;
declare interface LoginCache {
  selectedDB?: string;
}

declare interface LoginData extends ALLUser {
  info: string;
}

@Component
export default class AllLogin extends Vue {
  @Prop() public siteData: any;
  @Prop() public app: any;

  private showLoading: boolean = false;
  private showPassword: boolean = false;
  private showWelcome: boolean = false;
  private valid: boolean = false;
  private DBs: AllConfigDB[] = [];
  private selectedDB: AllConfigDB | undefined;
  private Roots?: AllConfigRoot[] = [];
  private selectedRoot: AllConfigRoot | undefined;
  private azureConfig: AzureConfig | undefined;

  private userfriendlyname: string = "";
  private avatar: any;
  private successText: string = "";
  private errorText: string = "";
  private debugText: string = "";
  private Benutzer: any = null;

  private name: string = "";
  private password: string = "";
  private mandant: string = "-2";
  private hash?: string;
  private art: string = "1";
  private useAzure: boolean = false;

  private isMobile: boolean = false;
  private initialized: boolean = false;
  private showDB: string = "";

  private azureLoginActive = false;
  private isNativeLoginNeeded: boolean = false;

  private timer?: number;

  private passwordValid: boolean = true;
  private passwordMessage: string = "";
  public async mounted() {
    const me = this;
    setDeviceInfoCallback(di => {
      me.isNativeLoginNeeded = di.useNativeLogin === true;
    });
    // Media Abfragen - und merken ob Handy oder Ipad..
    // und dann auf das statt isMobile prüfen
    this.isMobile = Capacitor.getPlatform() !== "web";
    // if (window.matchMedia('screen and (max-width: 768px)').matches) {}
    // this.isMobile = true; // Capacitor.getPlatform() !== "web";
    this.$api.onLoadConfig = () => me.initConfig();
    this.initConfig();
    if (this.DBs?.length === 1) {
      this.showDB = this.DBs[0].name;
    }

    // this.timer = setInterval(() => me.checkPasswordAutoFill(), 300);
    getDeviceInfo(true).then(t => {
      me.isNativeLoginNeeded = t.useNativeLogin === true;
    });
  }

  public unmounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private checkPasswordAutoFill() {
    if (!this.showWelcome) {
      let pwdEl: HTMLInputElement | undefined;
      const snurzpups = this.$refs.password as any;
      if (snurzpups?.length) {
        pwdEl = snurzpups[0].$el;
      } else {
        pwdEl = snurzpups?.$el;
        // snurzpups.$emit("focus");
      }

      if (pwdEl) {
        if (pwdEl.value && !this.password && pwdEl.value !== this.password) {
          console.log("autofill detected!", pwdEl.value, this.password);
          this.password = pwdEl.value;
        }
      }
      if (this.password) {
        this.valid = true;
        if (this.timer) {
          clearInterval(this.timer);
        }
      }
    }
  }

  private onFocusPW() {
    // setTimeout(() => {
    //   const compPW = this.$refs.password as any;
    //   compPW.$emit("focus");
    // });
  }
  private get showDebug() {
    return Constants.isDebug;
  }
  private get plattform() {
    return Capacitor.getPlatform();
  }

  private saveDB() {
    saveLocalData<LoginCache>(
      "loginCache",
      {
        selectedDB: this.selectedDB?.name
      },
      false
    );
  }

  private showNativeLogin() {
    const me = this;
    showNativeLoginDialog()
      .then(res => {
        if (res && res.success && res.user && res.pwd) {
          me.name = res.user;
          me.password = res.pwd;
          me.handleSubmit();
        }
      })
      .catch(e => {
        console.error(e);
      });
  }

  private initConfig() {
    this.checkAppVersion();
    this.$root.$forceUpdate();
    this.DBs = this.$api.getDBs();
    this.initialized = ALLConfig.initialized === true;
    this.selectedDB = undefined;
    this.showDB = ""; // nach Qr Code Scannen - initalisieren das DEMO nicht hängen bleibt!

    const loginCache = loadLocalData<LoginCache | undefined>(
      "loginCache",
      undefined,
      false
    );
    if (loginCache?.selectedDB) {
      this.selectedDB = this.DBs.find(d => d.name === loginCache?.selectedDB);
    } else if (this.$api.dB && this.DBs) {
      this.selectedDB = this.DBs.find(d => d.db === this.$api.dB);
    }
    if (this.DBs?.length === 1 && this.selectedDB) {
      this.showDB = this.selectedDB.name;
    }
    this.Roots = this.$api.getRootUrls();
    this.azureConfig = this.$api.getAzureDef();
    this.selectedRoot = undefined;
    if (this.$api.rootUrl) {
      this.selectedRoot = this.Roots?.find(
        d => d.rootUrl === this.$api.rootUrl
      );
    }
    // 23.2.2022 ein automatisches neu einloggen - soll NICHT bei der APP passiereN!
    const cookie = this.$api.getLocalData();
    if (cookie !== undefined && cookie != null && !this.isMobile) {
      this.successText = "automatische Neuanmeldung!"; // Web_autom_Neuanmeldung
      this.doLogin(cookie);
    }

    if (ALLConfig.azureConfig) {
      this.azureLoginActive = true;
      initAzureLogin()
        .then(() => {
          showSnackbar({
            text: "azure config initialized",
            duration: 300,
            color: "green"
          });
        })
        .catch(err => {
          this.errorText =
            "error init Azure: " + err + "\ncb-Uri:" + getAzureCallbackUrl();
        });
    }
    if (ALLConfig.azureConfig) {
      getAzureToken().then(token => {
        const accountName = getAzureUserName();
        if (!token || !accountName) {
          showSnackbar({
            text: "Bitte Azure-Anmeldung erneuern!",
            duration: 2000,
            color: "orange"
          });
          //   checkRefreshToken().then((r) => {
          //   if (r) {
          //     this.handleAzureLogin(r);
          //   }
          // });
        } else {
          this.handleAzureLogin(token);
        }
      });
    }
  }

  private handleAzureLogin(token: string) {
    if (token) {
      this.successText = "Willkommen " + getAzureAccountName();
      this.useAzure = true;
      if (!this.name) {
        this.name = getAzureUserName();
      }
      this.handleSubmit();
    }
  }

  private validate() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }
  private checkName(e: KeyboardEvent) {
    if (!PasswordValidator.isValidNameChar(e.key)) {
      showSnackbar({
        duration: 500,
        color: "red",
        text: "Eingabe von " + e.key + " nicht erlaubt!"
      });
      e.cancelBubble = true;
      e.preventDefault();
    }
    return false;
  }
  private checkEnter(e: KeyboardEvent) {
    if (e && e.keyCode === 13) {
      this.handleSubmit();
    }
  }
  private checkEnterInput(password: string) {
    if (password) {
      this.valid = true;
      if (this.password !== password) {
        this.password = password;
      }
    }
  }

  private async doAzureLogin() {
    try {
      const res = await doAzureLogin();

      if (res) {
        this.successText = "Willkommen " + getAzureAccountName();
        this.useAzure = true;
        if (!this.name) {
          this.name = getAzureUserName();
        }
        this.handleSubmit();
      }
    } catch (ex) {
      this.errorText = "Error on login:" + ex;
    }
  }

  private async doLogin(loginData: LoginData | string) {
    this.showLoading = true;
    this.debugText = JSON.stringify(loginData);
    let userdata: LoginData;
    if (typeof loginData === "string") {
      const parsed: LoginData | LoginData[] = JSON.parse(loginData);
      userdata = (parsed as LoginData[])?.length
        ? (parsed as LoginData[])[0]
        : (parsed as LoginData);
    } else {
      userdata = loginData;
    }
    if (this.DBs && this.DBs.length > 0 && userdata.dB) {
      this.selectedDB = this.DBs.find(d => d.db === userdata.dB);

      if (this.selectedDB) {
        console.log("logging into db " + JSON.stringify(this.selectedDB));
        this.$api.dB = this.selectedDB.db;
      }
    }
    if (this.Roots?.length) {
      if (this.selectedRoot) {
        console.log("using RootUrl" + JSON.stringify(this.selectedRoot));
        this.$api.rootUrl = this.selectedRoot.rootUrl;
      }
    }

    setCurrentUser(userdata);
    // if (!userdata.length) {
    // } else {
    //   setCurrentUser(userdata[0]); // Übergabe Userdaten an AUAPI damit es generell zur Verfügung steht
    // }

    if (this.name) {
      this.$api.user.kennung = this.name;
    }

    console.log(
      "got user: id:" + this.$api.user.id + " aic:" + this.$api.user.aic
    );
    this.Benutzer = (this.$root as any).userData = this.$api.user;
    if (!this.$api.user.aic && !this.$api.user.admin) {
      this.errorText =
        "Dem Benutzer wurde kein Stammsatz zugeordnet - keine persönliche Erfassung möglich!";
      // ist der User kein ADMIN & hat keinen Stammsatz hinterlegt - dann gibt es keine Formulare zu öffnen!
      this.password = "";
      this.successText = "";
      // this.$api.logout();
      // return;
      this.$api.logout().then((response: any) => {
        reloadApp(true);
      });
      this.showLoading = false;
      return;
    }

    if (!this.$api.user.tt) {
      // gibt es ein tt Wert - dann gibt es kein 1x... dann darf das nicht geprüft werden
      if (!/^\d+x\d+$/.exec("" + this.$api.user.id)) {
        this.errorText = userdata.info;
        // this.errorText = "User nicht gefunden!";
        this.$api.eraseLocalData();
        this.password = "";
        return;
      }
    }
    if (this.$api.user.error > 0) {
      // ist das login mit Token prüfe ich ob es einen Error gibt
      this.errorText = userdata.info;
      this.password = "";
      this.successText = "";
      // this.errorText = "User nicht gefunden!";
      this.$api.logout();
      return;
    } else if (!this.$api.user.startArray) {
      // gibt es kein Startformular - dann ausloggen!
      this.errorText =
        "Es wurde kein Startformular gefunden! Sie werden abgemeldet!";
      this.password = "";
      this.successText = "";
      this.$api.logout();
      return;
    }
    this.userfriendlyname = userdata.bezeichnung;
    this.showWelcome = true;
    // speichern der aktuellen User Daten (this.$api.user)..
    this.$api.setLocalData(60);
    this.avatar = this.$api.getUserAvatar();
    this.successText = "init....";
    const me = this;
    // const getAdmin = await this.$api.getAdmin();
    // if (getAdmin.data.admin === true) {
    //   // wenn Admin noch erlaubt - dann auf Admin Masken umschalten!
    // }
    this.$api.getGlobal(
      () => {
        RoleMenuMapping.InitializeAppByRole(userdata, me);
        if (userdata.startArray) {
          me.$emit("login", true, {
            pwValid: me.passwordValid,
            pwMessage: me.passwordMessage
          });
        } else {
          me.$emit("login", false);
          console.error("Login failed!");
        }
      },
      (txt: any) => {
        me.successText = txt;
        console.log(txt);
      },
      (txt: any) => {
        me.successText = "";
        me.errorText = txt;
        console.error(txt);
        me.$emit("login", false);
        console.error("Login failed!");
      }
    );
  }
  // PW verschlüsseln
  private handlePassword(res: any) {
    if (res === undefined) {
      this.errorText =
        "Der Webserver meldet sich nicht zurück - bitte wenden Sie sich an Ihe EDV!";
      return;
    }
    const pwminLength = res.data.pwML;
    const pwminDigit = res.data.pwMZ;
    const pwminSp = res.data.pwMS;
    const pwminUpperCase = res.data.pwMG;
    const pwAblauf = res.data.pwAblauf;
    const userArt = res.data.art;
    if (userArt !== 4 && userArt !== 3) { // bei AZURE & Idap darf diese Prüfung nicht stattfinden!
      const heute = new Date().toISOString().substr(0, 10);
      if (pwAblauf <= heute) {
        this.passwordValid = false;
        this.passwordMessage = "Passwort abgelaufen!";
      } else {
        const response = PasswordValidator.validate(
          this.password,
          pwminLength,
          pwminDigit,
          pwminSp,
          pwminUpperCase
        );
        this.passwordValid = response.isValid;
        this.passwordMessage = response.message;
      }
    }
    const userAic = res.data.aic;
    const userDatum = res.data.datum;
    if (userArt === 1) {
      const seed = this.password + userAic;
      this.hash = CryptoJS.MD5(seed).toString();
      console.log("pswHash:" + this.hash);
      this.art = "4";
      return;
    } else if (userArt === 2) {
      const seed = userAic + "$" + this.password + "#" + userDatum;
      this.hash = CryptoJS.MD5(seed).toString();
      this.art = "4";
      console.log("pswHash:" + this.hash);
      return;
    } else if (userArt === 3) {
      // LDAP Prüfung....
      const seed = this.password;
      this.hash = encryptForAD(seed);
      console.log("pswHash:" + this.hash);
      this.art = "2";
      return;
    } else if (userArt === 4) {
      // AZURE Prüfung....
      this.useAzure = true;
      return;
    }
  }
  private onNewConfig() {
    this.initConfig();
    this.initialized = ALLConfig.initialized = true;
  }
  private checkAppVersion() {
    const me = this;

    CheckVersion().then(t => {
      curentALLVersion.warVersion = t.warVersion;
      curentALLVersion.webVersion = t.webVersion;
      console.log(curentALLVersion.warVersion);
    });
  }
  private onConfigError(err: string) {
    this.errorText = err;
  }

  private async handleSubmit() {
    this.valid = false;
    // if (!this.selectedDB) {
    //   this.errorText = "Bitte Datebank auswählen!";
    //   return;console.log("");
    // }
    let DB;
    if (this.selectedDB) {
      DB = this.$api.dB = this.selectedDB.db;
    } else {
      DB = this.$api.dB;
    }
    if (!DB) {
      this.errorText = "Bitte Datebank auswählen!";
      return;
    }
    let selectUrl = this.$api.rootUrl;
    if (this.selectedRoot) {
      selectUrl = this.selectedRoot.rootUrl;
    }

    if (!this.name) {
      this.errorText = this.errorText = this.$globalsBezeichnung(
        this.Globals.Begriff,
        "Web_BenutzerAngeben",
        "Bitte Benutzer angeben!"
      );
      return;
    }

    if (!this.password && !this.useAzure) {
      this.errorText = this.Globals.Begriff(
        "Web_PW_angeben",
        "Bitte Passwort angeben!"
      );
      return;
    }

    const me = this;
    const Kennung = this.name; // aus Response dann aic.. etc auslesen
    try {
      if (this.useAzure) {
        const userdata = await this.$api.getUserData(DB, Kennung, selectUrl);
        await this.handlePassword(userdata);

        // nun PW verschlüsseln
        me.errorText = this.successText = "";
        me.showLoading = true;
        const url = this.$api.fullUrl("loginT", "webLog/");
        const token = await getAzureToken();
        const response = await makeRequest<LoginData>({
          url,
          method: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: {
            name: this.name,
            token,
            db: this.$api.dB,
            mandant: this.mandant
          }
        });

        me.$nextTick(() => {
          me.doLogin(response.data);
        });
      } else {
        const userdata = await this.$api.getUserData(DB, Kennung, selectUrl);
        await this.handlePassword(userdata);

        // nun PW verschlüsseln
        me.errorText = this.successText = "";
        me.showLoading = true;
        const url = this.$api.fullUrl("login", "webLog/");
        console.log("Login daten: art: " + this.art);
        const response = await makeRequest<LoginData>({
          url,
          method: "POST",
          contentType: "application/x-www-form-urlencoded",
          data: {
            name: this.name,
            password: this.hash,
            db: this.$api.dB,
            mandant: this.mandant,
            art: this.art
          }
        });

        me.$nextTick(() => {
          me.doLogin(response.data);
        });
      }
    } catch (ex) {
      // ein Error heraussen reicht
      me.handleLoginError(ex);
      // me.handleLoginError(new AxiosError("" + ex) );
      // im Axios Error kommt nur message und name - aber kein Response vom Webserver
      // den brauchen wir aber für die Meldungen siehe unten...
    }
  }

  // private handleLoginError(ex: AxiosResponse<any>) {
  private handleLoginError(ex: any) {
    console.error("bin im HandleLoginError");
    this.password = "";
    const rueckgabe = ex;
    this.errorText =
      "Entschuldigung!\r\nLogin aus technischen Gründen derzeit nicht möglich!";
    // Meldung: Web_Login_fehlgeschlagen
    if (ex.response?.status === 423) {
      this.errorText = ex.response.data?.info;
      // me.errorText = "Es läuft ein Update! Einloggen derzeit nicht möglich.";
      this.successText = "Fehlertyp / errorcode " + ex.response.status;
      // } else if (ex.bodyText !== undefined) {
      //   this.password = "";
      //   console.error("Login failed!", ex.bodyText);
      // } else if (ex.data !== undefined) {
      //   this.password = "";
      //   console.error("Login failed!", ex.data);
    } else if (ex?.response?.data?.info) {
      this.errorText = ex.response.data.info;
      if (this.errorText.indexOf("user not") >= 0) {
        this.name = "";
      }
      this.successText = "Fehlertyp / errorcode " + +ex.response.status;
    } else {
      console.error("App-Login failed!", ex);
    }
    this.showLoading = false;
  }
}
