




























































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
@Component
export default class MaterialCard extends Vue {
  @Prop() public icon!: string;
  @Prop() public image!: string;
  @Prop() public text!: string;
  @Prop() public title!: string;
  @Prop() public avatar!: string;
  @Prop({ default: "success" }) public color!: string;
  private actions: any;
  constructor() {
    super();
  }
  public mounted() {
    // ... muss ich was laden?
  }

  private classes() {
    return { "v-card--material--has-heading": this.hasHeading };
  }
  private hasHeading() {
    return Boolean(this.$slots.heading || this.title || this.icon);
  }
  private hasAltHeading() {
    return Boolean(this.$slots.heading || (this.title && this.icon));
  }
}
