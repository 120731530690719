import { Globals } from "@/Globals";
import { showDialog, showSnackbar } from "./UIHelper";
import AUApi from "@/api";
import { AxiosResponse } from "axios";
import { getKZValue } from "@/DataHelper";

export interface CalcResult {
  success: "success" | "warning" | "error";
}

export function calcFunction(
  modell: string,
  aic: number,
  fromDialog: boolean = false,
  app: Vue,
  zeitbereich: any,
  variable?: any,
  varName?: string,
  varDatum?: string,
  varName2?: string,
  varZeitart?: string,
  variable1?: any,
  text?: string,
) {
  let begriffModell!: ALLModell;
  let tabUbergabe: any[] = [];

  if (modell) {
    begriffModell = app.$globalsKennung(Globals.Begriff, modell);
  }
  // wird der Begriff nicht gefunden - ABBRUCH!
  if (!begriffModell) {
    showSnackbar({
      text: "Modell nicht gefunden",
      color: "error"
    });
    // this.showLoading = false;
    return Promise.reject("kein modell");
  }
  if (begriffModell.userFrage && fromDialog !== true) {
    const userFrage = app.$globalsBegriffMemo(
      app.Globals.Begriff,
      begriffModell.userFrage,
      "Soll die Berechnung durchgeführt werden?"
    );
    showDialog({
      title: "Frage",
      titleColor: "white",
      titleClass: "black--text",
      width: 300,
      height: 400,
      text: userFrage,
      persistent: true,
      okButton: true,
      onClose: (x: any) => {
        if (x.dialogResult === true) {
          calcFunction(modell, aic, true, app, zeitbereich, variable);
        }
        return true;
      }
    });
    return Promise.resolve();
  }
  // dialog nur aufmachen wenn dialog erforderlich und aufruf nicht vom dialog kommt.
  if (
    begriffModell.abfrage &&
    begriffModell.MDialog &&
    fromDialog !== true
  ) {
    showDialog({
      title: "Modelldialog",
      width: 250,
      site: "ModellDialog",
      // zeitbereich: me.zeitbereich,
      titleColor: "dvhBackground",
      titleClass: "white--text",
      data: {
        zeitbereich,
        query: begriffModell.abfrage
      },
      okButton: true,
      onClose: (x: any) => {
        if (x.returnedData.length > 0) {
          tabUbergabe = x.returnedData[0];
        }
        if (x.dialogResult === true) {
          calcFunction(modell, aic, true, app, zeitbereich, variable);
        }
        return true;
      }
    });
    return; // nicht weitermachen nur dialog aufmachen
  }
  // this.showLoading = true;
  let varUbergabe: any[] = [];
  const bemerkung: string = "Web - CalcHelperStatic";

  if (variable) {
    varUbergabe = variable;
  }
  if (varName || varName2 || varDatum) {
    if (variable) {
      varUbergabe.push({
        var: varName,
        type: "string",
        wert: variable,
        art: 1,
        user: app.$api.user.user,
        perm: false
      });
    }
    if (varZeitart) {
      varUbergabe.push({
        var: varName2,
        type: "string",
        wert: varZeitart,
        art: 1,
        user: app.$api.user.user,
        perm: false
      });
      // this.$api.setVariable(varUbergabe, this.$api.user.id, this.success); // soll das Memo übergeben
    }
    if (variable1) {
      const wert = new Date(variable1).toISOStringWithTZ();
      varUbergabe.push({
        var: varDatum,
        type: "string",
        wert,
        art: 1,
        user: app.$api.user.user,
        perm: false
      });
    }
  }
  return app.$api.postCalc(
    varUbergabe,
    tabUbergabe,
    begriffModell.aic,
    aic,
    zeitbereich,
    0,
    0,
    bemerkung,
    (r: any) => success(r, app, begriffModell, text),
    begriffModell.maxB,
  );
}

function success(res: any, app: Vue, begriffModell: any, text?: string): void {
  let calcResult: string = "";
  let iconAmpel: string = "";

  if (res.data.ergebnis !== "") {
    calcResult = res.data.ergebnis;
  }
  let ampelNumber: string = "";
  if (begriffModell.abfrage) {
    if (calcResult === "Gelb") {
      ampelNumber = "2";
    } else if (calcResult === "Rot") {
      ampelNumber = "3";
    } else if (calcResult === "Gruen") {
      ampelNumber = "1";
    }
    iconAmpel =
      "<img src='/" +
      ampelNumber +
      "_Ampel.png' alt='" +
      ampelNumber +
      "' />";
  } else {
    iconAmpel = "";
  }

  res.data.text = text;

  app.$emit("calculated", res.data);
  const vorfehler = res.data.vorfehler;
  if (vorfehler && vorfehler.length > 0 && vorfehler[0].error) {
    console.error("Thread - Modell Fehler: " + res.data.header.vorfehler[0].error);
    showSnackbar({
      text: vorfehler.error,
      color: "error"
    });
  }
  const calcMessage = res.data.error;
  const msgType = res.data.msgType;
  const header = res.data.header;
  let info = res.data.info;
  const responseJSON = res.data.response;
  const memo = res.data.memo;
  const title = res.data.titel;
  if (memo) {
    info = memo;
  }
  // this.showLoading = false;

  if (msgType === "Error") {
    showSnackbar({
      text: info,
      color: "error"
    });
  } else if (msgType === "Info") {
    showSnackbar({
      text: info,
      color: "info"
    });
  } else if (msgType === "Warnung") {
    showSnackbar({
      text: info,
      color: "warning"
    });
  }
  return msgType;
}
let aufgabenDefinitionCache: QueryResponse | undefined;
// gibt die aktuelle aufgabendefinion zurück cacht sie aber für die aktuelle session
export async function getAufgabeDefinition(): Promise<QueryResponse> {
  if (aufgabenDefinitionCache) { return aufgabenDefinitionCache; }
  try {
    const response = await AUApi.Current.getQuery(
      "Web_Status_Definitionen",
    ) as AxiosResponse<QueryResponse>;

    if (response.status < 300 && !response.data.error) {
      return aufgabenDefinitionCache = response.data;
    }
    if (response?.data.error) {
      console.error("error getting aufgabedefinion!", response?.data.error);
    }
  } catch (e) {
    console.error("error getting aufgabedefinion!", e);
  }
  return { header: [], columns: [], data: [], error: "no data from server" };
}
export function getKZAufgabeDefiniton<T>(
  kz: string,
  aicStamm: number,
  aufgabenDefinition: QueryResponse,
  defaultVal?: T) {
  const aufgabenDefRow = getAufgabeDefRow(aicStamm, aufgabenDefinition);
  if (!aufgabenDefRow) { return defaultVal; }
  return getKZValue(aufgabenDefRow, kz, aufgabenDefinition?.columns) ?? defaultVal;
}
export function getAufgabeDefRow(aicStamm: number, aufgabenDefinition: QueryResponse) {
  if (!aufgabenDefinition) { return; }
  return aufgabenDefinition?.data.find(
    (r: any) => r.aic_Stamm === aicStamm
  );
}

