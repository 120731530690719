import { Vue, Component, Prop, Provide } from "vue-property-decorator";
// import Constants from "../../Constants";
import { } from "../../DataHelper";
// import CreateList from "../CreateList";
import { findSperrDate } from "@/SperreHelper";
// import VTimeLineDay from "./VTimeLineDay.vue";


export default abstract class VTimeLineCarouselBase extends Vue {
  public static scrollToDate = new Date();
  protected myHeight: number = 900;
  protected myWidth: number = 450;
  protected activeTab: number = 0;
  protected dates: Date[] = [];
  protected today: any = new Date();
  protected timelinesInPage = 3;
  protected get pages() {

    if (this.dates.length % this.timelinesInPage > 1) {
      return Math.floor(this.dates.length / this.timelinesInPage) + 1;
    }
    return Math.round(this.dates.length / this.timelinesInPage);
  }

  @Prop() public aic: any;
  @Prop() private query!: string;
  @Prop() private queryEdit!: string;
  @Prop({ default: null }) public zeitbereich!: APIZeitbereich | null;
  @Prop({ default: false }) public showError!: boolean;
  @Prop({ default: false }) public showTagessaldo!: boolean;
  @Prop({ default: false }) public showNacherfassung!: boolean;
  @Prop({ default: false }) public showProjektstunden!: boolean;
  @Prop({ default: false }) public showReisekosten!: boolean;


  protected createdBase() {
    const me = this;
    this.$root.$on("windowResize", (a: any) => me.onResize(a));
  }

  protected mountedBase() {
    this.dates = [];
    this.myHeight = window.innerHeight - 100;
    if (window.innerWidth < 600) {
      this.timelinesInPage = 1;
      this.myWidth = window.innerWidth - 10;
    } else if
      (window.innerWidth > 600) {
      const max = window.innerWidth;
      let breite = 450;
      if (this.showTagessaldo) {
        breite = 350;
        this.myWidth = 350;
      }
      let anzahl = max / breite;
      anzahl = Math.floor(anzahl);
      this.timelinesInPage = anzahl;
    }

    this.calcDates();

    const me = this;
    setTimeout(() => {
      me.onResize(null);
      me.gotoDate(VTimeLineCarouselBase.scrollToDate);
    }, 500);
  }
  protected gotoDate(date: Date | string) {
    if (!date) {
      console.log("cannot go to undefined Date!");
      return;
    }
    if (typeof date === "string") {
      date = new Date(date);
    }
    if (!date.toISOStringWithTZ) {
      console.log("cannot go to not parsable Date!");
      return;
    }
    const iso = date.toISOStringWithTZ().substr(0, 10);
    const dayIndex =
      this.dates.findIndex(dt => dt.toISOStringWithTZ().substr(0, 10) === iso) -
      1;
    const tabIndex = Math.floor(dayIndex / this.timelinesInPage);
    this.activeTab = Math.max(0, tabIndex);
  }

  protected abstract calcDates(): void;

  protected getSperrDate() {
    const me = this;
    const aic = me.$api.user.aic;
    if (this.query) {
      const t = findSperrDate(this.query, aic, me);
      if (t) {
        const sperrDate = t.sperrDatum;
        return new Date(sperrDate);
      }
    }
  }
  private resizeTimer = 0;
  protected showCarousel = true;
  protected onResize(args: any) {

    let startDate: Date = VTimeLineCarouselBase.scrollToDate;
    this.showCarousel = false;
    if (this.resizeTimer) { clearTimeout(this.resizeTimer); }

    const timelines = (this.$refs.timeline) as Vue[];
    if (!startDate && timelines && timelines.length > 0) {
      startDate = this.dates[this.activeTab * this.timelinesInPage + 1];
    }

    // console.log("VTimelineCarouselBase.onResize");
    // if (this.$refs.markerspan) {
    //   const span = this.$refs.markerspan as HTMLSpanElement;
    //   if (!span) {
    //     return;
    //   }
    //   const rect = span.getBoundingClientRect();

    // this.myHeight = Math.max(window.innerHeight - rect.top - 50, 200);
    this.myHeight = window.innerHeight - 100;
    if (window.innerWidth < 600) {
      this.timelinesInPage = 1;
      this.myWidth = window.innerWidth - 10;
    } else if (window.innerWidth > 600) {
      const max = window.innerWidth;
      let breite = 450;
      this.myWidth = 450;
      if (this.showTagessaldo) {
        breite = 350;
        this.myWidth = 350;
      }
      let anzahl = max / breite;
      anzahl = Math.floor(anzahl);
      // if (anzahl > 7) {
      //   anzahl = 7;
      // }
      this.timelinesInPage = anzahl;
    }
    this.gotoDate(startDate);

    this.resizeTimer = setTimeout(() => {
      this.showCarousel = true;
    }, 100);
    // }
  }
}
