import { render, staticRenderFns } from "./SoftTerm.vue?vue&type=template&id=4e12c366&scoped=true&"
import script from "./SoftTerm.vue?vue&type=script&lang=ts&"
export * from "./SoftTerm.vue?vue&type=script&lang=ts&"
import style0 from "./SoftTerm.vue?vue&type=style&index=0&id=4e12c366&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e12c366",
  null
  
)

export default component.exports