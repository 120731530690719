
















import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";

@Component
export default class EditStamm extends EditPage {
  public mounted() {
    if (!this.query) {
      return;
    }
  }
}
