var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"light":"","loading":_vm.showLoading}},[(_vm.showHeader)?_c('v-card-title',{staticClass:"dvhBackground"},[_c('div',{staticClass:"headline font-weight-light white--text"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-sheet',{staticClass:"pa-4 primary lighten-2"},[_c('v-text-field',{attrs:{"label":"Search Company Directory","dark":"","flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-checkbox',{attrs:{"dark":"","hide-details":"","label":"Case sensitive search"},model:{value:(_vm.caseSensitive),callback:function ($$v) {_vm.caseSensitive=$$v},expression:"caseSensitive"}})],1)],1):_vm._e(),_c('v-card-text',[(_vm.initialized)?_c('v-treeview',{attrs:{"items":_vm.items,"selected-color":"success","open-all":"","search":"","item-key":"aic","item-text":"bezeichnung","selectable":_vm.selectable,"selection-type":"independent","return-object":"","loading-icon":"refresh","multiple-active":false,"on-icon":"check_circle","off-icon":"radio_button_unchecked"},on:{"input":_vm.selected,"change":_vm.selected,"update:active":_vm.selected},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('div',[_vm._v(_vm._s(item.bezeichnung))]),(
              _vm.hsSubAbfragen.find(function (abf) { return abf.aicStt == item.stt; }) &&
                _vm.hsSubData[item.stt]
            )?_c('div',[(_vm.showSubGrid)?_c('BaseSyncGrid',{attrs:{"preloadedData":_vm.getGridData(item.stt, item.aic),"allowFiltering":false}}):_vm._e()],1):_vm._e()])]}},{key:"prepend",fn:function(ref){
            var item = ref.item;
            var open = ref.open;
return [(!item.icon)?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")])]}}],null,false,43347082)}):_vm._e()],1),(_vm.pages > 1)?_c('v-pagination',{attrs:{"circle":"","total-visible":"8","length":_vm.pages},on:{"input":_vm.updatePagedItems},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }