


























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TreeItem } from "@/components/BaseTreeView.vue";
import BaseTreeView from "../BaseTreeView.vue";
import CreateList from "@/components/CreateList";
declare interface HsSubAbfragen {
  aicAbf: number;
  aicEig: number;
  kennung: string;
  aicStt: number;
  aicSttVon: number;
  art: string;
}
@Component
export default class TreeViewMA extends Vue {
  @Prop({ default: null }) public zeitbereich!: APIZeitbereich | null;
  @Prop({ default: null }) public siteData: any;
  @Prop() protected hsAicSelected!: number;
  private selectedAic: number | null = null;
  private selectedBezeichnung: string = "";
  private selectedQuery: string = "";

  public aic: any; // muss der markierte Satz sein
  private hsItems: HsListEntry[] = [];
  // private hsAbfragen: HsAbfragen[] = [];
  public hsSubAbfragen: HsSubAbfragen[] = [];
  private hsAic!: number;

  private componentKey: number = 0;
  private initalized: boolean = false;
  private title: string = "... Daten laden";

  public mounted() {
    this.reloadData();
  }

  private selected(c: any[], subAbfrage: any) {
    if (c.length > 0) {
      const selected = c[0];
      this.selectedAic = selected.aic;
      this.selectedBezeichnung = selected.bezeichnung;
      this.selectedQuery = subAbfrage.kennung;
    } else {
      this.selectedAic = null;
    }
  }

  public reloadData() {
    try {
      const me = this;
      this.$api.hauptschirm().then((data: any) => me.dataLoaded(data));
    } catch (e) {
      console.error(e);
    }
  }

  protected dataLoaded(data: any) {
    try {
      console.log("Hauptschirm data loaded");
      // Fehlerbehandlung
      if (data.data.error !== undefined) {
        this.$api.onfail(data.data.error);
        console.log(data.data.error);
        alert("ACHTUNG: " + data.data.error);
        this.$emit("back");
        return;
      }

      const dataList: DataListeEntry[] = data.data;
      if (dataList && dataList.length > 0) {
        dataList.forEach(element => {
          this.hsItems.push({
            Bezeichnung: element.Bezeichnung,
            Aic: element.Aic,
            Austritt: element.Austritt,
            Auswahl: element.Auswahl,
            Geloescht: element.Del, // gelöschte Daten
            Anzeige: element.VL,
            EbenenOffen: element.offen // alle Ebenen offen
          });
        });
      }
      this.hsAic = this.hsItems[0].Aic;
      this.title = this.hsItems[0].Bezeichnung;
      if (this.siteData.hsAicSelected) {
        this.hsAic = this.siteData.hsAicSelected;
        const findHS = this.hsItems.find(x => x.Aic === this.hsAic);
        if (findHS) {
          this.title = findHS.Bezeichnung;
        }
      }
      this.onHsItemClick(this.hsAic); // Subabfragen laden"!
    } catch (e) {
      console.error(e);
    }
  }
  private onHsItemClick(aic: any, bezeichnung?: string) {
    try {
      const me = this;
      this.hsAic = aic;
      if (bezeichnung) {
        this.title = bezeichnung;
      }
      this.$api
        .getHSEinstellung(aic, "")
        .then((resData: any) => me.getHsSubAbfragen(resData));
    } catch (e) {
      console.error(e);
    }
  }
  private getHsSubAbfragen(data: any) {
    const me = this;
    // im data stecken die AICs der Abfragen die pro Ebene geholt werden sollen
    // es stecken auch Parameter ob Ebene offen etc drinnen -daher reine Abfragen rausfiltern!
    const allSubData = data.data;
    this.hsSubAbfragen = allSubData.filter(
      (c: any) => c.aicAbf !== null && c.aicAbf !== undefined
    );
    this.forceRerender();
    this.initalized = true;
    // this.reloadData();
    // const x = me.$refs.HSList as BaseTreeView;
    // x?.reloadData();
  }
  private forceRerender() {
    this.componentKey += 1;
  }
}

declare interface DataListeEntry {
  Bezeichnung: string;
  Aic: number;
  Austritt: boolean;
  Auswahl: boolean;
  Del: boolean;
  VL: string;
  offen: boolean;
}
declare interface HsListEntry {
  Bezeichnung: string;
  Aic: number;
  Austritt: boolean;
  Auswahl: boolean;
  Geloescht: boolean;
  Anzeige: string;
  EbenenOffen: boolean;
}
// declare interface HsAbfragen {
//   // BsP: aicAbf: 7147
//   // eig: 6
//   // kennung: "HS_Web_FreigabeMA"
//   // stt: 163
//   aicAbf: any;
//   eig: any;
//   kennung: string;
//   stt: any;
// }
