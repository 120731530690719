import { render, staticRenderFns } from "./ReiseStatus.vue?vue&type=template&id=48acd068&scoped=true&"
import script from "./ReiseStatus.vue?vue&type=script&lang=ts&"
export * from "./ReiseStatus.vue?vue&type=script&lang=ts&"
import style0 from "./ReiseStatus.vue?vue&type=style&index=0&id=48acd068&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48acd068",
  null
  
)

export default component.exports