// base query component zum standardisierten
import { Vue, Component, Prop, Provide, Watch } from "vue-property-decorator";
import AUApi from "@/api";
import ChartBase from "./ChartBase";

export default abstract class BaseQueryChart extends ChartBase {
    @Prop() public siteData: any;
    protected showLoading: boolean = true;

    protected refreshing = false;
    public async refresh() {
        this.showLoading = true;
        if (this.refreshing) { return; }
        this.refreshing = true;
        try {
            if (this.query) {
                const r = await AUApi.Current.getQuery(
                    this.query,
                    undefined,
                    this.aic ?? null,
                    this.zeitbereich ?? null,
                    0,
                    false,
                    this.bewegungsdaten
                );
                this.dataLoaded(r.data);
            } else if (this.queryData) {
                this.dataLoaded(this.queryData);
            } else if (this.siteData) {
                if (this.siteData.queryData) {
                    this.dataLoaded(this.siteData.queryData);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            this.refreshing = false;
            this.showLoading = false;
        }
    }

    protected dataLoadedBase(data: QueryResponse) {
        this.lastQueryResponse = data;
        this.dataLoaded(data);
    }

    // implement this to show the data in the UI...
    protected abstract dataLoaded(data: QueryResponse): void;

    protected QvAICsChanged(): void {
        // nothing to do here
    }
}
