





































































import { Vue, Component, Prop, Provide } from "vue-property-decorator";
import EditPage from "@/components/EditPage";
import { getKennungTitle, getKennungValueDisplay, getKennungValue, } from "@/DataHelper";

@Component
export default class TagesSaldenAntrag extends EditPage {
  constructor() {
    super();

    this.query = "Web_WF_Antrag_Tag";
    this.showLoading = true;
  }
  private anzeigeAntrag: boolean = true;
  private deleteVisible: boolean = false;
  private showUestAuszahlen: boolean = false;
  private showauszahlungUest: boolean = false;

  public mounted() {
    this.mountedBase();
  }
  public bewDelete() {
    if (this.data && this.data.aic_Bew_pool) {
      this.onDelete(this.data.aic_Bew_pool, "");
      this.$emit("back");
    }
  }
  public save() {
    this.onSave(this.data, "");
  }
  protected initialize() {
    if (this.data && this.data.aic_Bew_pool) {
      this.deleteVisible = true;
    }
    const antrag = getKennungValue(this.data, "ZE_ANTRAG_BOOL3", this.columns);
    const keinAntrag = getKennungValueDisplay(this.data, "keinAntrag", this.columns);
    if (keinAntrag === "1.00") {
      this.anzeigeAntrag = false;
    }
    const uestAuszahlen = getKennungTitle("ZE_UEST_NICHT_AUSZAH", this.columns);
    if (uestAuszahlen) {
      this.showUestAuszahlen = true;
    }
    const auszahlungUest = getKennungTitle("ZE_AUSZAHLUNG_UEST", this.columns);
    if (auszahlungUest) {
      this.showauszahlungUest = true;
    }
  }
}
